<template>
  <div class="filters__values__item">
    <span class="capitalize">{{
      isObject ? scope[keyName] : split(scope)
    }}</span>
    <button v-if="!disabled" :disabled="disabled" @click="removeFilter('type')">
      <BaseIcon icon="filter/close" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { helpers } from "@/composables/helpers";

export default defineComponent({
  name: "FilterItem",
  components: {
    BaseIcon,
  },
  props: {
    scope: {
      type: [String, Object],
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    isObject: {
      type: Boolean,
      default: true,
      required: false,
    },
    keyName: {
      type: String,
      default: "name",
      required: false,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  setup(props, context) {
    const { split } = helpers();

    const removeFilter = () => {
      context.emit("remove_filter", props.filter, props.index);
    };

    return { removeFilter, split };
  },
});
</script>

<style scoped></style>
