import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/filters/getters";
import { mutations } from "@/store/modules/filters/mutations";
import { actions } from "@/store/modules/filters/actions";
import { FilterState } from "@/store/modules/filters/types";
import moment from "moment";
import { DeviceStatusEnums } from "@/store/modules/devices/types";

export const state: FilterState = {
  selectedFilter: "",
  dateFrom: moment().subtract(30, "days").format("YYYY-MM-DD"),
  dateTo: moment().format("YYYY-MM-DD"),
  filters: {
    organization: "Maimonides Medical Center",
    hospital: "",
    department: "",
    category: "",
    subCategory: "",
    vendor: "",
    reconciliationStatuses: [],
    deviceStatuses: DeviceStatusEnums.AVAILABLE,
    locationUnit: "",
  },
  searchString: "",
};

export const repairs: Module<FilterState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default repairs;
