import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "location__navigations__modal coverage" }
const _hoisted_2 = { class: "coverage__tab__header modal__tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "coverage__tabs__values" }
const _hoisted_5 = {
  key: 0,
  class: "coverage__tabs__values__items"
}
const _hoisted_6 = { class: "switch" }
const _hoisted_7 = ["onChange", "checked"]
const _hoisted_8 = {
  key: 1,
  class: "coverage__tabs__values__items"
}
const _hoisted_9 = { class: "switch" }
const _hoisted_10 = ["onChange", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabNames, (tab) => {
        return (_openBlock(), _createElementBlock("button", {
          onClick: ($event: any) => (_ctx.setTab(tab)),
          key: tab,
          class: _normalizeClass({ active: _ctx.currentTab === tab })
        }, [
          _createElementVNode("span", null, _toDisplayString(tab), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.currentTab === 'mode')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data['mode'], (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.key,
                class: "coverage__tabs__values__item"
              }, [
                _createElementVNode("h4", null, _toDisplayString(item.name), 1),
                _createElementVNode("label", _hoisted_6, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    onChange: ($event: any) => (_ctx.handleCoverageChange(item.key, $event)),
                    checked: _ctx.coverages.includes(item.key)
                  }, null, 40, _hoisted_7),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "slider" }, null, -1))
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.currentTab === 'status')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data['status'], (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.key,
                class: "coverage__tabs__values__item"
              }, [
                _createElementVNode("h4", null, _toDisplayString(item.name), 1),
                _createElementVNode("label", _hoisted_9, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    onChange: ($event: any) => (_ctx.handleCoverageChange(item.key, $event)),
                    checked: _ctx.coverages.includes(item.key)
                  }, null, 40, _hoisted_10),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "slider" }, null, -1))
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}