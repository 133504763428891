import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modals modal" }
const _hoisted_2 = { class: "modal__header" }
const _hoisted_3 = { class: "modal__content tickets" }
const _hoisted_4 = { class: "modal__content___left tickets__left" }
const _hoisted_5 = { class: "modal__content___right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ItemRow = _resolveComponent("ItemRow")!
  const _component_ItemsList = _resolveComponent("ItemsList")!
  const _component_TicketEvent = _resolveComponent("TicketEvent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "modal__header__title" }, "Ticket Details", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "modals-trigger modal__header__close"
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal-close" })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ItemsList, { title: "Ticket Status" }, {
          default: _withCtx(() => [
            _createVNode(_component_ItemRow, { title: "Status" }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([_ctx.addStatusClass(_ctx.computeStatus(_ctx.ticket.status)), "modal__contract__status"])
                }, _toDisplayString(_ctx.computeStatus(_ctx.ticket.status)), 3)
              ]),
              _: 1
            }),
            _createVNode(_component_ItemRow, {
              title: "Ticket #",
              value: _ctx.ticket.number
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Date Issued",
              value: _ctx.formatDate(_ctx.ticket.created_at)
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Estimate of completion",
              value: "Mar 07, 2023"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ItemsList, { title: "Ticket Description" }, {
          default: _withCtx(() => [
            _createVNode(_component_ItemRow, {
              title: "Repair Cost",
              value: "-"
            }),
            _createVNode(_component_ItemRow, {
              title: "Issued by",
              value: _ctx.getUserFullName(_ctx.ticket.issued_by)
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Ticket priority",
              value: _ctx.ticket.priority
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Description",
              value: _ctx.ticket.description
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ItemsList, { title: "Device details" }, {
          default: _withCtx(() => [
            _createVNode(_component_ItemRow, {
              title: "Serial",
              value: _ctx.ticket.device?.serial_number
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Category",
              value: _ctx.ticket.device?.subcategory?.category?.name
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Subcategory",
              value: _ctx.ticket.device?.subcategory?.name
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Vendor",
              value: _ctx.ticket.device?.vendor?.company_name
            }, null, 8, ["value"]),
            _createVNode(_component_ItemRow, {
              title: "Model",
              value: _ctx.ticket.device?.model
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "modal__content__sub__title" }, "Events", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event, key) => {
          return (_openBlock(), _createBlock(_component_TicketEvent, {
            key: key,
            event: event
          }, null, 8, ["event"]))
        }), 128))
      ])
    ])
  ]))
}