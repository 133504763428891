import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ToastState } from "@/store/modules/toast/types";

export const getters: GetterTree<ToastState, RootState> = {
  visible(state: ToastState): boolean {
    return state.visible;
  },
  title(state: ToastState): string {
    return state.title;
  },
  description(state: ToastState): string {
    return state.description;
  },
};
