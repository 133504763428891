<template>
  <div class="location__navigations__modal building">
    <h6
      v-for="(building, key) in buildings"
      :key="key"
      :class="{ active: building.id === currentBuilding.id }"
      @click="updateBuilding(building)"
    >
      {{ building.building_name }}
    </h6>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "../../store";

export default defineComponent({
  name: "BuildingsNavigation",
  setup(_, { emit }) {
    const currentBuilding = computed(() => {
      return store.getters["locations/building"];
    });

    const buildings = computed(() => {
      return store.getters["locations/buildings"];
    });

    const floors = computed(() => {
      return store.getters["locations/floors"];
    });

    const updateBuilding = (building) => {
      store.commit("locations/SET_BUILDING", building);
      store.dispatch("locations/getFloors").then(() => {
        const floor = floors.value[floors.value.length - 1];
        store.commit("locations/SET_FLOOR_NUMBER", floor.floor_number);
        store.commit("locations/SET_FLOOR", floor);
        emit("update-map", "building");
      });
    };
    return {
      currentBuilding,
      buildings,
      updateBuilding,
    };
  },
});
</script>
