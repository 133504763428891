<template>
  <div :class="{ active: showUpdateRoom && room }" class="add-access-point">
    <div class="form-head">
      <h3>Update room</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form v-if="room" @submit.prevent="update" class="form">
      <div class="form-group">
        <BaseLabel title="Unit" />
        <select v-model="room.unit" class="base__select">
          <option :value="null">Select Unit</option>
          <option
            :key="key"
            v-for="(unit, key) in roomsUnits"
            :value="unit.acronym"
          >
            {{ unit.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <BaseLabel title="Label" />
        <input
          v-model="room.label"
          placeholder="Enter Label"
          class="base__input uppercase"
          type="text"
        />
      </div>
      <div class="form-group">
        <BaseLabel title="Name" />
        <input
          v-model="room.name"
          placeholder="Enter name"
          class="base__input"
          type="text"
          required
        />
        <p>Room names format should be R-001 (1 letter, 3 numbers)</p>
      </div>
      <div class="access-point__form-footer">
        <button
          type="submit"
          class="access-point__add-submit"
          :disabled="disabled"
        >
          Update Room
        </button>
        <button type="button" @click="close" class="access-point__add-cancel">
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "UpdateRoomModal",
  components: { BaseIcon, BaseLabel },
  setup(_, { emit }) {
    const showAction = ref(false);
    const room = ref(null);
    const disabled = ref(false);

    const showUpdateRoom = computed(() => {
      return store.getters["locations/showUpdateRoom"];
    });

    const roomsUnits = computed(() => {
      return store.getters["locations/roomsUnits"];
    });

    const childMethod = (roomData) => {
      disabled.value = false;
      room.value = roomData;
    };

    const close = () => {
      store.commit("locations/SET_SHOW_UPDATE_ROOM", false);
    };

    const update = async () => {
      disabled.value = true;
      store.dispatch("locations/updateRoom", room.value).then(() => {
        emit("update", room.value);
      });
    };

    return {
      showAction,
      close,
      roomsUnits,
      update,
      disabled,
      childMethod,
      showUpdateRoom,
      room,
    };
  },
});
</script>
