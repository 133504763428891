<template>
  <ItemRow title="Cost" :value="`$${formatMoney(event.cost, 2)}`" />
</template>

<script>
import { defineComponent } from "vue";
import { helpers } from "@/composables/helpers";
import ItemRow from "../../../resusables/list/ItemRow";

export default defineComponent({
  name: "FinancialEvent",
  components: { ItemRow },
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { formatMoney } = helpers();

    return { formatMoney };
  },
});
</script>

<style scoped></style>
