import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toast__message"
}
const _hoisted_2 = { class: "toast__titles" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.visible && _ctx.title)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.description), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_BaseIcon, { icon: "modal/close" })
        ])
      ]))
    : _createCommentVNode("", true)
}