import moment from "moment";
import { devices } from "@/composables/table/devices";
import { helpers } from "@/composables/helpers";
import { computed } from "vue";
import store from "@/store";
import { contracts } from "@/composables/contracts/contracts";

export function viewDevice() {
  const { orderTypes, orderTypeName } = devices();
  const { getContractCost, getServiceContractCost } = contracts();
  const { formatMoney, dateIsPresent } = helpers();

  const deviceStatusesRep = {
    available: "Available",
    dirty: "Dirty",
    in_repair: "Repair",
    in_use: "In-Use",
    unavailable: "Unavailable",
  };

  const formatStatus = (status) => {
    if (!status) return null;
    return status.split("_").join(" ");
  };

  const formatDate = (date) => {
    return moment.unix(date / 1000).format("MM/DD/YY HH:mm");
  };

  const formatDateFromMs = (date) => {
    return moment.unix(date / 1000).format("MM/DD/YY HH:mm");
  };

  const addStatusClass = (status) => {
    if (status === "available") {
      return "device__events__status__primary";
    } else if (status === "closed") {
      return "device__events__status__danger";
    } else {
      return "device__events__status__success";
    }
  };

  const device = computed(() => {
    return store.getters["devices/device"];
  });

  const getWithKey = (arr, key, value) => {
    return arr.find((order) => order[key] === value);
  };

  const getOrdersAmount = () => {
    const orders: any = [];
    let sum = 0;

    for (const order of device.value.orders) {
      if (
        orderTypes.includes(order.type) ||
        (order.type === "shipping" && orderTypes.includes("purchase"))
      ) {
        sum += parseFloat(order.unit_amount);

        if (order.type === "purchase") {
          orders.splice(0, 0, {
            name: orderTypeName[order.type],
            type: order.type,
            amount: `$${formatMoney(order.unit_amount, 2)}`,
          });
        } else {
          orders.push({
            name: orderTypeName[order.type],
            type: order.type,
            amount: `$${formatMoney(order.unit_amount, 2)}`,
          });
        }
      }
    }

    for (const type of orderTypes) {
      const exist = orders.find((order) => order.type === type);

      if (!exist) {
        orders.push({
          name: orderTypeName[type],
          type,
          amount: "-",
        });
      }
    }

    const total = `$${formatMoney(sum, 2)}`;

    return { total, orders };
  };

  const getCostOfOwnership = () => {
    const orders: any = [];
    let total: string | number = 0;

    const amountType = "unit_amount";

    const purchase = getWithKey(device.value.orders, "type", "purchase");
    const rental = getWithKey(device.value.orders, "type", "rental");
    const service = getWithKey(device.value.orders, "type", "maintenance");
    const maintenance = getWithKey(
      device.value.orders,
      "type",
      "preventative_maintenance"
    );
    const disposition = getWithKey(device.value.orders, "type", "disposition");

    if (purchase) {
      const amount = getContractCost(purchase, amountType, false);
      if (typeof amount === "number") total += amount;

      orders.push({
        name: orderTypeName.purchase,
        type: "purchase",
        amount: `$${formatMoney(amount, 2)}`,
      });
    } else if (rental) {
      const amount = getContractCost(rental, amountType, false);
      if (typeof amount === "number") total += amount;

      if (dateIsPresent(rental.end_date)) {
        orders.push({
          name: orderTypeName.rental,
          type: "rental",
          amount: `$${formatMoney(amount, 2)}`,
        });
      } else {
        orders.push({
          name: orderTypeName.purchase,
          type: "rental",
          amount: `$${formatMoney(amount, 2)}`,
        });
      }
    }

    if (service) {
      const amount = getServiceContractCost(service, amountType, false);
      if (typeof amount === "number") total += amount;

      orders.push({
        name: orderTypeName.maintenance,
        type: "maintenance",
        amount: `$${formatMoney(amount, 2)}`,
      });
    } else {
      orders.push({
        name: orderTypeName.maintenance,
        type: "maintenance",
        amount: `-`,
      });
    }

    if (maintenance) {
      const amount = getContractCost(maintenance, amountType, false);
      if (typeof amount === "number") total += amount;

      orders.push({
        name: orderTypeName.preventative_maintenance,
        type: "preventative_maintenance",
        amount: `$${formatMoney(amount, 2)}`,
      });
    } else {
      orders.push({
        name: orderTypeName.preventative_maintenance,
        type: "preventative_maintenance",
        amount: `-`,
      });
    }

    if (disposition) {
      const amount = getContractCost(disposition, amountType, false);
      if (typeof amount === "number") total += amount;

      orders.push({
        name: "Disposition",
        type: "disposition",
        amount: `$${formatMoney(amount, 2)}`,
      });
    } else {
      orders.push({
        name: "Disposition",
        type: "disposition",
        amount: `-`,
      });
    }

    return { total: `$${formatMoney(total, 2)}`, orders };
  };

  return {
    deviceStatusesRep,
    formatStatus,
    getCostOfOwnership,
    formatDateFromMs,
    formatDate,
    addStatusClass,
    getOrdersAmount,
  };
}
