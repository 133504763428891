import { MutationTree } from "vuex";
import { AnalyticsState } from "@/store/modules/analytics/types";

export const mutations: MutationTree<AnalyticsState> = {
  SET_FINANCIAL_DATA(state: AnalyticsState, data: object) {
    state.financialData = data;
    return state.financialData;
  },
  SET_UTILIZATION_DATA(state: AnalyticsState, data: object) {
    state.utilizationData = data;
    return state.utilizationData;
  },
  SET_BIOMED_TIMELINE_DATA(state: AnalyticsState, data: object) {
    state.biomedTimelineData = data;
    return state.biomedTimelineData;
  },
  SET_BIOMED_MEANTIMES_DATA(state: AnalyticsState, data: object) {
    state.biomedMeantimesData = data;
    return state.biomedMeantimesData;
  },
  SET_DEVICES_RECONCILIATION_COUNTS(state: AnalyticsState, data: object) {
    state.devicesReconciliationCounts = data;
    return state.devicesReconciliationCounts;
  },
  SET_UTILIZATION_RATES(state: AnalyticsState, data: object) {
    state.utilizationRates = data;
    return state.utilizationRates;
  },
};
