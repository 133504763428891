import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/tickets/getters";
import { mutations } from "@/store/modules/tickets/mutations";
import { actions } from "@/store/modules/tickets/actions";
import { TicketsState } from "@/store/modules/tickets/types";

export const TicketsData: TicketsState = {
  tickets: [],
  ticket: {},
  events: [],
};

export const tickets: Module<TicketsState, RootState> = {
  namespaced: true,
  state: TicketsData,
  getters,
  actions,
  mutations,
};

export default tickets;
