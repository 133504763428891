import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { LoadingState } from "@/store/modules/loading/types";
import Nprogress from "nprogress";

export const actions: ActionTree<LoadingState, RootState> = {
  async startLoading({ commit, state }, reset: false): Promise<void> {
    if (reset) {
      Nprogress.start();
      return;
    }

    if (state["apiWaitingCount"] === 0) {
      Nprogress.start();
    }

    commit("SET_API_WAITING_COUNT", state["apiWaitingCount"] + 1);
  },
  async doneLoading({ commit, state }, reset: false): Promise<void> {
    if (reset) {
      Nprogress.done();
      return;
    }
    commit("SET_API_WAITING_COUNT", state["apiWaitingCount"] - 1);

    if (state["apiWaitingCount"] <= 0) {
      commit("SET_API_WAITING_COUNT", 0);
      Nprogress.done();
    }
  },
};
