<template>
  <div
    :key="key"
    v-for="(item, key) in datasets"
    class="multi__line__chart__labels__item"
  >
    <span
      class="multi__line__chart__labels__item__color"
      :style="{ backgroundColor: item.borderColor }"
    ></span
    ><span class="multi__line__chart__labels__item__label">{{
      item.label
    }}</span
    ><span class="multi__line__chart__labels__item__value"
      >{{ avgArr(item.data) }} <span>days</span></span
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MeantimeChartLabels",
  props: {
    datasets: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const avgArr = (arr) => {
      const sum = arr.reduce((a, b) => a + b, 0);
      return Math.round(sum ? sum / arr.length : sum);
    };

    return {
      avgArr,
    };
  },
});
</script>

<style scoped></style>
