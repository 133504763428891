import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/pagination/getters";
import { mutations } from "@/store/modules/pagination/mutations";
import { actions } from "@/store/modules/pagination/actions";
import { PaginationState } from "@/store/modules/pagination/types";

const state: PaginationState = {
  offset: 0,
  limit: 10,
  page: 1,
};

export const dropdowns: Module<PaginationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dropdowns;
