<template>
  <div v-show="!show" @click="toggle" class="map-sidebar-open modals-trigger">
    <BaseIcon icon="hamburger-list" />
  </div>

  <div v-show="show" class="modals access-points-list">
    <button @click="toggle" class="map-sidebar-close">
      <BaseIcon icon="modal-close" />
    </button>

    <div class="access-points-list__main">
      <div class="access-points-list__form">
        <div class="input__group__list__search">
          <BaseIcon
            class="input__group__list__search__svg"
            icon="form/search"
          />
          <BaseInput
            placeholder="Search by name, BSSID..."
            type="text"
            class="input__group__list__search__input"
          />
        </div>
        <div class="access-points-list__filter">
          <BaseIcon icon="filter/filter-alt" />
        </div>
      </div>

      <div class="access-points__content">
        <h3 class="access-points__title">Access points</h3>
        <p class="access-points__description">
          You can use access point signal strength to track it down. Always keep
          access point updated
        </p>
        <div class="access-points__content__cover">
          <button @click="openAdd" class="access-points__content__add">
            <BaseIcon icon="modal/add" />
            <span>Add Access Point</span>
          </button>
        </div>
      </div>

      <div class="access-points-list__tabs">
        <div class="access-points-list__tabs__button modal__tabs">
          <button @click="toggleTabs(true)" :class="{ active: showMapped }">
            <span>Mapped</span> <span>{{ accessPoints.length }}</span>
          </button>
          <button @click="toggleTabs(false)" :class="{ active: !showMapped }">
            <span>Unmapped</span> <span>{{ unmappedAccessPoints.length }}</span>
          </button>
        </div>
        <div
          v-if="lastTimeAccessPointsRefreshed"
          class="access-points-list__tabs__refreshed"
        >
          <p>Last updated {{ diffAccessPointsRefreshedTime }}</p>
        </div>
        <div v-show="showMapped" class="access-points__tabs__items">
          <AccessPointsListItem
            :index="key"
            :accessPoint="accessPoint"
            :key="key"
            v-for="(accessPoint, key) in accessPoints"
          />
        </div>
        <div v-show="!showMapped" class="access-points__tabs__items">
          <AccessPointsListItem
            :index="key"
            :mapped="false"
            :key="key"
            :accessPoint="accessPoint"
            v-for="(accessPoint, key) in unmappedAccessPoints"
          />
        </div>
      </div>

      <RefreshAccessPoints />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "../svg/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import AccessPointsListItem from "@/components/access-points/AccessPointsListItem";
import store from "@/store";
import RefreshAccessPoints from "@/components/access-points/RefreshAccessPoints";
import { helpers } from "@/composables/helpers";

export default defineComponent({
  name: "AccessPointsList",
  components: {
    RefreshAccessPoints,
    AccessPointsListItem,
    BaseInput,
    BaseIcon,
  },
  setup() {
    const showMapped = ref(true);

    const { diffForHumans } = helpers();

    onMounted(() => {
      store.commit("dropdowns/TOGGLE_ACCESS_POINT_LIST_ITEM", -1);
    });

    const accessPoints = computed(() => {
      return store.getters["locations/accessPoints"];
    });

    const show = computed(() => {
      return store.getters["dropdowns/accessPointsList"];
    });

    const unmappedAccessPoints = computed(() => {
      return store.getters["locations/unmappedAccessPoints"];
    });

    const lastTimeAccessPointsRefreshed = computed(() => {
      return store.getters["accessPoints/lastTimeAccessPointsRefreshed"];
    });

    const diffAccessPointsRefreshedTime = computed(() => {
      return (
        lastTimeAccessPointsRefreshed.value &&
        diffForHumans(lastTimeAccessPointsRefreshed.value)
      );
    });

    const toggle = () => {
      store.commit("dropdowns/TOGGLE_DROPDOWN", "access_points_list");
      store.commit("dropdowns/TOGGLE_ACCESS_POINT_LIST_ITEM", -1);
    };

    const toggleTabs = (value) => {
      showMapped.value = value;
    };

    const openAdd = () => {
      store.commit("accessPoints/SET_ACCESS_POINT", null);
      store.commit("accessPoints/SET_SHOW_ADD", true);
    };

    return {
      show,
      showMapped,
      toggle,
      lastTimeAccessPointsRefreshed,
      openAdd,
      accessPoints,
      toggleTabs,
      diffAccessPointsRefreshedTime,
      unmappedAccessPoints,
    };
  },
});
</script>
