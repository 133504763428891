<template>
  <div
    class="access-points__tabs__item__strength"
    :class="getGrade(accessPoint.transmit_power)"
  >
    <template v-if="accessPoint.transmit_power">
      <h6 class="transmit__power__text">
        {{ getGrade(accessPoint.transmit_power) }} Signal
      </h6>
      <div class="transmit__power__cover">
        <div
          class="transmit__power__strength"
          :style="{
            width: `${calculateSignalPercentage(accessPoint.transmit_power)}%`,
          }"
        ></div>
      </div>
      <h6 class="transmit__power__text">
        -{{ accessPoint.transmit_power }} dBm
      </h6>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AccessPointTransmitPower",
  props: {
    index: {
      type: Number,
      required: true,
    },
    accessPoint: {
      type: Object,
      required: false,
      default: null,
    },
    mapped: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const showAction = ref(false);
    const openAdd = () => {
      // store.commit("accessPoints/SET_SHOW_ADD", true);
    };

    const calculateSignalPercentage = (dBm) => {
      dBm = dBm * -1;
      if (dBm > -70) {
        return 100;
      } else if (dBm >= -85) {
        return Math.round(((dBm + 85) / 15) * 25) + 75;
      } else if (dBm >= -100) {
        return Math.round(((dBm + 100) / 15) * 25) + 50;
      } else if (dBm >= -109) {
        return Math.round(((dBm + 109) / 8) * 25) + 25;
      } else {
        return 0;
      }
    };
    const getGrade = (dBm) => {
      if (!dBm) return;
      const perc = calculateSignalPercentage(dBm);
      if (perc === 100) return "strong";
      if (perc >= 50) return "fair";
      return "poor";
    };

    return {
      showAction,
      openAdd,
      getGrade,
      calculateSignalPercentage,
    };
  },
});
</script>
