import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createBlock(_component_DoughnutChart, {
    chartData: _ctx.chartDataList,
    "is-filterable": true,
    "filter-exist": _ctx.activeStatus !== '',
    "active-label": [_ctx.activeStatus],
    onFilter: _ctx.updateData
  }, null, 8, ["chartData", "filter-exist", "active-label", "onFilter"]))
}