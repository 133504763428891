import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.show }, "add-access-point"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Device Details", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "" }, [
      _createElementVNode("div", { class: "device__list__item__numbers" }, [
        _createElementVNode("h6", null, [
          _createElementVNode("span", null, "Serial"),
          _createElementVNode("span", null, "22739")
        ]),
        _createElementVNode("span", null, "279")
      ])
    ], -1))
  ], 2))
}