<template>
  <div class="toast__message" v-if="visible && title">
    <div class="toast__titles">
      <h4>{{ title }}</h4>
      <h5 v-if="description">{{ description }}</h5>
    </div>
    <button @click="close">
      <BaseIcon icon="modal/close" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "@/store";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "ToastMessage",
  components: { BaseIcon },

  setup() {
    const visible = computed(() => {
      return store.getters["toast/visible"];
    });
    const title = computed(() => {
      return store.getters["toast/title"];
    });
    const description = computed(() => {
      return store.getters["toast/description"];
    });

    const close = () => {
      store.commit("toast/HIDE_TOAST");
    };

    return {
      visible,
      title,
      description,
      close,
    };
  },
});
</script>

<style scoped></style>
