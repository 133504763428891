import { computed } from "vue";
import store from "@/store";
import { locations } from "@/composables/maps/locations";

export function access_points() {
  const { transformCoordinates } = locations();

  const accessPoint = computed(() => {
    return store.getters["accessPoints/accessPoint"];
  });

  const accessPointsCoordinates = computed(() => {
    return store.getters["locations/accessPointsCoordinates"];
  });

  const hasAccessPointCoordinates = computed(() => {
    return (
      accessPoint.value &&
      accessPoint.value.coordinate &&
      accessPoint.value.coordinate.lat &&
      accessPoint.value.coordinate.lng
    );
  });

  const accessPointsCoordinatesTransformed = computed(() => {
    if (!accessPointsCoordinates.value.features) return [];
    return accessPointsCoordinates.value.features
      .filter((feature) => {
        const coordinates = feature?.geometry?.coordinates;
        return coordinates && coordinates.length > 0;
      })
      .map((feature) => {
        const coordinates = feature.geometry.coordinates;
        const transformedCoordinates = transformCoordinates(coordinates);

        return {
          name: feature.properties.NAME,
          label: feature.access_point.label,
          status: feature.access_point.status,
          access_point: feature.access_point,
          jsonId: feature.jsonId,
          coordinate: {
            lat: transformedCoordinates[0].lat,
            lng: transformedCoordinates[0].lng,
          },
        };
      });
  });

  const showAccessPointIcon = (accessPoint: any): boolean => {
    return accessPoint.is_anchor || accessPoint.is_ble_compatible;
  };

  return {
    hasAccessPointCoordinates,
    accessPointsCoordinatesTransformed,
    showAccessPointIcon,
  };
}
