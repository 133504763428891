import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "add-access-point find-devices__forms active"
}
const _hoisted_2 = { class: "form-head" }
const _hoisted_3 = { class: "form-footer" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UpdateDeviceLocationForm = _resolveComponent("UpdateDeviceLocationForm")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Mark as dirty", -1)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_BaseIcon, { icon: "modal/close" })
          ])
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
          class: "form"
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("p", null, " Confirm device location. The device location is auto-set based on your phone’s location. ", -1)),
          _createVNode(_component_UpdateDeviceLocationForm, {
            modelValue: _ctx.location,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.location) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              disabled: _ctx.disabled,
              type: "submit",
              class: "form-footer__btn-full"
            }, " Mark as dirty ", 8, _hoisted_4)
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}