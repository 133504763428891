import { FilterScopeEnums } from "@/store/modules/filters/types";

export const RouteFilters = {
  Home: {
    allowedFilters: [FilterScopeEnums.VENDOR],
  },
  Devices: {
    allowedFilters: [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
      FilterScopeEnums.RECONCILIATION_STATUSES,
      FilterScopeEnums.DEVICE_STATUSES,
    ],
  },
  ViewDevice: {
    allowedFilters: [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
      FilterScopeEnums.RECONCILIATION_STATUSES,
    ],
  },
  Contracts: {
    allowedFilters: [FilterScopeEnums.SUBCATEGORY, FilterScopeEnums.VENDOR],
  },
  ViewContract: {
    allowedFilters: [],
  },
  AccessPoints: {
    allowedFilters: [],
  },
  FindDevices: {
    allowedFilters: [
      FilterScopeEnums.DEVICE_STATUSES,
      FilterScopeEnums.LOCATION_UNIT,
      // FilterScopeEnums.FLOOR_NUMBER,
    ],
  },
};
