import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/loading/getters";
import { mutations } from "@/store/modules/loading/mutations";
import { actions } from "@/store/modules/loading/actions";
import { LoadingState } from "@/store/modules/loading/types";

const state: LoadingState = {
  apiWaitingCount: 0,
};

export const loading: Module<LoadingState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default loading;
