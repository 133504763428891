<template>
  <div v-if="type && event" class="util__events__content__body">
    <ItemsList>
      <ItemRow
        title="Date and time"
        :value="formatDateFromMs(event.created_date)"
      />
      <ItemRow title="Event name">
        <h6 class="value__row__bold">
          {{ type }}
        </h6>
      </ItemRow>
      <ItemRow title="Status">
        <div class="events__item__header__right">
          <span class="events__item__header__status">{{
            getStatus(type, event)
          }}</span>
        </div>
      </ItemRow>
      <BiomedEvent v-if="type === 'biomed'" :type="type" :event="event" />
      <UtilizationEvent
        v-else-if="type === 'utilization'"
        :type="type"
        :event="event"
      />
      <LocationEvent
        v-else-if="type === 'location'"
        :type="type"
        :event="event"
      />
      <FinancialEvent
        v-else-if="type === 'financial'"
        :type="type"
        :event="event"
      />
    </ItemsList>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import ItemRow from "../../../resusables/list/ItemRow";
import ItemsList from "../../../resusables/list/ItemsList";
import { viewDevice } from "@/composables/devices/view-device";
import BiomedEvent from "@/components/devices/events/open/BiomedEvent";
import UtilizationEvent from "@/components/devices/events/open/UtlizationEvent";
import LocationEvent from "@/components/devices/events/open/LocationEvent";
import FinancialEvent from "@/components/devices/events/open/FinancialEvent";

export default defineComponent({
  name: "ViewDeviceEvent",
  components: {
    FinancialEvent,
    LocationEvent,
    UtilizationEvent,
    BiomedEvent,
    ItemsList,
    ItemRow,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);
    const { formatStatus, formatDateFromMs } = viewDevice();

    const getStatus = (type, event) => {
      if (type === "location") {
        return event.type;
      }
      if (type === "financial") {
        return formatStatus(event.type);
      }
      return formatStatus(event.status);
    };

    return {
      getStatus,
      formatDateFromMs,
      isOpen,
      dataIndex,
    };
  },
});
</script>

<style scoped></style>
