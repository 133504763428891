<template>
  <ItemRow title="Location">
    <div v-if="event.location" class="device__location__levels">
      <h6>{{ event.location.building_name }}</h6>
      <BaseIcon icon="greater-than" />
      <h6>{{ event.location.floor_name }}</h6>
      <BaseIcon icon="greater-than" />
      <h6>{{ event.location.room_name }}</h6>
    </div>
  </ItemRow>
  <ItemRow title="User id">
    <h5 class="modal__table__row__value">
      {{ event.user_id || "N/A" }}
    </h5>
  </ItemRow>
</template>

<script>
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import ItemRow from "../../../resusables/list/ItemRow";

export default defineComponent({
  name: "LocationEvent",

  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  components: { ItemRow, BaseIcon },
});
</script>

<style scoped></style>
