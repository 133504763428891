<template>
  <section id="login">
    <h1>Good</h1>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppStatus",
});
</script>
