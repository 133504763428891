<template>
  <div class="cost__card__item">
    <h6 class="cost__card__item__title">
      {{ title }}
    </h6>
    <div class="cost__card__item__list">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CostCardItem",

  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped></style>
