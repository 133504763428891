<template>
  <div class="demo-date-picker">
    <div class="block">
      <el-date-picker
        v-model="date"
        type="daterange"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        format="DD MMMM YYYY"
        value-format="YYYY-MM-DD"
        :shortcuts="shortcuts"
        :disabled-date="isDateDisabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref, watch } from "vue";
import { defineComponent } from "vue";
import { ElDatePicker } from "element-plus";
import moment from "moment";
import store from "@/store";

export default defineComponent({
  components: { ElDatePicker },

  setup(_, { emit }) {
    // const last30Days = moment().subtract(30, "days").format("YYYY-MM-DD");
    // const dateTo = moment().format("YYYY-MM-DD");
    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const start = moment().subtract(7, "days");
          const end = moment();
          return [start, end];
        },
      },
      {
        text: "Last 30 days",
        value: () => {
          const start = moment().subtract(30, "days");
          const end = moment();
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const start = moment().subtract(1, "months").startOf("month");
          const end = moment().subtract(1, "months").endOf("month");
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const start = moment().subtract(3, "months");
          const end = moment();
          return [start, end];
        },
      },
      {
        text: "Last 6 months",
        value: () => {
          const start = moment().subtract(6, "months");
          const end = moment();
          return [start, end];
        },
      },
      {
        text: "This year",
        value: () => {
          const start = moment().startOf("year");
          const end = moment();
          return [start, end];
        },
      },
      {
        text: "Last year",
        value: () => {
          const start = moment().subtract(1, "years").startOf("year");
          const end = moment().subtract(1, "years").endOf("year");
          return [start, end];
        },
      },
    ];

    const dateFrom = computed(() => {
      return store.getters["filters/dateFrom"];
    });

    const dateTo = computed(() => {
      return store.getters["filters/dateTo"];
    });

    const date = ref([dateFrom.value, dateTo.value]);

    const isDateDisabled = (date) => {
      return moment(date).isAfter(moment().format("YYYY-MM-DD"));
    };

    watch(date, (value) => {
      store.commit("filters/SET_DATE_FROM", value[0]);
      store.commit("filters/SET_DATE_TO", value[1]);
      emit("date_updated");
    });

    return {
      date,
      shortcuts,
      isDateDisabled,
    };
  },
});
</script>
<style>
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

body {
  --el-color-primary: #016667;
  --el-input-focus-border-color: #016667;
}
.el-date-table td.end-date .el-date-table-cell__text,
.el-date-table td.start-date .el-date-table-cell__text {
  @apply bg-primary;
}

.el-date-table td.available:hover {
  @apply text-primary;
}

.el-range-editor.el-input__wrapper {
  height: 40px;
  width: 330px;
}

.demo-date-picker .block {
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  /*margin-bottom: 20px;*/
}

input.el-range-input:focus {
  --tw-ring-color: none;
}

.el-picker-panel__sidebar {
}

.el-picker-panel__shortcut {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
