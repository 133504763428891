<template>
  <div v-if="show" class="add-access-point find-devices__forms active">
    <div class="form-head">
      <h3>Mark as available</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="submit" class="form">
      <p>
        Confirm device location. The device location is auto-set based on your
        phone’s location.
      </p>
      <UpdateDeviceLocationForm v-model="location" />
      <div class="form-footer">
        <button
          :disabled="disabled"
          type="submit"
          class="form-footer__btn-full"
        >
          Mark as available
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";
import UpdateDeviceLocationForm from "@/components/find-devices/forms/UpdateDeviceLocationForm.vue";
import { DeviceStatusEnums } from "@/store/modules/devices/types";

export default defineComponent({
  name: "MarkAsAvailableModal",
  components: { UpdateDeviceLocationForm, BaseIcon },
  setup(_, { emit }) {
    const disabled = ref(false);
    const status = DeviceStatusEnums.AVAILABLE;

    const location = ref({
      unit_name: "",
      building_id: "",
      floor_id: "",
      room_id: "",
      bed_id: "",
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const show = computed(() => {
      return store.getters["findDevices/showMarkAsAvailable"];
    });

    const close = () => {
      store.dispatch("findDevices/setModal", {
        modal: "showMarkAsAvailable",
        show: false,
      });
    };

    const getLocationId = () => {
      let location_id = "";

      if (location.value.bed_id) {
        location_id = location.value.bed_id;
      } else if (location.value.room_id) {
        location_id = location.value.room_id;
      } else if (location.value.floor_id) {
        location_id = location.value.floor_id;
      } else if (location.value.building_id) {
        location_id = location.value.building_id;
      }
      return location_id;
    };

    const submit = async () => {
      const location_id = getLocationId();
      const buildingId = location.value.building_id;
      const floorId = location.value.floor_id;
      const roomId = location.value.room_id;
      const unitName = location.value.unit_name;
      disabled.value = true;
      await Promise.all([
        store.dispatch("devices/updateDeviceStatus", {
          deviceId: currentDevice.value.id,
          status,
        }),
        location_id &&
          store.dispatch("devices/updateDevice", {
            deviceId: currentDevice.value.id,
            data: { location_id },
          }),
        unitName &&
          buildingId &&
          floorId &&
          roomId &&
          store.dispatch("locations/updateRoomUnit", {
            buildingId,
            floorId,
            roomId,
            data: {
              unit_name: unitName,
            },
          }),
      ]);
      disabled.value = false;
      emit("update-map", status);
    };

    return {
      disabled,
      close,
      show,
      location,
      submit,
    };
  },
});
</script>
