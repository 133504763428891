<template>
  <div class="location__navigations__modal floor">
    <h6
      v-for="(floor, key) in floors"
      :key="key"
      :class="{ active: floor.id === currentFloor.id }"
      @click="updateFloor(floor)"
    >
      {{ floor.floor_number }}
    </h6>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "../../store";
import { access_points } from "@/composables/maps/access_points";

export default defineComponent({
  name: "FloorsNavigation",
  setup(_, { emit }) {
    const { hasAccessPointCoordinates } = access_points();

    const currentFloor = computed(() => {
      return store.getters["locations/floor"];
    });

    const accessPoint = computed(() => {
      return store.getters["accessPoints/accessPoint"];
    });

    const floors = computed(() => {
      return store.getters["locations/floors"];
    });
    const showAdd = computed(() => {
      return store.getters["accessPoints/showAdd"];
    });

    const updateFloor = (floor) => {
      store.commit("locations/SET_FLOOR_NUMBER", floor.floor_number);
      store.commit("locations/SET_FLOOR", floor);
      if (hasAccessPointCoordinates.value) {
        const accessPointData = JSON.parse(JSON.stringify(accessPoint.value));
        accessPointData.coordinate = null;
        store.commit("accessPoints/SET_ACCESS_POINT", accessPointData);
        if (!showAdd.value) {
          store.commit("accessPoints/SET_SHOW_ADD", true);
        }
      }
      emit("update-map", "floor");
    };
    return {
      currentFloor,
      floors,
      updateFloor,
    };
  },
});
</script>
