import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/analytics/getters";
import { mutations } from "@/store/modules/analytics/mutations";
import { actions } from "@/store/modules/analytics/actions";
import { AnalyticsState } from "@/store/modules/analytics/types";

export const state: AnalyticsState = {
  data: [],
  utilizationData: {},
  financialData: {},
  biomedTimelineData: {},
  biomedMeantimesData: {},
  devicesReconciliationCounts: {},
  utilizationRates: {},
};

export const repairs: Module<AnalyticsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default repairs;
