import { MutationTree } from "vuex";
import { AccessPointState } from "@/store/modules/access-points/types";

export const mutations: MutationTree<AccessPointState> = {
  SET_ACCESS_POINT(state: AccessPointState, data: object) {
    state.accessPoint = data;
    return state.accessPoint;
  },
  SET_SHOW_LOCATION_PROMPT(state: AccessPointState, data: boolean) {
    state.showLocationPrompt = data;
    return state.showLocationPrompt;
  },
  SET_SHOW_ADD(state: AccessPointState, data: boolean) {
    state.showAdd = data;
    return state.showAdd;
  },
  SET_SHOW_COVERAGE(state: AccessPointState, data: boolean) {
    state.showCoverage = data;
    return state.showCoverage;
  },
  SET_COVERAGES(state: AccessPointState, data: string[]) {
    state.coverages = data;
    return state.coverages;
  },
  SET_LAST_TIME_ACCESS_POINTS_REFRESHED(
    state: AccessPointState,
    data: null | Date
  ) {
    state.lastTimeAccessPointsRefreshed = data;
    return state.lastTimeAccessPointsRefreshed;
  },
};
