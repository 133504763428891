import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { TicketsState } from "@/store/modules/tickets/types";
import AxiosClient from "@/services/api";
const BASE_URL = "/v1";

export const actions: ActionTree<TicketsState, RootState> = {
  getRepairTickets(
    { commit },
    { pagination = { limit: 25, offset: 0 }, sort = null } = {}
  ) {
    if (!pagination) pagination = { limit: 25, offset: 0 };
    let url = `/work-orders?limit=${pagination.limit}&offset=${pagination.offset}&`;

    if (sort && sort.order_by && sort.order_direction) {
      url += `order_by=${sort.order_by}&order_direction=${sort.order_direction}&`;
    }

    const filter = this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }
    // if (filter.biomedMeantimesType) {
    //   url += `type=${filter.biomedMeantimesType}&`;
    // }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_REPAIR_TICKETS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getRepairTicket({ commit }, ticketId) {
    return AxiosClient.get(`${BASE_URL}/work-orders/${ticketId}`)
      .then((res) => {
        commit("SET_REPAIR_TICKET", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getRepairTicketEvents({ commit }, ticketId) {
    return AxiosClient.get(`${BASE_URL}/work-orders/${ticketId}/events`)
      .then((res) => {
        commit("SET_REPAIR_TICKET_EVENTS", res.data.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
