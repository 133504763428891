<template>
  <BaseLayout contentClass="devices__main__content__map">
    <div class="find-devices">
      <Filters @apply_filters="changeData" :allowedFilters="allowedFilters" />
      <div class="find-devices___map">
        <FindDevicesRoomsMap />
        <LocationNavigations @updateMap="changeData" />
        <MapDevicesListModal
          @updateMap="changeData"
          @updateProximity="updateProximity"
        />
        <DeviceDetailModal />

        <AssignToPatientModal />
        <AssignToClinicianModal @updateMap="changeData" />
        <MarkAsDirtyModal @updateMap="changeData" />
        <MarkAsAvailableModal @updateMap="changeData" />
        <SubmitRepairTicketModal @updateMap="changeData" />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import FindDevicesRoomsMap from "@/components/find-devices/FindDevicesRoomsMap.vue";
import Filters from "@/components/resusables/filters/Filters.vue";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import LocationNavigations from "@/components/locations/LocationNavigations.vue";
import MapDevicesListModal from "@/components/find-devices/MapDevicesListModal.vue";
import DeviceDetailModal from "@/components/find-devices/DeviceDetailModal.vue";
import AssignToPatientModal from "@/components/find-devices/forms/AssignToPatientModal.vue";
import AssignToClinicianModal from "@/components/find-devices/forms/AssignToClinicianModal.vue";
import MarkAsDirtyModal from "@/components/find-devices/forms/MarkAsDirtyModal.vue";
import MarkAsAvailableModal from "@/components/find-devices/forms/MarkAsAvailableModal.vue";
import SubmitRepairTicketModal from "@/components/find-devices/forms/SubmitRepairTicketModal.vue";
import { DeviceStatusEnums } from "@/store/modules/devices/types";
import { find_devices } from "@/composables/maps/find_devices";

export default defineComponent({
  name: "FindDevices",

  components: {
    SubmitRepairTicketModal,
    MarkAsAvailableModal,
    MarkAsDirtyModal,
    AssignToClinicianModal,
    AssignToPatientModal,
    DeviceDetailModal,
    MapDevicesListModal,
    LocationNavigations,
    Filters,
    FindDevicesRoomsMap,
    BaseLayout,
  },

  setup() {
    const allowedFilters = [
      FilterScopeEnums.DEVICE_STATUSES,
      FilterScopeEnums.LOCATION_UNIT,
    ];

    const { setDefaultFilter, resetCenterOfMapAfterSelect } = find_devices();

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    onMounted(async () => {
      await Promise.all([getData(), setDefaultFilter()]);
    });

    const geojson = computed(() => {
      return store.getters["locations/geojson"];
    });

    const getData = async () => {
      await Promise.all([
        store.dispatch("locations/getBuildings"),
        store.dispatch("locations/getFloors"),
        store.dispatch("locations/getRoomUnits"),

        store.dispatch("locations/getRoomsCoordinates"),
        store.dispatch("devices/getAllDevicesProximity"),
        store.dispatch("findDevices/getAllDevicesList"),
        store.dispatch("devices/getStatusesCount"),
      ]);
    };

    const getStatusToastTitle = (status) => {
      let title = "";
      if (status === DeviceStatusEnums.AVAILABLE) {
        title = `SERIAL ${currentDevice.value.serial_number} has been marked as available.`;
      } else if (status === DeviceStatusEnums.DIRTY) {
        title = `SERIAL ${currentDevice.value.serial_number} has been marked as dirty.`;
      } else if (status === DeviceStatusEnums.IN_REPAIR) {
        title = "Ticket # - W001 has been issued.";
      } else if (status === DeviceStatusEnums.IN_USE) {
        title = `SERIAL ${currentDevice.value.serial_number} has been assigned to clinician.`;
      }
      return title;
    };

    const changeData = async (status) => {
      store.commit("findDevices/RESET_SELECTED_ITEM");
      store.commit("findDevices/SET_SELECTED_DEVICE", null);
      await Promise.all([
        status && store.commit("findDevices/CLOSE_ALL_MODALS"),
        status &&
          currentDevice.value &&
          store.dispatch("toast/showToast", {
            title: getStatusToastTitle(status),
            // description: `Device: ${currentDevice.value.description} (${currentDevice.value.vendor?.brand_name})`,
            description: "",
          }),
        store.dispatch("locations/getRoomsCoordinates"),
        store.dispatch("devices/getAllDevicesProximity"),
        store.dispatch("findDevices/getAllDevicesList"),
        store.dispatch("devices/getStatusesCount"),
        store.dispatch("locations/getFloors"),
      ]);
      status && store.commit("findDevices/SET_CURRENT_DEVICE", null);
    };
    const updateProximity = async ({ lat, lng, device }) => {
      await Promise.all([
        store.dispatch("locations/getRoomsCoordinates"),
        store.dispatch("devices/getAllDevicesProximity"),
        // store.dispatch("findDevices/getAllDevicesList"),
        // store.dispatch("devices/getStatusesCount"),
      ]);
      resetCenterOfMapAfterSelect(device);
    };

    return {
      allowedFilters,
      geojson,
      getData,
      changeData,
      updateProximity,
    };
  },
});
</script>
