export interface FilterState {
  selectedFilter: string;
  dateFrom: string;
  dateTo: string;
  filters: FilterTypes;
  searchString: string;
}

export interface FilterTypes {
  organization: string;
  hospital: object | string;
  department: object | string;
  category: object | string;
  subCategory: object | string;
  vendor: object | string;
  reconciliationStatuses: string[];
  deviceStatuses: string;
  locationUnit: object | string;
}

export enum FilterScopeEnums {
  ORGANIZATION = "ORGANIZATION",
  DEPARTMENT = "DEPARTMENT",
  CATEGORY = "CATEGORY",
  SUBCATEGORY = "SUBCATEGORY",
  VENDOR = "VENDOR",
  RECONCILIATION_STATUSES = "RECONCILIATION_STATUSES",
  DEVICE_STATUSES = "DEVICE_STATUSES",
  LOCATION_UNIT = "LOCATION_UNIT",
  FLOOR_NUMBER = "FLOOR_NUMBER",
  DEVICE_SEARCH = "DEVICE_SEARCH",
}

export const workOrderInitialStatuses = [
  "collected",
  "diagnosed",
  "escalated",
  "open",
  "repaired",
  "replaced",
];

export const workOrderFinalStatuses = [
  "closed",
  "collected",
  "diagnosed",
  "escalated",
  "repaired",
  "replaced",
];

export const biomedEventTypes = [
  "alert",
  "disposition",
  "install",
  "onboarding",
  "preventative_maintenance",
  "recall",
  "registration",
  "repair",
];
