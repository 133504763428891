import { MutationTree } from "vuex";
import { TicketsState } from "@/store/modules/tickets/types";

export const mutations: MutationTree<TicketsState> = {
  SET_REPAIR_TICKETS(state: TicketsState, data: object[]) {
    state.tickets = data;
    return state.tickets;
  },
  SET_REPAIR_TICKET(state: TicketsState, data: object) {
    state.ticket = data;
    return state.ticket;
  },
  SET_REPAIR_TICKET_EVENTS(state: TicketsState, data: object[]) {
    state.events = data;
    return state.events;
  },
};
