<template>
  <div
    @click="toggleDropdown"
    class="modals-trigger dashboard__main__header__dropdown__button"
    ref="modalsTrigger"
  >
    <div tabindex="0" class="dashboard__main__header__button__container">
      <div class="user__initials dashboard__main__header__button__img">
        {{ initials }}
      </div>
      <div class="dashboard__main__header__button__down">
        <BaseIcon
          icon="arrow-down"
          class="dashboard__main__header__button__down__svg"
        />
      </div>
    </div>

    <div
      class="modals dashboard__main__header__dropdown__list"
      :class="{ active: showDropdown }"
    >
      <div class="dashboard__main__header__dropdown__list__item">
        <div class="header__user">
          <div class="user__initials header__user__initials">
            {{ initials }}
          </div>
          <div class="header__user__titles">
            <div class="header__user__titles__name">{{ fullName }}</div>
            <p class="header__user__titles__position">Hospital Admin</p>
          </div>
        </div>
      </div>
      <div class="dashboard__main__header__dropdown__list__item__divide"></div>
      <div class="dashboard__main__header__dropdown__list__item">
        <button
          @click="logout"
          class="dashboard__main__header__dropdown__list__item__link"
        >
          <BaseIcon
            class="dashboard__main__header__dropdown__list__item__link__img"
            icon="header/logout"
          />
          <span> Logout </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon";
import store from "@/store";
import { helpers } from "@/composables/helpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HeaderDropdown",
  components: { BaseIcon },

  setup() {
    const router = useRouter();

    const { getFirstLetter } = helpers();

    const toggleDropdown = () => {
      store.dispatch("dropdowns/toggleDropdown", "header_user");
    };

    const showDropdown = computed(() => {
      return store.state.dropdowns.header_user;
    });

    const user = computed(() => {
      return store.getters["auth/authUser"];
    });

    const initials = computed(() => {
      return user.value
        ? `${getFirstLetter(user.value.first_name)}${getFirstLetter(
            user.value.last_name
          )}`
        : null;
    });

    const fullName = computed(() => {
      return user.value
        ? `${user.value.first_name} ${user.value.last_name}`
        : null;
    });

    const logout = async () => {
      await store.dispatch("auth/logout");
      await router.push({ name: "Logout" });
    };

    return { showDropdown, toggleDropdown, logout, user, initials, fullName };
  },
});
</script>

<style scoped></style>
