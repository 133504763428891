import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "form-head" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "access-point__form-footer" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.showUpdateRoom && _ctx.room }, "add-access-point"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Update room", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    (_ctx.room)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)), ["prevent"])),
          class: "form"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseLabel, { title: "Unit" }),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.room.unit) = $event)),
              class: "base__select"
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("option", { value: null }, "Select Unit", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roomsUnits, (unit, key) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: key,
                  value: unit.acronym
                }, _toDisplayString(unit.name), 9, _hoisted_3))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.room.unit]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseLabel, { title: "Label" }),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.room.label) = $event)),
              placeholder: "Enter Label",
              class: "base__input uppercase",
              type: "text"
            }, null, 512), [
              [_vModelText, _ctx.room.label]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseLabel, { title: "Name" }),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.room.name) = $event)),
              placeholder: "Enter name",
              class: "base__input",
              type: "text",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.room.name]
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, "Room names format should be R-001 (1 letter, 3 numbers)", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "submit",
              class: "access-point__add-submit",
              disabled: _ctx.disabled
            }, " Update Room ", 8, _hoisted_7),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
              class: "access-point__add-cancel"
            }, " Cancel ")
          ])
        ], 32))
      : _createCommentVNode("", true)
  ], 2))
}