import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AccessPointState } from "@/store/modules/access-points/types";
import AxiosClient from "@/services/api";
const BASE_URL = "/v1";
const buildingName = "Main Hospital";

export const actions: ActionTree<AccessPointState, RootState> = {
  storeAccessPoint({ commit }, accessPoint) {
    // TODO: Get Building Name from /locations/buildings
    return AxiosClient.post(
      `${BASE_URL}/maps/access-point/${buildingName}/${accessPoint.floorNumber}/add`,
      {
        access_point: {
          label: accessPoint.label,
        },
        coordinates: [[accessPoint.coordinate.lng, accessPoint.coordinate.lat]],
      }
    )
      .then((res) => {
        commit("SET_ACCESS_POINT", null);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  updateAccessPoint({ commit }, accessPoint) {
    // TODO: Get Building Name from /locations/buildings
    const updateLabel =
      accessPoint.status && accessPoint.status === "unavailable";
    let data: any = {
      coordinates: [[accessPoint.coordinate.lng, accessPoint.coordinate.lat]],
    };
    if (updateLabel) {
      data = {
        ...data,
        access_point: {
          label: accessPoint.label,
        },
      };
    }
    return AxiosClient.post(
      `${BASE_URL}/maps/access-point/${buildingName}/${accessPoint.floorNumber}/${accessPoint.jsonId}`,
      data
    )
      .then((res) => {
        commit("SET_ACCESS_POINT", null);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  deleteAccessPoint({ commit }, accessPoint) {
    // TODO: Get Building Name from /locations/buildings
    return AxiosClient.delete(`${BASE_URL}/access-points/${accessPoint.id}`)
      .then((res) => {
        commit("SET_ACCESS_POINT", null);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
