import { MutationTree } from "vuex";
import { ToastState } from "@/store/modules/toast/types";

export const mutations: MutationTree<ToastState> = {
  SHOW_TOAST(state, { title, description }) {
    state.title = title;
    state.description = description;
    state.visible = true;
  },
  SET_TOAST_VISIBILITY(state, status: boolean) {
    state.visible = status;
    return state.visible;
  },
  HIDE_TOAST(state) {
    state.title = "";
    state.description = "";
    state.visible = false;
    return state;
  },
};
