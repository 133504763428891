<template>
  <div :class="{ active: show }" class="add-access-point">
    <div class="form-head">
      <h3>Assign to clinician</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="submit" class="form">
      <p>Device will be assigned to:</p>
      <div class="form-group">
        <BaseLabel title="Clinician name" />
        <input
          v-model="data.name"
          class="base__input"
          placeholder="Enter clinician name"
          type="text"
          required
        />
      </div>
      <div class="form-group">
        <BaseLabel title="Add Comment" />
        <textarea
          v-model="data.note"
          :class="{ input__error: hasNoteError }"
          class="base__textarea"
          placeholder="Write comment here..."
          required
        ></textarea>
        <span :class="{ error: hasNoteError }" class="base__textarea__counter"
          >{{ data.note.length }}/{{ maxLength }}</span
        >
      </div>
      <div class="form-footer">
        <button
          :disabled="disabled || hasNoteError"
          type="submit"
          class="form-footer__btn-full"
        >
          Mark as In-Use
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";
import { encryption } from "@/composables/phi/encryption";
import { DeviceStatusEnums } from "@/store/modules/devices/types";

export default defineComponent({
  name: "AssignToClinicianModal",
  components: { BaseLabel, BaseIcon },
  setup(_, { emit }) {
    const status = DeviceStatusEnums.IN_USE;
    const maxLength = 128;

    const disabled = ref(false);
    const { encrypt } = encryption();

    const authUser = computed(() => {
      return store.getters["auth/authUser"];
    });

    const data = ref({
      name: "",
      note: "",
    });

    onMounted(() => {
      disabled.value = false;
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const show = computed(() => {
      return store.getters["findDevices/showAssignToClinician"];
    });

    watch(show, (val) => {
      if (val) {
        data.value.name = `${authUser.value.first_name} ${authUser.value.last_name}`;
        data.value.note = "";
      }
    });

    const hasNoteError = computed(() => {
      return data.value.note.length > maxLength;
    });

    const submit = async () => {
      disabled.value = true;
      const encryptedData = await encrypt(data.value);
      if (!encryptedData) {
        disabled.value = false;
        await store.dispatch("toast/showToast", {
          title: "Encryption failed!",
          description: "",
        });
        return;
      }
      await store
        .dispatch("devices/assignDeviceToClinician", {
          deviceId: currentDevice.value.id,
          borrower_hash: encryptedData,
        })
        .then(() => {
          disabled.value = false;
        })
        .catch(() => {
          disabled.value = false;
        });
      disabled.value = false;
      emit("update-map", status);
    };

    const close = () => {
      data.value.name = "";
      store.dispatch("findDevices/setModal", {
        modal: "showAssignToClinician",
        show: false,
      });
    };

    return {
      disabled,
      close,
      hasNoteError,
      show,
      submit,
      data,
      maxLength,
    };
  },
});
</script>
