<template>
  <h3 class="events__list__item__title">{{ event.type }}</h3>
  <div v-if="isOpen" class="events__item__details">
    <div class="events__item__details__item">
      <h5>Status date:</h5>
      <h6>{{ formatDateFromMs(event.status_date) }}</h6>
    </div>
    <div class="events__item__details__item">
      <h5>Priority:</h5>
      <h6 :class="`event__biomed__priority__${event.priority}`">
        {{ event.priority }}
      </h6>
    </div>
    <!--    <div class="events__item__details__item">-->
    <!--      <h5>Description:</h5>-->
    <!--      <h6>Trimedx reported problem</h6>-->
    <!--    </div>-->
    <div class="events__item__details__item">
      <h5>Type:</h5>
      <h6>Biomed event</h6>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helpers } from "@/composables/helpers";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "BiomedEvents",

  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const { formatMoney } = helpers();

    const { formatStatus, formatDate, formatDateFromMs, addStatusClass } =
      viewDevice();

    return {
      formatMoney,
      formatStatus,
      formatDateFromMs,
      formatDate,
      addStatusClass,
    };
  },
});
</script>

<style scoped></style>
