import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/devices/getters";
import { mutations } from "@/store/modules/devices/mutations";
import { actions } from "@/store/modules/devices/actions";
import { DevicesState } from "@/store/modules/devices/types";

export const DevicesData: DevicesState = {
  devices: {},
  device: {},
  hospitals: [],
  departments: [],
  categories: [],
  subCategories: [],
  vendors: [],
  reconciliationEvent: null,
  deviceEvents: {},
  deviceBiomedEvents: [],
  deviceFinancialEvents: [],
  deviceLocationEvents: [],
  deviceUtilizationEvents: [],
  deviceReconciliationCounts: [],
  deviceReconciliationStatusesCounts: {},
  deviceStatusesCounts: {},
  deviceFiles: {},
  devicesProximity: [],
  utilGroupBy: "day",
};

export const devices: Module<DevicesState, RootState> = {
  namespaced: true,
  state: DevicesData,
  getters,
  actions,
  mutations,
};

export default devices;
