import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "location__navigations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BuildingsNavigation = _resolveComponent("BuildingsNavigation")!
  const _component_FloorsNavigation = _resolveComponent("FloorsNavigation")!
  const _component_CoverageNavigation = _resolveComponent("CoverageNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAccessPointsRoute)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCoverage && _ctx.toggleCoverage(...args))),
          class: "location__navigations__item"
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Coverage", -1)),
          _createVNode(_component_BaseIcon, {
            icon: `expand-${_ctx.showCoverage ? 'less' : 'more'}`
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "location__navigations-line" }, null, -1)),
    (_ctx.isFindDevicesRoute)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open('building'))),
          class: _normalizeClass(["location__navigations__item", { active: _ctx.show === 'building' }])
        }, [
          (_ctx.show === 'building')
            ? (_openBlock(), _createBlock(_component_BuildingsNavigation, {
                key: 0,
                onUpdateMap: _ctx.update
              }, null, 8, ["onUpdateMap"]))
            : _createCommentVNode("", true),
          _createElementVNode("h6", null, _toDisplayString(_ctx.building.building_name), 1),
          _createVNode(_component_BaseIcon, {
            icon: `expand-${_ctx.show === 'building' ? 'less' : 'more'}`
          }, null, 8, ["icon"])
        ], 2))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "location__navigations-line" }, null, -1)),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open('floor'))),
      class: _normalizeClass(["location__navigations__item", { active: _ctx.show === 'floor' }])
    }, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.floor.floor_name), 1),
      _createVNode(_component_BaseIcon, {
        icon: `expand-${_ctx.show === 'floor' ? 'less' : 'more'}`
      }, null, 8, ["icon"])
    ], 2),
    (_ctx.show === 'floor')
      ? (_openBlock(), _createBlock(_component_FloorsNavigation, {
          key: 2,
          onUpdateMap: _ctx.update
        }, null, 8, ["onUpdateMap"]))
      : _createCommentVNode("", true),
    (_ctx.showCoverage)
      ? (_openBlock(), _createBlock(_component_CoverageNavigation, {
          key: 3,
          data: _ctx.coverageData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}