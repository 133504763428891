import { Module } from "vuex";
import { AuthState } from "@/store/modules/auth/types";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/auth/getters";
import { mutations } from "@/store/modules/auth/mutations";
import { actions } from "@/store/modules/auth/actions";
import getEnv from "@/utils/env";

const COGNITO_CLIENT_ID = getEnv("VUE_APP_SQUID_COGNITO_CLIENT_ID");
const COGNITO_APP_DOMAIN = getEnv("VUE_APP_SQUID_COGNITO_APP_DOMAIN");
const COGNITO_REDIRECT_URI = getEnv("VUE_APP_SQUID_COGNITO_REDIRECT_URI");
const APP_URL = getEnv("VUE_APP_SQUID_BASE_URL");

const state: AuthState = {
  user: null,
  jwt: null,
  code: null,
  loginEndpoint: `https://${COGNITO_APP_DOMAIN}/login?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`,
  logoutEndpoint: `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${APP_URL}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`,
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default auth;
