<template>
  <div class="modal__table__row">
    <h5 class="modal__table__row__title">{{ title }}</h5>
    <div class="modal__table__row__body">
      <h5 v-if="value !== null" class="modal__table__row__value">
        {{ isNumber(value) ? value : value || "-" }}
      </h5>
      <slot v-else />
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },

  setup() {
    const isNumber = (str) => {
      return /^\d+$/.test(str);
    };

    return {
      isNumber,
    };
  },
};
</script>

<style scoped></style>
