import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filters__category__group" }
const _hoisted_2 = { class: "filters__category__group__item__head" }
const _hoisted_3 = { class: "filter__input__group" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "filters__category__group__item__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.scopeList), (key, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "filters__category__group__item"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseCheckbox, {
              name: `head-${_ctx.filter}`,
              "onUpdate:checked": ($event: any) => (_ctx.checkGroup(_ctx.filter, key, index, $event)),
              checked: _ctx.group[index]
            }, null, 8, ["name", "onUpdate:checked", "checked"]),
            _createVNode(_component_BaseLabel, { title: key }, null, 8, ["title"])
          ]),
          _createElementVNode("div", {
            onClick: ($event: any) => (_ctx.selectGroup(index))
          }, [
            _createVNode(_component_BaseIcon, {
              icon: `expand-${_ctx.activeGroup[index] ? 'less' : 'more'}`
            }, null, 8, ["icon"])
          ], 8, _hoisted_4)
        ]),
        (_ctx.activeGroup[index])
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scopeList[key], (scope, ind) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: ind,
                  class: "filters__category__group__item__body__item"
                }, [
                  _createVNode(_component_BaseCheckbox, {
                    name: _ctx.filter,
                    "onUpdate:checked": ($event: any) => (_ctx.checkValues(_ctx.filter, key, scope, index, $event)),
                    checked: _ctx.selecting[_ctx.filter].some((fil) => fil === scope)
                  }, null, 8, ["name", "onUpdate:checked", "checked"]),
                  _createVNode(_component_BaseLabel, {
                    title: _ctx.split(scope)
                  }, null, 8, ["title"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}