import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-head" }
const _hoisted_2 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.show }, "add-access-point find-devices__forms"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Submit repair ticket", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
      class: "form"
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("p", null, " Device has a problem? Submit a repair ticket so it can be picked for repair. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseLabel, { title: "Description" }),
        _cache[3] || (_cache[3] = _createElementVNode("textarea", {
          class: "base__textarea",
          placeholder: "Write device problem here..."
        }, null, -1))
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-footer" }, [
        _createElementVNode("button", {
          type: "submit",
          class: "form-footer__btn-full"
        }, " Submit repair ticket ")
      ], -1))
    ], 32)
  ], 2))
}