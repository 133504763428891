import axios, { AxiosError, AxiosResponse } from "axios";
import getEnv from "@/utils/env";
import getEnvs from "@/utils/env/decrypt";
import store from "@/store";

await getEnvs();

const baseDomain = getEnv("VUE_APP_SQUID_BASE_API_URL");

const baseURL = `${baseDomain}`;
const httpClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(async (config: any) => {
  const params = config.params;
  const token = localStorage.getItem("access-token") || "";
  const url = config.url;

  if (token && !url.startsWith("/auth/signin")) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (params && params.hideProgressBar) {
    return config;
  }

  store.dispatch("loading/startLoading");

  return config;
});

httpClient.interceptors.response.use(
  async (config: AxiosResponse) => {
    const params = config.config?.params;
    if (params && params.hideProgressBar) {
      return config;
    }
    store.dispatch("loading/doneLoading");
    return config;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      const COGNITO_CLIENT_ID = getEnv("VUE_APP_SQUID_COGNITO_CLIENT_ID");
      const COGNITO_APP_DOMAIN = getEnv("VUE_APP_SQUID_COGNITO_APP_DOMAIN");
      const COGNITO_REDIRECT_URI = getEnv("VUE_APP_SQUID_COGNITO_REDIRECT_URI");
      // const LOGOUT_URL = `${getEnv("VUE_APP_SQUID_BASE_URL")}/auth/login`;
      // const LOGOUT_URL = getEnv("VUE_APP_SQUID_COGNITO_LOGOUT_URI");

      window.location.href = `https://${COGNITO_APP_DOMAIN}/oauth2/authorize?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code&scope=openid`;
      // window.location.href = `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${LOGOUT_URL}`;
      // window.location.href = `https://${COGNITO_APP_DOMAIN}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${LOGOUT_URL}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code`;
    }
    store.dispatch("loading/doneLoading");
    return Promise.reject(error);
  }
);
// httpClient.defaults.timeout = 20000;
httpClient.defaults.timeout = 50000;

export default httpClient;
