import { computed } from "vue";
import store from "@/store";

export function auth() {
  const user = computed(() => {
    return store.getters["auth/authUser"];
  });

  const departmentNumber = computed(() => {
    return user.value?.department_number;
  });
  const validateRoute = (route: string) => {
    const ORDeptId = "18011";
    const NursingDeptId = "17000";
    const valid = [ORDeptId, NursingDeptId];
    return (
      !valid.includes(departmentNumber.value) ||
      (valid.includes(departmentNumber.value) && route === "/find-devices")
    );
  };

  const validateMiddleware = (route: string) => {
    const ORDeptId = "18011";
    const NursingDeptId = "17000";
    const valid = [ORDeptId, NursingDeptId];
    return valid.includes(departmentNumber.value) && route !== "/find-devices";
  };

  return {
    validateRoute,
    validateMiddleware,
  };
}
