<template>
  <div class="device-view__body__util__events">
    <div class="device-view__body__util__events__list">
      <div class="device__events__list">
        <DeviceTypeEvents
          v-for="(events, type, key) in deviceEvents"
          :current-type="currentType"
          @set="setEvent"
          :key="key"
          :events="events"
          :type="type"
        />
      </div>
    </div>
    <div class="device-view__body__util__events__content">
      <ViewDeviceEvent
        v-if="currentEvent && currentType"
        :event="currentEvent"
        :type="currentType"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import store from "@/store";
import DeviceTypeEvents from "@/components/devices/DeviceTypeEvents";
import ViewDeviceEvent from "@/components/devices/events/open/ViewDeviceEvent";

export default defineComponent({
  name: "DeviceUtilizationEvents",
  components: { ViewDeviceEvent, DeviceTypeEvents },
  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);

    const currentType = ref(null);
    const currentEvent = ref(null);

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const deviceEvents = computed(() => {
      return store.getters["devices/deviceEvents"];
    });

    const setEvent = (index1, type, event1) => {
      currentType.value = type;
      currentEvent.value = event1;
    };

    return {
      device,
      setEvent,
      currentType,
      currentEvent,
      deviceEvents,
      isOpen,
      dataIndex,
    };
  },
});
</script>
