import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import AxiosClient from "@/services/api";
import { LocationsState } from "@/store/modules/locations/types";

const BASE_URL = "/v1";
const mainBuildingName = "Main Hospital";

export const actions: ActionTree<LocationsState, RootState> = {
  getBuildings({ commit, state }) {
    return AxiosClient.get(`${BASE_URL}/locations/buildings`)
      .then((res) => {
        const buildings = res.data.data;
        commit("SET_BUILDINGS", res.data.data);

        if (!state.building) {
          const building = buildings.find(
            (building) => building.building_name === mainBuildingName
          );
          commit("SET_BUILDING", building);
        }

        return buildings;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  async getFloors({ commit, state }, buildingId = null) {
    const building: any = state.building;
    const buildingId1 = buildingId ? buildingId : building.id;
    try {
      const res = await AxiosClient.get(
        `${BASE_URL}/locations/buildings/${buildingId1}/floors`
      );
      const data = res.data.data;
      data.sort((a, b) => b.floor_number - a.floor_number);
      if (!buildingId) {
        commit("SET_FLOORS", data);
      }
      if (!state.floor) {
        const floor = data.find((floor) => floor.floor_number == 1);
        commit("SET_FLOOR", floor);
      }
      return data;
    } catch (err) {
      if (err instanceof Error) {
        const message_1 = err.message;
        return Promise.reject(new Error(message_1));
      }
    }
  },
  async getRooms(_, { buildingId, floorId }) {
    return AxiosClient.get(
      `${BASE_URL}/locations/buildings/${buildingId}/floors/${floorId}/rooms`
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getBeds(_, { buildingId, floorId, roomId }) {
    return AxiosClient.get(
      `${BASE_URL}/locations/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}/beds`
    )
      .then((res) => {
        return res.data.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getAccessPoints({ commit, state }) {
    let floor: any = state.floor;
    if (!floor) {
      floor = state.floors.find(
        (floor) => floor.floor_number === state.floorNumber
      );
      commit("SET_FLOOR", floor);
    }
    commit("SET_ACCESS_POINTS", []);
    return AxiosClient.get(`${BASE_URL}/access-points?floor_id=${floor.id}`)
      .then((res) => {
        commit("SET_ACCESS_POINTS", res.data.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  unmappedAccessPoints({ commit }) {
    return AxiosClient.get(`${BASE_URL}/ap-streams?mapped=false`)
      .then((res) => {
        commit("SET_UNMAPPED_ACCESS_POINTS", res.data.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getRoomUnits({ commit }) {
    return AxiosClient.get(`${BASE_URL}/locations/units`)
      .then((res) => {
        commit("SET_ROOMS_UNITS", res.data.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  updateRoomUnit(_, { buildingId, floorId, roomId, data }) {
    return AxiosClient.put(
      `${BASE_URL}/locations/buildings/${buildingId}/floors/${floorId}/rooms/${roomId}/`,
      data
    )
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getAccessPointsCoordinates({ commit, state }) {
    commit("SET_ACCESS_POINTS_COORDINATES", []);
    return AxiosClient.get(
      `${BASE_URL}/maps/access-point/${mainBuildingName}/${state.floorNumber}`
    )
      .then((res) => {
        commit("SET_ACCESS_POINTS_COORDINATES", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getRoomsCoordinates({ commit, state }) {
    // TODO: Get Building Name from /locations/buildings
    commit("SET_MAP_GEO_JSON", []);
    // const buildingName =
    //   state.building && state.building.building_name
    //     ? state.building.building_name
    //     : mainBuildingName;
    return AxiosClient.get(
      `${BASE_URL}/maps/locations/${mainBuildingName}/${state.floorNumber}`
    )
      .then((res) => {
        commit("SET_MAP_GEO_JSON", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  updateRoom({ commit, state }, room) {
    const req = {
      FLOOR: state.floorNumber,
      "ROOM-NAME": room.name,
      LABEL: room.label ? room.label.toUpperCase() : room.label,
      UNIT: room.unit,
    };
    return AxiosClient.post(
      `${BASE_URL}/maps/locations/${mainBuildingName}/${state.floorNumber}/${room.jsonId}`,
      req
    )
      .then((res) => {
        commit("SET_MAP_GEO_JSON", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
