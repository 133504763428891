import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Devices from "@/views/Devices/Devices.vue";
import RepairTickets from "@/views/Repairs/RepairTickets.vue";
import Contracts from "@/views/Contracts/Contracts.vue";
import store from "@/store";
import ViewDevice from "@/views/Devices/ViewDevice.vue";
import ViewRepairTicket from "@/views/Repairs/ViewRepairTicket.vue";
import OAuthCallback from "@/views/OAuthCallback.vue";
import AppStatus from "@/views/AppStatus.vue";
import ViewContract from "@/views/Contracts/ViewContract.vue";
import Login from "@/views/Auth/Login.vue";
import Logout from "@/views/Auth/Logout.vue";
import AccessPoints from "@/views/AccessPoints/AccessPoints.vue";
import FindDevices from "@/views/FindDevices/FindDevices.vue";
import { auth } from "@/composables/auth";
import getEnv from "@/utils/env";
import Rentals from "@/views/Rentals/Rentals.vue";
import RentalsForm from "@/views/Rentals/RentalsForm.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/callback",
    name: "OAuthCallback",
    component: OAuthCallback,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/app/status",
    name: "AppStatus",
    component: AppStatus,
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
    meta: {
      authRequired: true,
    },
    // children: [
    //   {
    //     path: ":id",
    //     name: "ViewDevice",
    //     component: ViewDevice,
    //     props: true,
    //   },
    // ],
  },
  {
    path: "/devices/:id",
    name: "ViewDevice",
    component: ViewDevice,
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/repairs",
    name: "RepairTickets",
    component: RepairTickets,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":id",
        name: "ViewRepairTicket",
        component: ViewRepairTicket,
      },
    ],
  },
  {
    path: "/contracts",
    name: "Contracts",
    component: Contracts,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ":id",
        name: "ViewContract",
        component: ViewContract,
      },
    ],
  },
  {
    path: "/access-points",
    name: "AccessPoints",
    component: AccessPoints,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/find-devices",
    name: "FindDevices",
    component: FindDevices,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/rentals",
    name: "Rentals",
    component: Rentals,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "form",
        name: "RentalsForm",
        component: RentalsForm,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const { validateMiddleware } = auth();

router.beforeEach((routeTo, routeFrom, next) => {
  store.commit("dropdowns/CLOSE_ALL_DROPDOWN");
  store.commit("loading/SET_API_WAITING_COUNT", 0);

  const redirectToLogin = () => {
    const COGNITO_REDIRECT_URI = getEnv("VUE_APP_SQUID_COGNITO_REDIRECT_URI");
    const COGNITO_CLIENT_ID = getEnv("VUE_APP_SQUID_COGNITO_CLIENT_ID");
    const COGNITO_APP_DOMAIN = getEnv("VUE_APP_SQUID_COGNITO_APP_DOMAIN");
    window.location.href = `https://${COGNITO_APP_DOMAIN}/oauth2/authorize?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code&scope=openid`;
  };

  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  // const authUser = store.getters["auth/authUser"];
  // const jwt = store.getters["auth/jwt"];
  const jwt = localStorage.getItem("access-token");

  if (!authRequired) {
    return next();
  }

  if (validateMiddleware(routeTo.fullPath)) {
    return next("/find-devices");
  }

  // if (authUser && jwt) {
  if (jwt) {
    return next();
  }

  return redirectToLogin();
  // return next({ name: "Logout" });
});

export default router;
