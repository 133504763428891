<template>
  <div :class="{ active: show }" class="add-access-point">
    <div class="form-head">
      <h3>Device Details</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <div class="">
      <div class="device__list__item__numbers">
        <h6>
          <span>Serial</span>
          <span>22739</span>
        </h6>
        <span>279</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";

export default defineComponent({
  name: "DeviceDetailModal",
  components: { BaseIcon },
  setup() {
    const disabled = ref(false);

    onMounted(() => {
      // store.commit("findDevices/SET_SHOW_MARK_IN_USE", false);
    });

    const currentFloor = computed(() => {
      return store.getters["locations/floorNumber"];
    });

    const show = computed(() => {
      return store.getters["accessPoints/showAdd"];
    });

    const accessPointData = ref({
      label: "",
      floorNumber: currentFloor.value,
    });

    const close = () => {
      accessPointData.value.label = "";
      // store.commit("findDevices/SET_SHOW_MARK_IN_USE", false);
    };

    return {
      disabled,
      close,
      show,
      accessPointData,
    };
  },
});
</script>
