import { MutationTree } from "vuex";
import {
  DashboardChartDataType,
  DashboardState,
} from "@/store/modules/dashboard/types";

export const mutations: MutationTree<DashboardState> = {
  SET_DATA(state: DashboardState, data: DashboardChartDataType[]) {
    state.data = data;
    return state.data;
  },
};
