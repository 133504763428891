<template>
  <div>
    <h5 v-if="title" class="modal__content__sub__title">{{ title }}</h5>
    <div class="modal__table">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemsList",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped></style>
