<template>
  <DoughnutChart
    :total="financialData.average"
    title="Average Cost of Ownership"
    totalLabel="Average Cost"
    :isCurrency="true"
    :chartData="chartDataList"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import DoughnutChart from "@/components/resusables/charts/DoughnutChart.vue";
import store from "@/store";

export default defineComponent({
  name: "ContractsChart",
  components: {
    DoughnutChart,
  },
  setup() {
    const financialData = computed(() => {
      return store.getters["analytics/financialData"];
    });

    const updateChartList = () => {
      return {
        datasets: [
          {
            data: [
              financialData.value["average_purchase"],
              financialData.value["average_rental"],
              financialData.value["average_maintenance"],
              financialData.value["average_preventative_maintenance"],
              financialData.value["average_disposition"],
            ],
            backgroundColor: [
              "#016667",
              "#DAB75D",
              "#B5CBCB",
              "#DAE5E6",
              "#DAE5E6",
            ],
          },
        ],
        labels: [
          "Purchases",
          "Rentals",
          "Service",
          "Maintenance",
          "Disposition",
        ],
      };
    };

    let chartDataList = ref(updateChartList());

    watch(financialData, () => {
      chartDataList.value = updateChartList();
    });

    return {
      chartDataList,
      financialData,
    };
  },
});
</script>
