<template>
  <div class="modals modal">
    <div class="modal__header">
      <h3 class="modal__header__title">Ticket Details</h3>
      <button @click="close" class="modals-trigger modal__header__close">
        <BaseIcon icon="modal-close" />
      </button>
    </div>
    <div class="modal__content tickets">
      <div class="modal__content___left tickets__left">
        <ItemsList title="Ticket Status">
          <ItemRow title="Status">
            <div
              :class="addStatusClass(computeStatus(ticket.status))"
              class="modal__contract__status"
            >
              {{ computeStatus(ticket.status) }}
            </div>
          </ItemRow>
          <ItemRow title="Ticket #" :value="ticket.number" />
          <ItemRow title="Date Issued" :value="formatDate(ticket.created_at)" />
          <ItemRow title="Estimate of completion" value="Mar 07, 2023" />
        </ItemsList>

        <ItemsList title="Ticket Description">
          <ItemRow title="Repair Cost" value="-" />
          <ItemRow
            title="Issued by"
            :value="getUserFullName(ticket.issued_by)"
          />
          <ItemRow title="Ticket priority" :value="ticket.priority" />
          <ItemRow title="Description" :value="ticket.description" />
        </ItemsList>

        <ItemsList title="Device details">
          <ItemRow title="Serial" :value="ticket.device?.serial_number" />
          <ItemRow
            title="Category"
            :value="ticket.device?.subcategory?.category?.name"
          />
          <ItemRow
            title="Subcategory"
            :value="ticket.device?.subcategory?.name"
          />
          <ItemRow
            title="Vendor"
            :value="ticket.device?.vendor?.company_name"
          />
          <ItemRow title="Model" :value="ticket.device?.model" />
        </ItemsList>
      </div>
      <div class="modal__content___right">
        <h5 class="modal__content__sub__title">Events</h5>

        <TicketEvent :key="key" :event="event" v-for="(event, key) in events" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import ItemsList from "@/components/resusables/list/ItemsList.vue";
import ItemRow from "@/components/resusables/list/ItemRow.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { helpers } from "@/composables/helpers";
import TicketEvent from "@/components/tickets/TicketEvent.vue";

export default defineComponent({
  name: "ViewRepairTicket",
  components: { TicketEvent, ItemRow, ItemsList, BaseIcon },

  setup(props, context) {
    const route = useRoute();

    const { formatDate, addStatusClass, split, getUserFullName } = helpers();

    onMounted(async () => {
      const ticketId = route.params.id;
      await store.dispatch("tickets/getRepairTicket", ticketId);
      await store.dispatch("tickets/getRepairTicketEvents", ticketId);
    });

    const computeStatus = (status) => {
      return split(status);
    };

    const ticket = computed(() => {
      return store.getters["tickets/ticket"];
    });

    const events = computed(() => {
      return store.getters["tickets/events"];
    });

    const close = () => {
      context.emit("close");
    };

    return {
      close,
      ticket,
      events,
      computeStatus,
      getUserFullName,
      addStatusClass,
      formatDate,
    };
  },
});
</script>

<style></style>
