<template>
  <div class="pagination">
    <template v-if="pagination && totalElements > 0">
      <div class="pagination__panel pagination__left__panel">
        <h5 class="pagination__title">{{ name }} per page:</h5>
        <select
          @change="updateLimit($event.target.value)"
          class="pagination__dropdown pagination__limit"
        >
          <option
            :selected="lim === currentPagination.limit"
            :key="key"
            :value="lim"
            v-for="(lim, key) of limits"
          >
            {{ lim }}
          </option>
        </select>
        <h5 class="pagination__text pagination__current__limit">
          {{ currentPagination.offset + 1 }} - {{ toPage }} of
          {{ currentPagination.total_elements }} total
        </h5>
      </div>
      <div class="pagination__panel pagination__right__panel">
        <select
          class="pagination__dropdown pagination__pages"
          @change="updatePage($event.target.value)"
        >
          <option
            :selected="page === currentPage"
            :key="key"
            v-for="(page, key) of totalNumPage"
            :value="page"
          >
            {{ page }}
          </option>
        </select>
        <h5 class="pagination__text">of {{ totalNumPage }}</h5>
        <div class="pagination__navigations">
          <button
            @click="updatePage(currentPage - 1)"
            :disabled="currentPage === 1"
            class="pagination__navigation"
          >
            <BaseIcon
              class="pagination__navigation__svg"
              icon="pagination/left-navigation"
            />
          </button>
          <button
            @click="updatePage(currentPage + 1)"
            :disabled="currentPage === totalNumPage"
            class="pagination__navigation"
          >
            <BaseIcon
              class="pagination__navigation__svg"
              icon="pagination/right-navigation"
            />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseIcon from "../../svg/BaseIcon.vue";
import { computed, defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "PaginationComponent",
  components: { BaseIcon },
  emits: ["paginate"],
  props: {
    pagination: {
      required: true,
      type: [Object, null],
    },
    name: {
      required: true,
      type: String,
    },
    totalElements: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit, expose }) {
    let currentPagination = computed(() => {
      return props.pagination;
    });
    // let currentPagination = ref({ ...props.pagination });
    const limits = [25, 50, 100];

    onMounted(() => {
      resetPagination();
    });

    const totalNumPage = computed(() => {
      if (!currentPagination.value) return null;

      const total = parseInt(currentPagination.value.total_elements);
      const limit = parseInt(currentPagination.value.limit);

      return Math.ceil(total / limit);
    });

    const currentPage = computed(() => {
      if (!currentPagination.value) return null;

      const total = parseInt(currentPagination.value.total_elements);
      const offset = parseInt(currentPagination.value.offset);
      const limit = parseInt(currentPagination.value.limit);

      return offset >= total ? -1 : offset / limit + 1;
    });

    const toPage = computed(() => {
      if (!currentPagination.value) return null;

      const limit = parseInt(currentPagination.value.limit);
      const offset = parseInt(currentPagination.value.offset);
      const total = parseInt(currentPagination.value.total_elements);

      let sum = offset + limit;
      if (sum > total) {
        return total;
      }
      return sum;
    });

    const resetPagination = () => {
      if (!currentPagination.value) return;

      currentPagination.value.offset = 0;
      currentPagination.value.limit = 25;
    };

    const updatePage = (page) => {
      if (!currentPagination.value) return;

      const limit = parseInt(currentPagination.value.limit);
      currentPagination.value.offset = (parseInt(page) - 1) * limit;
      emit("paginate", { pagination: currentPagination.value });
    };

    const updateLimit = (limit) => {
      if (!currentPagination.value) return;

      currentPagination.value.limit = limit;
      currentPagination.value.offset = 0;
      emit("paginate", { pagination: currentPagination.value });
    };

    expose({ resetPagination, currentPagination });

    return {
      currentPage,
      totalNumPage,
      updatePage,
      updateLimit,
      toPage,
      currentPagination,
      limits,
    };
  },
});
</script>

<style scoped></style>
