<template>
  <div class="location__navigations__modal coverage">
    <div class="coverage__tab__header modal__tabs">
      <button
        v-for="tab of tabNames"
        @click="setTab(tab)"
        :key="tab"
        :class="{ active: currentTab === tab }"
      >
        <span>{{ tab }}</span>
      </button>
    </div>
    <div class="coverage__tabs__values">
      <div v-if="currentTab === 'mode'" class="coverage__tabs__values__items">
        <div
          v-for="item of data['mode']"
          :key="item.key"
          class="coverage__tabs__values__item"
        >
          <h4>{{ item.name }}</h4>
          <label class="switch">
            <input
              type="checkbox"
              @change="handleCoverageChange(item.key, $event)"
              :checked="coverages.includes(item.key)"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div v-if="currentTab === 'status'" class="coverage__tabs__values__items">
        <div
          v-for="item of data['status']"
          :key="item.key"
          class="coverage__tabs__values__item"
        >
          <h4>{{ item.name }}</h4>
          <label class="switch">
            <input
              type="checkbox"
              @change="handleCoverageChange(item.key, $event)"
              :checked="coverages.includes(item.key)"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import store from "../../store";

export default defineComponent({
  name: "CoverageNavigation",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },

  setup(props) {
    let currentTab = ref("mode");

    const tabNames = Object.keys(props.data);

    const setTab = (tab: string) => {
      currentTab.value = tab;
    };

    const coverages = computed(() => {
      return store.getters["accessPoints/coverages"];
    });

    const handleCoverageChange = (key: string, event) => {
      const checked = event.target.checked;
      let values = coverages.value;
      if (checked) {
        values.push(key);
      } else {
        values = values.filter((item: string) => item !== key);
      }
      store.commit("accessPoints/SET_COVERAGES", values);
    };

    return {
      tabNames,
      currentTab,
      setTab,
      coverages,
      handleCoverageChange,
    };
  },
});
</script>
