export interface DevicesState {
  devices: object;
  departments: object[];
  hospitals: object[];
  categories: object[];
  subCategories: object[];
  vendors: object[];
  device: object;
  deviceEvents: object;
  reconciliationEvent: object | null;
  deviceBiomedEvents: object[];
  deviceFinancialEvents: object[];
  deviceLocationEvents: object[];
  deviceUtilizationEvents: object[];
  deviceReconciliationCounts: object[];
  deviceReconciliationStatusesCounts: object;
  deviceStatusesCounts: object;
  deviceFiles: object;
  devicesProximity: object[];
  utilGroupBy: string;
}

export enum DeviceStatusEnums {
  AVAILABLE = "available",
  DIRTY = "dirty",
  IN_REPAIR = "in_repair",
  IN_USE = "in_use",
  UNAVAILABLE = "unavailable",
}

export const DEVICE_STATUSES = [
  "available",
  "dirty",
  "in_repair",
  "in_use",
  "unavailable",
];
