<template>
  <DoughnutChart
    :chartData="chartDataList"
    :is-filterable="true"
    :filter-exist="activeStatus !== ''"
    :active-label="[activeStatus]"
    @filter="updateData"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import DoughnutChart from "@/components/resusables/charts/DoughnutChart.vue";
import store from "@/store";

export default defineComponent({
  name: "DevicesStatusesChart",
  components: {
    DoughnutChart,
  },
  setup() {
    const deviceStatuses = computed(() => {
      return store.getters["devices/deviceStatusesCounts"];
    });

    const filters = computed(() => {
      return store.getters["filters/filters"];
    });

    const activeStatus = computed(() => {
      return filters.value.deviceStatuses;
    });

    const updateChartList = () => {
      return {
        datasets: [
          {
            data: [
              deviceStatuses.value["available"],
              deviceStatuses.value["unavailable"],
              deviceStatuses.value["in_use"] + deviceStatuses.value["dirty"],
              deviceStatuses.value["in_repair"],
            ],
            backgroundColor: [
              "#016667",
              "#DAB75D",
              "#B5CBCB",
              "#DAE5E6",
              "#DAE5E6",
            ],
          },
        ],
        labels: ["available", "unavailable", "in_use", "in_repair"],
      };
    };

    let chartDataList = ref(updateChartList());

    watch(deviceStatuses, () => {
      chartDataList.value = updateChartList();
    });

    const updateData = (status: string | null) => {
      if (status === filters.value.deviceStatuses) {
        return;
      }
      if (!status) {
        filters.value.deviceStatuses = "";
      } else {
        filters.value.deviceStatuses = status;
      }
      store.commit("filters/SET_SELECTED_FILTER", filters.value);
      store.dispatch("devices/getDevices");
    };

    return {
      activeStatus,
      chartDataList,
      deviceStatuses,
      updateData,
    };
  },
});
</script>
