<template>
  <div class="cost__card__item__container">
    <h3 class="cost__card__item__cost">{{ price }}</h3>
    <p class="cost__card__item__cost__title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "CostCardListItem",

  props: {
    price: {
      type: [Number, String],
      required: false,
      default: "-",
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
