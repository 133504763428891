import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "devices__list" }
const _hoisted_2 = { class: "devices__list__container" }
const _hoisted_3 = { class: "devices__list__container__top" }
const _hoisted_4 = { class: "input__group__list__search" }
const _hoisted_5 = {
  key: 0,
  class: "devices__title"
}
const _hoisted_6 = { class: "devices__title__name" }
const _hoisted_7 = { class: "device__list__tabs" }
const _hoisted_8 = { class: "modal__tabs badges" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "device__list__items__container" }
const _hoisted_11 = { class: "device__list__tabs__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_MapDevicesListItem = _resolveComponent("MapDevicesListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BaseIcon, {
            class: "input__group__list__search__svg",
            icon: "form/search"
          }),
          _createVNode(_component_BaseInput, {
            placeholder: "Search device name, serial no...",
            type: "text",
            class: "input__group__list__search__input",
            modelValue: _ctx.searchStr,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchStr) = $event))
          }, null, 8, ["modelValue"])
        ]),
        (_ctx.filters && _ctx.filters.category && _ctx.filters.category.id)
          ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
              (_ctx.hasSelectedItem)
                ? (_openBlock(), _createBlock(_component_BaseIcon, {
                    key: 0,
                    onClick: _ctx.resetSelectedItem,
                    icon: "arrow2/left"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.filters.category.name), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, key) => {
            return (_openBlock(), _createElementBlock("button", {
              onClick: ($event: any) => (_ctx.updateDevices(tab)),
              key: key,
              class: _normalizeClass({ active: key === _ctx.activeIndex })
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.deviceStatusesRep[tab]), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.deviceStatuses[tab]), 1)
            ], 10, _hoisted_9))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.hasSelectedItem)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.selectedItem.devices, (device, key) => {
                  return (_openBlock(), _createBlock(_component_MapDevicesListItem, {
                    onUpdateProximity: _ctx.updateProximity,
                    class: "selected",
                    key: key,
                    index: key,
                    device: device
                  }, null, 8, ["onUpdateProximity", "index", "device"]))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.devices.data, (device, key) => {
                  return (_openBlock(), _createBlock(_component_MapDevicesListItem, {
                    onUpdateProximity: _ctx.updateProximity,
                    key: key,
                    index: key,
                    device: device
                  }, null, 8, ["onUpdateProximity", "index", "device"]))
                }), 128))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "scrollObserver",
            style: {"height":"10px"}
          }, null, -1))
        ])
      ])
    ])
  ]))
}