import { helpers } from "@/composables/helpers";

export function devices() {
  const { formatMoney } = helpers();

  const orderTypes = [
    "maintenance",
    "preventative_maintenance",
    "purchase",
    "rental",
  ];

  const orderTypeName = {
    purchase: "Purchase",
    maintenance: "Service",
    rental: "Rental",
    shipping: "Shipping",
    preventative_maintenance: "Maintenance",
  };

  const getOrderIds = (row) => {
    const orders = row.orders;

    const ids: any = [];
    orders.forEach((order: any) => {
      if (orderTypes.includes(order.type)) {
        ids.push(order.document_id);

        if (order.type === "purchase") {
          const shipping = orders.filter((order) => order.type === "shipping");
          if (shipping.length > 0) {
            ids.push(order.document_id);
          }
        }
      }
    });

    if (ids.length === 0) {
      return "-";
    }

    return ids.toString();
  };

  const getOrderTypeAmount = (row, type) => {
    const orders = row.orders;
    const order = orders.filter((order) => order.type === type);

    if (order.length === 0) {
      return "-";
    }

    return `$${formatMoney(order[0].unit_amount)}`;
  };

  const getTotalOrdersAmount = (row) => {
    const orders = row.orders;
    let sum = 0;
    orders.forEach((order) => {
      if (orderTypes.includes(order.type)) {
        sum += parseFloat(order.unit_amount);

        if (order.type === "purchase") {
          const shipping = orders.filter((order) => order.type === "shipping");
          if (shipping.length > 0) {
            sum += parseFloat(shipping[0].unit_amount);
          }
        }
      }
    });
    return sum ? `$${formatMoney(sum)}` : "-";
  };

  return {
    getOrderIds,
    getOrderTypeAmount,
    getTotalOrdersAmount,
    orderTypes,
    orderTypeName,
  };
}
