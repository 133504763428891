<template>
  <h3 class="events__list__item__title">Utilization</h3>
  <div class="events__item__details">
    <div class="events__item__details__item">
      <h5>User id:</h5>
      <h6>{{ event.user_id || "N/A" }}</h6>
    </div>
    <div v-if="isOpen" class="events__item__details__item">
      <h5>Patient MRN:</h5>
      <h6>{{ event.patient_hash || "N/A" }}</h6>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { helpers } from "@/composables/helpers";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "UtilizationEvents",

  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const { formatMoney } = helpers();

    const { formatStatus, formatDate, addStatusClass } = viewDevice();

    return { formatMoney, formatStatus, formatDate, addStatusClass };
  },
});
</script>

<style scoped></style>
