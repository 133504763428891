import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ContractsState } from "@/store/modules/contracts/types";
import AxiosClient from "@/services/api";
const BASE_URL = "/v1";

export const actions: ActionTree<ContractsState, RootState> = {
  getContracts(
    { commit },
    { pagination = { limit: 25, offset: 0 }, sort = null } = {}
  ) {
    if (!pagination) pagination = { limit: 25, offset: 0 };
    let url = `/orders?limit=${pagination.limit}&offset=${pagination.offset}&`;

    if (sort && sort.order_by && sort.order_direction) {
      url += `order_by=${sort.order_by}&order_direction=${sort.order_direction}&`;
    }

    const filter = this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `device_subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `device_category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_CONTRACTS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getAllContracts({ commit }) {
    let url = `/orders?limit=1000&offset=0&`;

    const filter = this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `device_subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `device_category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_ALL_CONTRACTS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getContract({ commit }, contractId) {
    return AxiosClient.get(`${BASE_URL}/orders/${contractId}`)
      .then((res) => {
        commit("SET_CONTRACT", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getOrderDocumentPDF({ commit }, contractId) {
    return AxiosClient.get(`${BASE_URL}/orders/${contractId}/pdf`, {
      responseType: "blob",
    })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  getOrderCosts({ commit }) {
    let url = `/orders/costs?`;

    const filter = this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `device_subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `device_category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }
    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        commit("SET_ORDER_COSTS", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
