<template>
  <BaseLayout @close="closeRepairTicket">
    <Filters @apply_filters="changeData" :allowedFilters="allowedFilters" />

    <div class="repairs__charts">
      <div class="dashboard__card repairs__chart__item">
        <h3 class="repairs__chart__item__title">Ticket Status</h3>
        <BiomedTimelineLineChart />
      </div>
      <div class="dashboard__card repairs__chart__item">
        <h3 class="repairs__chart__item__title">Mean Time</h3>
        <BiomedMeantimesLineChart />
      </div>
    </div>

    <TableComponent
      ref="tableRef"
      v-if="tickets.data"
      name="Tickets"
      @open="openRepairTicket"
      :data="tickets"
      @updatePagination="updateTable"
      :fields="fields"
      :computeStatus="computeStatus"
    />

    <div class="view__bg">
      <router-view @close="closeRepairTicket" />
    </div>
  </BaseLayout>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";

import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/table/Table.vue";
import Filters from "@/components/resusables/filters/Filters.vue";
import router from "@/router";
import store from "@/store";
import BiomedTimelineLineChart from "@/components/charts/biomed/BiomedTimelineLineChart.vue";
import BiomedMeantimesLineChart from "@/components/charts/biomed/BiomedMeantimesLineChart.vue";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import { helpers } from "@/composables/helpers";

export default defineComponent({
  name: "RepairTicketsPage",
  emits: ["open", "update-pagination"],

  components: {
    BiomedMeantimesLineChart,
    BiomedTimelineLineChart,
    Filters,
    TableComponent,
    BaseLayout,
  },

  methods: {
    changeData(filter) {
      this.$refs.tableRef?.resetSorting();

      store.dispatch("tickets/getRepairTickets");
      store.dispatch("analytics/getBiomedTimelineData", { filter });
      store.dispatch("analytics/getBiomedMeantimesData", { filter });
    },
  },

  setup() {
    const isOpen = ref(false);

    const fields = [
      { key: "number", label: "Ticket", order_key: "number", sortable: true },
      {
        key: "issued_by",
        label: "User",
        type: "user",
        sortable: false,
      },
      {
        key: ["device", "name"],
        type: "object",
        label: "Device",
        sortable: false,
      },
      {
        key: ["device", "subcategory", "category", "name"],
        type: "object",
        label: "Category",
        sortable: false,
      },
      {
        key: ["device", "subcategory", "name"],
        type: "object",
        label: "Subcategory",
        sortable: false,
      },
      {
        key: ["device", "vendor", "company_name"],
        type: "object",
        label: "Vendor",
        sortable: false,
      },
      { key: "created_date", type: "date", label: "Issued", sortable: false },
      {
        key: "modified_date",
        type: "ticket_closed_date",
        label: "Closed",
        sortable: false,
      },
      { key: "repair_cost", label: "Repair Cost", sortable: false },
      {
        key: "status",
        type: "status",
        label: "Status",
        order_key: "status",
        sortable: true,
      },
    ];

    onMounted(() => {
      getData();
    });

    const computeStatus = (row) => {
      return split(row.status);
    };

    const allowedFilters = [FilterScopeEnums.SUBCATEGORY];
    const { split } = helpers();

    const tickets = computed(() => {
      return store.getters["tickets/tickets"];
    });

    const openRepairTicket = (ticket) => {
      store.commit("tickets/SET_REPAIR_TICKET", ticket);
      router.push({ name: "ViewRepairTicket", params: { id: ticket.id } });
    };

    const getData = () => {
      store.dispatch("tickets/getRepairTickets");
    };

    const closeRepairTicket = () => {
      isOpen.value = false;
      router.push({ name: "RepairTickets" });
    };

    const updateTable = (res) => {
      store.dispatch("tickets/getRepairTickets", res);
    };

    return {
      openRepairTicket,
      isOpen,
      computeStatus,
      fields,
      closeRepairTicket,
      tickets,
      updateTable,
      allowedFilters,
    };
  },
});
</script>
