<template>
  <div class="list__chart__list">
    <h3 v-if="title" class="list_chart__title">{{ title }}</h3>
    <div class="list__chart__labels">
      <div
        :key="key"
        v-for="(dataset, key) in datasets"
        class="list__chart__labels__item"
      >
        <span
          class="list__labels__item__color"
          :style="{
            backgroundColor: dataset.color,
            borderColor: dataset.borderColor,
          }"
        ></span>
        <span class="list__labels__item__label">{{ dataset.label }}</span>

        <span class="list__labels__item__value">{{ dataset.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ListChart",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    datasets: {
      type: Object,
      required: true,
    },
  },
});
</script>
