<template>
  <div class="filters__input__group__list__body__item">
    <button
      @click="selectFilter(index)"
      class="filters__input__group__list__body__item__title"
    >
      <span>{{ name }}</span>
      <BaseIcon :icon="`expand-${activeFilter === index ? 'less' : 'more'}`" />
    </button>
    <div
      class="filters__input__group__list__body__item__list"
      :class="{ active: activeFilter === index && show }"
    >
      <div v-if="!isGrouped">
        <div
          v-for="(scope, key) in scopeList"
          :key="key"
          class="filters__input__group__item"
        >
          <template v-if="objectArray">
            <BaseCheckbox
              v-if="multiple"
              :name="filter"
              @update:checked="check(filter, scope, $event)"
              :checked="selecting[filter].some((fil) => fil.id === scope.id)"
            />
            <BaseCheckbox
              v-else
              :name="filter"
              @update:checked="check(filter, scope, $event)"
              :checked="selecting[filter]['id'] === scope.id"
            />
            <BaseLabel :title="scope[scopeObjName]" />
          </template>
          <template v-else>
            <BaseCheckbox
              v-if="multiple"
              :name="filter"
              @update:checked="check(filter, scope, $event)"
              :checked="selecting[filter].some((fil) => fil === scope)"
            />
            <BaseRadio
              v-else
              :name="filter"
              @update:checked="check(filter, scope, $event)"
              :checked="selecting[filter] === scope"
            />
            <BaseLabel
              :title="
                deviceStatusesRep[scope]
                  ? deviceStatusesRep[scope]
                  : split(scope)
              "
            />
          </template>
        </div>
      </div>

      <FilterCategoryGroup
        :selecting="selecting"
        :scopeList="scopeList"
        :filter="filter"
        :multiple="multiple"
        :objectArray="objectArray"
        @check="check"
        v-else
      />
      <!--              <a href="" class="filters__input__group__link">-->
      <!--                See all-->
      <!--                <BaseIcon icon="arrow-forward" />-->
      <!--              </a>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseRadio from "@/components/form/BaseRadio.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import { helpers } from "@/composables/helpers";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";
import FilterCategoryGroup from "@/components/resusables/filters/FilterCategoryGroup.vue";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "FilterInputGroup",
  components: {
    FilterCategoryGroup,
    BaseCheckbox,
    BaseLabel,
    BaseRadio,
    BaseIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    activeFilter: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
    isGrouped: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    scopeList: {
      type: [Array, Object],
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    objectArray: {
      type: Boolean,
      default: true,
      required: false,
    },
    scopeObjName: {
      type: String,
      default: "name",
      required: false,
    },
    selecting: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { split } = helpers();

    const { deviceStatusesRep } = viewDevice();

    const selectFilter = (index) => {
      context.emit("select_filter", index);
    };

    const check = (field: string, value: any, checked: boolean) => {
      context.emit(
        "check",
        field,
        value,
        props.objectArray,
        props.multiple,
        checked
      );
    };

    return { deviceStatusesRep, selectFilter, check, split };
  },
});
</script>

<style scoped></style>
