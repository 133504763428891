import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "device-view dashboard__card" }
const _hoisted_2 = { class: "device-view__navigation" }
const _hoisted_3 = { class: "device-view__body" }
const _hoisted_4 = { class: "device-view__body__description" }
const _hoisted_5 = { class: "device-view__body__description__titles" }
const _hoisted_6 = { class: "device-view__navigation__tabs" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Filters = _resolveComponent("Filters")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DeviceDetails = _resolveComponent("DeviceDetails")!
  const _component_DeviceUtilizationEvents = _resolveComponent("DeviceUtilizationEvents")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_Filters, {
        onApply_filters: _ctx.changeData,
        allowedFilters: _ctx.allowedFilters,
        "show-date-picker": false,
        onDate_updated: _ctx.dateUpdated
      }, null, 8, ["onApply_filters", "allowedFilters", "onDate_updated"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            class: "device-view__navigation__back",
            to: "/devices"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseIcon, { icon: "device/back" }),
              _cache[0] || (_cache[0] = _createElementVNode("span", null, "All Devices", -1))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("h6", null, "Description", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.device?.subcategory?.category?.name), 1),
                _createElementVNode("h4", null, _toDisplayString(_ctx.device?.subcategory?.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.device?.subcategory?.category?.department?.name) + " Department ", 1),
                _createElementVNode("h5", null, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", null, "Serial No:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.device.serial_number), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceViewTabs, (tab, index) => {
              return (_openBlock(), _createElementBlock("button", {
                onClick: ($event: any) => (_ctx.changeTabIndex(index)),
                key: index,
                class: _normalizeClass({ active: _ctx.deviceTabIndex === index })
              }, _toDisplayString(tab), 11, _hoisted_7))
            }), 128))
          ]),
          (_ctx.deviceTabIndex === 0)
            ? (_openBlock(), _createBlock(_component_DeviceDetails, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.deviceTabIndex === 1)
            ? (_openBlock(), _createBlock(_component_DeviceUtilizationEvents, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}