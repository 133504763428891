<template>
  <div class="rentals__chart">
    <DoughnutChart
      total-label="Device Requests"
      title="Device Requests"
      :chartData="chartDataList"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import DoughnutChart from "@/components/resusables/charts/DoughnutChart.vue";

export default defineComponent({
  name: "RentalsChart",
  components: {
    DoughnutChart,
  },
  setup() {
    const updateChartList = () => {
      return {
        datasets: [
          {
            data: [52, 34, 23, 10, 7, 5],
            backgroundColor: [
              "#016667",
              "#DAB75D",
              "#B5CBCB",
              "#DAE5E6",
              "#DAE5E6",
            ],
          },
        ],
        labels: [
          "Infusion Pumps",
          "PCA Pumps",
          "CVVHDs",
          "Impellas",
          "Bladder Scanners",
          "Other",
        ],
      };
    };

    let chartDataList = ref(updateChartList());

    return {
      chartDataList,
    };
  },
});
</script>
