<template>
  <DeviceBaseTypeEvents
    :key="key"
    v-for="(event, key) in events"
    @click="setEvent(key, type, event)"
    :class="{ active: currentType === type && currentIndex === key }"
    :event="event"
    :type="type"
  >
    <template #default="{ isOpen }">
      <BiomedEvents
        v-if="type === 'biomed'"
        :isOpen="isOpen"
        :type="type"
        :event="event"
      />
      <UtilizationEvents
        v-else-if="type === 'utilization'"
        :type="type"
        :isOpen="isOpen"
        :event="event"
      />
      <LocationEvents
        v-else-if="type === 'location'"
        :type="type"
        :isOpen="isOpen"
        :event="event"
      />
      <FinancialEvents
        v-else-if="type === 'financial'"
        :type="type"
        :isOpen="isOpen"
        :event="event"
      />
    </template>
  </DeviceBaseTypeEvents>
</template>

<script>
import { defineComponent, ref } from "vue";
import { helpers } from "@/composables/helpers";
import { viewDevice } from "@/composables/devices/view-device";
import DeviceBaseTypeEvents from "@/components/devices/DeviceBaseTypeEvents";
import LocationEvents from "@/components/devices/events/LocationEvents";
import BiomedEvents from "@/components/devices/events/BiomedEvents";
import UtilizationEvents from "@/components/devices/events/UtlizationEvents";
import FinancialEvents from "@/components/devices/events/FinancialEvents";

export default defineComponent({
  name: "DeviceTypeEvents",

  props: {
    events: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    currentType: {
      type: [String, null],
      required: true,
      default: null,
    },
  },

  emits: ["set"],

  components: {
    FinancialEvents,
    UtilizationEvents,
    BiomedEvents,
    LocationEvents,
    DeviceBaseTypeEvents,
  },

  setup(_, { emit }) {
    const { formatMoney } = helpers();
    const currentIndex = ref(-1);

    const { formatStatus, formatDate, addStatusClass } = viewDevice();

    const setEvent = (index, type, event) => {
      currentIndex.value = index;
      emit("set", index, type, event);
    };

    return {
      formatMoney,
      formatStatus,
      formatDate,
      addStatusClass,
      setEvent,
      currentIndex,
    };
  },
});
</script>

<style scoped></style>
