import { createApp, inject } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { init as SentryInit, vueRouterInstrumentation } from "@sentry/vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/tailwind.css";
import VueLogger from "vuejs3-logger";
import getEnv from "@/utils/env.js";
import "nprogress/nprogress.css";
loadFonts();

const app = createApp(App);

SentryInit({
  app,
  environment: getEnv("VUE_APP_SQUID_ENVIRONMENT"),
  dsn: getEnv("VUE_APP_SQUID_SENTRY_DSN"),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", getEnv("VUE_APP_SQUID_BASE_URL"), /^\//],
    }),
    new Sentry.Replay(),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: 1.0,
  attachProps: true,
  attachStacktrace: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.config.globalProperties.$filters = {
  currencyUSD(value) {
    return "$" + value;
  },
};

const isProduction = getEnv("VUE_APP_SQUID_ENVIRONMENT") === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

declare global {
  interface Window {
    heap: any;
  }
}

window.heap = window.heap || [];
window.heap.load = function (appId: string, config: any = {}) {
  window.heap.appid = appId;
  window.heap.config = config;
  const forceSSL = config.forceSSL || document.location.protocol === "https:";
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = `${
    forceSSL ? "https:" : "http:"
  }//cdn.heapanalytics.com/js/heap-${appId}.js`;
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript?.parentNode?.insertBefore(script, firstScript);

  const heapFunctions = [
    "addEventProperties",
    "addUserProperties",
    "clearEventProperties",
    "identify",
    "removeEventProperty",
    "setEventProperties",
    "track",
    "unsetEventProperty",
  ];

  for (const heapFunction of heapFunctions) {
    window.heap[heapFunction] = (...args: any[]) => {
      window.heap.push([heapFunction, ...args]);
    };
  }
};
window.heap.load(getEnv("VUE_APP_SQUID_HEAP_ANALYTICS_ID"));
const user = store.getters["auth/authUser"];
if (user) {
  window.heap.identify(user.id);
  window.heap.addUserProperties({
    Email: user.email,
    Name: `${user.first_name} ${user.last_name}`,
  });
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(router).use(store).use(VueLogger, options).mount("#app");
