<template>
  <div>
    <ItemsList title="Contract Status">
      <ItemRow title="Status">
        <div
          :class="addStatusClass(computeStatus)"
          class="modal__contract__status"
        >
          {{ computeStatus }}
        </div>
      </ItemRow>
      <ItemRow title="PO #" :value="contract.document_id" />
      <ItemRow title="Invoice #" :value="contract.invoice?.invoice_number" />
      <ItemRow title="Contract Type" :value="orderTypeName[contract.type]" />
      <ItemRow title="Start Date" :value="formatDate(contract.start_date)" />
    </ItemsList>

    <ItemsList title="Description" class="mt-5">
      <div class="modal__table__row">
        <div class="modal__table__row__body">
          <h5 class="modal__table__row__value">
            {{
              contract.description ? contract.description.toLowerCase() : "-"
            }}
          </h5>
        </div>
      </div>
      <ItemRow title="Vendor" :value="contract?.vendor?.company_name" />
      <ItemRow
        title="Category"
        :value="contract?.subcategory?.category?.name"
      />
      <ItemRow title="Subcategory" :value="contract?.subcategory?.name" />
      <ItemRow title="Cost" :value="getContractCost(contract)" />
      <ItemRow title="Savings" :value="getContractMaintenance(contract)" />
      <ItemRow title="Number of Devices" :value="contract.quantity" />
      <ItemRow
        title="Unverified Devices"
        :value="contract.unverified_count ? contract.unverified_count : 0"
      />
      <ItemRow
        v-if="contract.warranty_end_date"
        title="Warranty End Date"
        :value="formatDate(contract.warranty_end_date)"
      />
      <ItemRow
        v-if="contract.warranty_type"
        title="Warranty Type"
        :value="contract.warranty_type"
      />
      <ItemRow title="Refundable" :value="contract.refundable ? 'Yes' : 'No'" />
      <ItemRow
        v-if="contract.refundable"
        title="Refundable Type"
        :value="contract.refundable_type"
      />
      <ItemRow v-if="contract.archive_path" title="PDF Attachment">
        <div class="modal__table__value__attachment">
          <button @click="downloadDoc">View</button>
          <span>or</span>
          <button @click="downloadDoc">download</button>
        </div>
      </ItemRow>
    </ItemsList>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import moment from "moment";
import { contracts } from "@/composables/contracts/contracts";
import { helpers } from "@/composables/helpers";
import ItemsList from "@/components/resusables/list/ItemsList.vue";
import ItemRow from "@/components/resusables/list/ItemRow.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ContractDetails",
  components: { ItemRow, ItemsList },

  setup() {
    const { orderTypeName, getContractCost, getContractMaintenance } =
      contracts();
    const { formatDate } = helpers();
    const showUnVerified = ref(true);
    const route = useRoute();
    const contractId = route.params.id;

    const computeStatus = computed(() => {
      if (contract.value.cycle === null) return "-";
      if (
        moment(contract.value.end_date).isAfter(moment().format("YYYY-MM-DD"))
      ) {
        return "Active";
      }
      return "Ended";
    });

    const contract = computed(() => {
      return store.getters["contracts/contract"];
    });

    const addStatusClass = (value) => {
      if (value === "Issued") {
        return "table__status__danger";
      } else if (["Collected", "Ended"].includes(value)) {
        return "table__status__warning";
      } else if (["Repaired", "Active"].includes(value)) {
        return "table__status__success";
      }
      return "";
    };

    const downloadDoc = async () => {
      const res = await store.dispatch(
        "contracts/getOrderDocumentPDF",
        contractId
      );
      const pdfBlob = new Blob([res], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = `${contract.value.archive_path}.pdf`;
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
    };

    return {
      close,
      computeStatus,
      contract,
      formatDate,
      addStatusClass,
      orderTypeName,
      getContractCost,
      downloadDoc,
      getContractMaintenance,
      showUnVerified,
    };
  },
});
</script>

<style></style>
