<template>
  <div class="form-group">
    <BaseLabel title="Building" />
    <select
      @change="onBuildingChange"
      v-model="location.building_id"
      class="base__select"
      required
    >
      <option value="">Select Building</option>
      <option
        :value="building.id"
        v-for="(building, key) in buildings"
        :key="key"
      >
        {{ building.building_name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <BaseLabel title="Floor" />
    <select
      @change="onFloorChange"
      v-model="location.floor_id"
      class="base__select"
      required
    >
      <option value="">Select Floor</option>
      <option :value="floor.id" v-for="(floor, key) in floors" :key="key">
        {{ floor.floor_name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <BaseLabel title="Unit" />
    <select v-model="location.unit_name" class="base__select">
      <option value="">Select Unit</option>
      <option
        :key="key"
        v-for="(unit, key) in roomsUnits"
        :value="unit.acronym"
      >
        {{ unit.name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <BaseLabel title="Room" />
    <select
      @change="onRoomChange"
      v-model="location.room_id"
      class="base__select"
    >
      <option value="">Select Room</option>
      <option :value="room.id" v-for="(room, key) in rooms" :key="key">
        {{ room.room_name }}
      </option>
    </select>
  </div>
  <div class="form-group">
    <BaseLabel title="Bed" />
    <select v-model="location.bed_id" class="base__select">
      <option value="">Select Bed</option>
      <option :value="bed.id" v-for="(bed, key) in beds" :key="key">
        {{ bed.bed_number }}
      </option>
    </select>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";

export default defineComponent({
  name: "UpdateDeviceLocationForm",
  components: { BaseLabel },
  props: {
    modelValue: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const disabled = ref(false);

    const buildings = computed(() => {
      return store.getters["locations/buildings"];
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const roomsUnits = computed(() => {
      return store.getters["locations/roomsUnits"];
    });

    const result = ref({
      location_id: "",
      unit_name: "",
    });

    const floors = ref([]);
    const rooms = ref([]);
    const beds = ref([]);

    const location = ref({
      unit_name: "",
      building_id: "",
      floor_id: "",
      room_id: "",
      bed_id: "",
    });

    const resetLocation = async () => {
      location.value = {
        unit_name: "",
        building_id: "",
        floor_id: "",
        room_id: "",
        bed_id: "",
      };
    };

    const getLocation = (locationType) => {
      return currentDevice.value &&
        currentDevice.value.location &&
        currentDevice.value.location[locationType]
        ? currentDevice.value.location[locationType]
        : "";
    };

    watch(
      () => ({ ...location.value }),
      (newVal, oldVal) => {
        if (oldVal.building_id && oldVal.building_id !== newVal.building_id) {
          location.value.floor_id = "";
          location.value.room_id = "";
          location.value.bed_id = "";
        }
        if (oldVal.floor_id && oldVal.floor_id !== newVal.floor_id) {
          location.value.room_id = "";
          location.value.bed_id = "";
        }
        if (oldVal.room_id && oldVal.room_id !== newVal.room_id) {
          location.value.bed_id = "";
          location.value.unit_name = "";
        }

        emit("update:modelValue", newVal);
      }
    );

    const setLocation = async () => {
      await resetLocation();
      location.value.unit_name = getLocation("unit_name");

      const buildingName = getLocation("building_name");
      if (!buildingName) return;
      const building = buildings.value.find(
        (build) => build.building_name === buildingName
      );
      if (!building) return;
      location.value.building_id = building.id;

      floors.value = await store.dispatch("locations/getFloors", building.id);
      const floorName = getLocation("floor_name");
      if (!floorName) return;
      const floor = floors.value.find((flor) => flor.floor_name === floorName);
      if (!floor) return;
      location.value.floor_id = floor.id;

      rooms.value = await store.dispatch("locations/getRooms", {
        floorId: floor.id,
        buildingId: building.id,
      });
      const roomName = getLocation("room_name");
      if (!roomName) return;

      const room = rooms.value.find((room) => room.room_name === roomName);
      if (!room) return;
      location.value.room_id = room.id;

      beds.value = await store.dispatch("locations/getBeds", {
        floorId: floor.id,
        buildingId: building.id,
        roomId: room.id,
      });
      const bedNumber = getLocation("bed_number");
      if (!bedNumber) return;

      const bed = beds.value.find((bed) => bed.bed_number == bedNumber);
      if (!bed) return;
      location.value.bed_id = bed.id;
    };

    watch(currentDevice, async () => {
      await setLocation();
    });

    const onBuildingChange = async () => {
      if (location.value.building_id) {
        floors.value = await store.dispatch(
          "locations/getFloors",
          location.value.building_id
        );
      }

      rooms.value = [];
      beds.value = [];
    };

    const onFloorChange = async () => {
      if (location.value.building_id && location.value.floor_id) {
        rooms.value = await store.dispatch("locations/getRooms", {
          floorId: location.value.floor_id,
          buildingId: location.value.building_id,
        });
      }
      beds.value = [];
    };

    const onRoomChange = async () => {
      if (
        location.value.building_id &&
        location.value.floor_id &&
        location.value.room_id
      ) {
        beds.value = await store.dispatch("locations/getBeds", {
          floorId: location.value.floor_id,
          buildingId: location.value.building_id,
          roomId: location.value.room_id,
        });
      }
    };

    onMounted(async () => {
      await setLocation();
    });

    return {
      disabled,
      location,
      result,
      buildings,
      floors,
      rooms,
      beds,
      onBuildingChange,
      onRoomChange,
      onFloorChange,
      roomsUnits,
    };
  },
});
</script>
