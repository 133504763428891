import { MutationTree } from "vuex";
import { DevicesState } from "@/store/modules/devices/types";

export const mutations: MutationTree<DevicesState> = {
  SET_DEVICES(state: DevicesState, data: object) {
    state.devices = data;
    return state.devices;
  },
  SET_DEVICE(state: DevicesState, data: object) {
    state.device = data;
    return state.device;
  },
  SET_DEVICE_RECONCILIATION_DEVICE(state: DevicesState, data: object | null) {
    state.reconciliationEvent = data;
    return state.reconciliationEvent;
  },
  SET_DEVICE_EVENTS(state: DevicesState, data: object) {
    state.deviceEvents = data;
    return state.deviceEvents;
  },
  SET_DEVICE_BIOMED_EVENTS(state: DevicesState, data: object[]) {
    state.deviceBiomedEvents = data;
    return state.deviceBiomedEvents;
  },
  SET_DEVICE_FINANCIAL_EVENTS(state: DevicesState, data: object[]) {
    state.deviceFinancialEvents = data;
    return state.deviceFinancialEvents;
  },
  SET_DEVICE_LOCATION_EVENTS(state: DevicesState, data: object[]) {
    state.deviceLocationEvents = data;
    return state.deviceLocationEvents;
  },
  SET_DEVICE_UTILIZATION_EVENTS(state: DevicesState, data: object[]) {
    state.deviceUtilizationEvents = data;
    return state.deviceUtilizationEvents;
  },
  SET_DEPARTMENTS(state: DevicesState, data: object[]) {
    state.departments = data;
    return state.departments;
  },
  SET_HOSPITALS(state: DevicesState, data: object[]) {
    state.hospitals = data;
    return state.hospitals;
  },
  SET_VENDORS(state: DevicesState, data: object[]) {
    state.vendors = data;
    return state.vendors;
  },
  SET_CATEGORIES(state: DevicesState, data: object[]) {
    state.categories = data;
    return state.categories;
  },
  SET_SUB_CATEGORIES(state: DevicesState, data: object[]) {
    state.subCategories = data;
    return state.subCategories;
  },
  SET_DEVICE_RECONCILIATION_COUNTS(state: DevicesState, data: object[]) {
    state.deviceReconciliationCounts = data;
    return state.deviceReconciliationCounts;
  },
  SET_DEVICE_RECONCILIATION_STATUSES_COUNTS(state: DevicesState, data: object) {
    state.deviceReconciliationStatusesCounts = data;
    return state.deviceReconciliationStatusesCounts;
  },
  SET_DEVICE_STATUSES_COUNTS(state: DevicesState, data: object) {
    state.deviceStatusesCounts = data;
    return state.deviceStatusesCounts;
  },
  SET_DEVICE_FILES(state: DevicesState, data: object) {
    state.deviceFiles = data;
    return state.deviceFiles;
  },
  SET_DEVICES_PROXIMITY(state: DevicesState, data: object[]) {
    state.devicesProximity = data;
    return state.devicesProximity;
  },
  SET_UTIL_GROUP_BY(state: DevicesState, data: string) {
    state.utilGroupBy = data;
    return state.utilGroupBy;
  },
};
