<template>
  <div class="devices__list">
    <div class="devices__list__container">
      <div class="devices__list__container__top">
        <div class="input__group__list__search">
          <BaseIcon
            class="input__group__list__search__svg"
            icon="form/search"
          />
          <BaseInput
            placeholder="Search device name, serial no..."
            type="text"
            class="input__group__list__search__input"
            v-model="searchStr"
          />
        </div>

        <button
          v-if="filters && filters.category && filters.category.id"
          class="devices__title"
        >
          <BaseIcon
            v-if="hasSelectedItem"
            @click="resetSelectedItem"
            icon="arrow2/left"
          />
          <span class="devices__title__name">{{ filters.category.name }}</span>
          <!--          <span class="devices__title__count"-->
          <!--            >({{ devices.total_elements }})</span-->
          <!--          >-->
        </button>
      </div>
      <div class="device__list__tabs">
        <div class="modal__tabs badges">
          <button
            v-for="(tab, key) in tabs"
            @click="updateDevices(tab)"
            :key="key"
            :class="{ active: key === activeIndex }"
          >
            <span>{{ deviceStatusesRep[tab] }}</span>
            <span>{{ deviceStatuses[tab] }}</span>
          </button>
        </div>
        <div class="device__list__items__container">
          <div class="device__list__tabs__items">
            <template v-if="hasSelectedItem">
              <MapDevicesListItem
                @updateProximity="updateProximity"
                class="selected"
                :key="key"
                :index="key"
                :device="device"
                v-for="(device, key) in selectedItem.devices"
              />
            </template>
            <template v-else>
              <MapDevicesListItem
                @updateProximity="updateProximity"
                :key="key"
                :index="key"
                :device="device"
                v-for="(device, key) in devices.data"
              />
            </template>
          </div>
          <div class="scrollObserver" style="height: 10px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import MapDevicesListItem from "@/components/find-devices/MapDevicesListItem.vue";
import store from "@/store";
import { find_devices } from "@/composables/maps/find_devices";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "MapDevicesListModal",
  components: { MapDevicesListItem, BaseInput, BaseIcon },

  setup(_, { emit }) {
    const { deviceStatusesRep } = viewDevice();
    const { hasSelectedItem } = find_devices();
    const searchStr = ref("");

    const tabs = ["available", "dirty", "in_repair", "in_use"];

    const filters = computed(() => {
      return store.getters["filters/filters"];
    });

    const devices = computed(() => {
      return store.getters["findDevices/devicesList"];
    });

    const devicesDumpList = computed(() => {
      return store.getters["findDevices/devicesDumpList"];
    });

    const deviceStatuses = computed(() => {
      return store.getters["devices/deviceStatusesCounts"];
    });

    const selectedItem = computed(() => {
      return store.getters["findDevices/selectedItem"];
    });

    const deviceStatusesCounts = computed(() => {
      return store.getters["devices/deviceStatusesCounts"];
    });

    onMounted(() => {
      handleScroll();
      store.commit("findDevices/CLOSE_ALL_MODALS");
      store.commit("dropdowns/TOGGLE_DEVICE_LOCATION_LIST_ITEM", -1);
      store.commit("findDevices/SET_CURRENT_DEVICE", null);
      resetSelectedItem();
    });

    const activeIndex = computed(() => {
      const index = tabs.findIndex(
        (tab) => tab === filters.value.deviceStatuses
      );
      return index >= 0 ? index : 0;
    });

    watch(searchStr, () => {
      if (searchStr.value) {
        const regex = new RegExp(searchStr.value, "i");
        const dataCopy = [...devicesDumpList.value.data];

        devices.value.data = dataCopy.filter(
          (device: any) =>
            regex.test(device.serial_number) ||
            regex.test(device.subcategory?.name)
        );
        store.commit(
          "findDevices/SET_DEVICES_LIST",
          Object.assign({}, devices.value)
        );
      } else {
        store.commit(
          "findDevices/SET_DEVICES_LIST",
          Object.assign({}, devicesDumpList.value)
        );
      }
    });

    const handleScroll = () => {
      // const scrollObserver = document.querySelector(".scrollObserver");
      // const observer = new IntersectionObserver((entries) => {
      //   if (!entries[0].isIntersecting || hasSelectedItem.value) return;
      //   const { limit, offset, total_elements } = devices.value;
      //   if (limit + offset > total_elements) return;
      //   store.dispatch("devices/getDevices", {
      //     pagination: {
      //       offset: limit + offset,
      //       limit,
      //     },
      //     infinite: true,
      //   });
      // });
      // if (scrollObserver) observer.observe(scrollObserver);
    };

    const updateDevices = (status) => {
      filters.value.deviceStatuses = status;
      store.commit("filters/SET_FILTERS", filters.value);
      store.commit("findDevices/CLOSE_ALL_MODALS");
      emit("update-map");
    };
    const resetSelectedItem = () => {
      store.commit("findDevices/RESET_SELECTED_ITEM");
    };
    const updateProximity = ({ device }) => {
      emit("update-proximity", { device });
    };

    return {
      searchStr,
      tabs,
      deviceStatusesRep,
      deviceStatuses,
      filters,
      devices,
      selectedItem,
      activeIndex,
      updateDevices,
      resetSelectedItem,
      deviceStatusesCounts,
      hasSelectedItem,
      updateProximity,
    };
  },
});
</script>

<style scoped></style>
