import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-head" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-footer" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.show }, "add-access-point"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Assign to clinician", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
      class: "form"
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("p", null, "Device will be assigned to:", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseLabel, { title: "Clinician name" }),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.name) = $event)),
          class: "base__input",
          placeholder: "Enter clinician name",
          type: "text",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.data.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseLabel, { title: "Add Comment" }),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.note) = $event)),
          class: _normalizeClass([{ input__error: _ctx.hasNoteError }, "base__textarea"]),
          placeholder: "Write comment here...",
          required: ""
        }, null, 2), [
          [_vModelText, _ctx.data.note]
        ]),
        _createElementVNode("span", {
          class: _normalizeClass([{ error: _ctx.hasNoteError }, "base__textarea__counter"])
        }, _toDisplayString(_ctx.data.note.length) + "/" + _toDisplayString(_ctx.maxLength), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          disabled: _ctx.disabled || _ctx.hasNoteError,
          type: "submit",
          class: "form-footer__btn-full"
        }, " Mark as In-Use ", 8, _hoisted_5)
      ])
    ], 32)
  ], 2))
}