import { MutationTree } from "vuex";
import { FilterState, FilterTypes } from "@/store/modules/filters/types";
import { DeviceStatusEnums } from "@/store/modules/devices/types";
import router from "@/router";
import { helpers } from "@/composables/helpers";

const { replaceKeysWithEmptyString } = helpers();

export const mutations: MutationTree<FilterState> = {
  SET_FILTERS(state: FilterState, data: FilterTypes) {
    const currentRoute: any = router.currentRoute;
    const routeName = currentRoute._value.name;
    if (!data.deviceStatuses && routeName === "FindDevices") {
      data.deviceStatuses = DeviceStatusEnums.AVAILABLE;
    }
    state["filters"] = Object.assign({}, data);
    return state["filters"];
  },
  SET_SELECTED_FILTER(state: FilterState, data: string) {
    state["selectedFilter"] = data;
    return state["selectedFilter"];
  },
  SET_DATE_FROM(state: FilterState, data: string) {
    state["dateFrom"] = data;
    return state["dateFrom"];
  },
  SET_DATE_TO(state: FilterState, data: string) {
    state["dateTo"] = data;
    return state["dateTo"];
  },
  SET_SEARCH_STRING(state: FilterState, data: string) {
    state.searchString = data;
    return state.searchString;
  },
  CLEAR_FILTERS(state: FilterState) {
    const defaultFilters = {
      department: "",
      category: "",
      subCategory: "",
      vendor: "",
      reconciliationStatuses: [],
      deviceStatuses: "",
      locationUnit: "",
    };
    state["filters"] = { ...state["filters"], ...defaultFilters };
    return state["filters"];
  },
};
