<template>
  <button
    :disabled="disabled"
    @click="initiateJob"
    class="access-point__refresh"
  >
    <BaseIcon :icon="`access-point/${disabled ? 'loading' : 'refresh'}`" />
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import {
  GlueClient,
  StartJobRunCommand,
  GetJobRunCommand,
} from "@aws-sdk/client-glue";
import getEnv from "@/utils/env.js";
import store from "@/store";

export default defineComponent({
  name: "RefreshAccessPoints",
  components: { BaseIcon },
  setup() {
    const disabled = ref(false);

    const initiateJob = async () => {
      try {
        disabled.value = true;
        const client = new GlueClient({
          region: getEnv("VUE_APP_SQUID_AWS_DEFAULT_REGION"),
          credentials: {
            accessKeyId: getEnv("VUE_APP_SQUID_AWS_ACCESS_KEY_ID"),
            secretAccessKey: getEnv("VUE_APP_SQUID_AWS_SECRET_ACCESS_KEY"),
          },
        });
        const JobName = getEnv(
          "VUE_APP_SQUID_AIRWAVE_POPULATE_ACCESS_POINTS_JOB"
        );
        const command = new StartJobRunCommand({
          JobName,
        });

        const response: any = await client.send(command);
        const command2 = new GetJobRunCommand({
          JobName,
          RunId: response.JobRunId,
        });
        let statusResponse: any = await client.send(command2);
        let status = statusResponse.JobRun.JobRunState;
        while (status === "RUNNING") {
          statusResponse = await client.send(command2);
          status = statusResponse.JobRun.JobRunState;
        }
        store.commit(
          "accessPoints/SET_LAST_TIME_ACCESS_POINTS_REFRESHED",
          new Date()
        );
        disabled.value = false;
      } catch (e) {
        console.log(e);
        disabled.value = false;
      }
    };

    return {
      initiateJob,
      disabled,
    };
  },
});
</script>
