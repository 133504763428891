import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "demo-date-picker" }
const _hoisted_2 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_date_picker, {
        modelValue: _ctx.date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
        type: "daterange",
        "start-placeholder": "Start Date",
        "end-placeholder": "End Date",
        format: "DD MMMM YYYY",
        "value-format": "YYYY-MM-DD",
        shortcuts: _ctx.shortcuts,
        "disabled-date": _ctx.isDateDisabled
      }, null, 8, ["modelValue", "shortcuts", "disabled-date"])
    ])
  ]))
}