import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { DevicesState } from "@/store/modules/devices/types";

export const getters: GetterTree<DevicesState, RootState> = {
  devices(state: DevicesState) {
    return state.devices;
  },
  hospitals(state: DevicesState) {
    return state.hospitals;
  },
  departments(state: DevicesState) {
    return state.departments;
  },
  categories(state: DevicesState) {
    return state.categories;
  },
  subCategories(state: DevicesState) {
    return state.subCategories;
  },
  vendors(state: DevicesState) {
    return state.vendors;
  },
  device(state: DevicesState) {
    return state.device;
  },
  deviceEvents(state: DevicesState) {
    return state.deviceEvents;
  },
  reconciliationEvent(state: DevicesState) {
    return state.reconciliationEvent;
  },
  deviceBiomedEvents(state: DevicesState) {
    return state.deviceBiomedEvents;
  },
  deviceFinancialEvents(state: DevicesState) {
    return state.deviceFinancialEvents;
  },
  deviceLocationEvents(state: DevicesState) {
    return state.deviceLocationEvents;
  },
  deviceUtilizationEvents(state: DevicesState) {
    return state.deviceUtilizationEvents;
  },
  deviceReconciliationCounts(state: DevicesState) {
    return state.deviceReconciliationCounts;
  },
  deviceReconciliationStatusesCounts(state: DevicesState) {
    return state.deviceReconciliationStatusesCounts;
  },
  deviceStatusesCounts(state: DevicesState) {
    return state.deviceStatusesCounts;
  },
  deviceFiles(state: DevicesState) {
    return state.deviceFiles;
  },
  devicesProximity(state: DevicesState) {
    return state.devicesProximity;
  },
  utilGroupBy(state: DevicesState) {
    return state.utilGroupBy;
  },
};
