<template>
  <div
    v-if="hasAccessPointCoordinates && showLocationPrompt"
    :class="{ active: showLocationPrompt && accessPoint }"
    class="access-point__location__prompt"
    id="access-point__location__prompt"
    :style="{ top: `${accessPoint.top}px`, left: `${accessPoint.left}px` }"
  >
    <button @click="close">Not yet</button>
    <button @click="setLocation">Set Location</button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "@/store";
import { access_points } from "@/composables/maps/access_points";

export default defineComponent({
  name: "AccessPointLocationPromptModal",
  setup() {
    const { hasAccessPointCoordinates } = access_points();

    const showLocationPrompt = computed(() => {
      return store.getters["accessPoints/showLocationPrompt"];
    });

    const accessPoint = computed(() => {
      return store.getters["accessPoints/accessPoint"];
    });

    const close = () => {
      store.commit("accessPoints/SET_SHOW_LOCATION_PROMPT", false);
    };

    const setLocation = () => {
      store.commit("accessPoints/SET_SHOW_LOCATION_PROMPT", false);
      store.commit("accessPoints/SET_SHOW_ADD", true);
      accessPoint.value.setLocation = true;
      store.commit("accessPoints/SET_ACCESS_POINT", accessPoint.value);
    };

    return {
      close,
      accessPoint,
      setLocation,
      hasAccessPointCoordinates,
      showLocationPrompt,
    };
  },
});
</script>
