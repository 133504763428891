import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { TicketsState } from "@/store/modules/tickets/types";

export const getters: GetterTree<TicketsState, RootState> = {
  tickets(state: TicketsState) {
    return state.tickets;
  },
  ticket(state: TicketsState) {
    return state.ticket;
  },
  events(state: TicketsState) {
    return state.events;
  },
};
