import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { LocationsState } from "@/store/modules/locations/types";

export const getters: GetterTree<LocationsState, RootState> = {
  geojson(state: LocationsState) {
    return state.geojson;
  },
  accessPoints(state: LocationsState) {
    return state.accessPoints;
  },
  center(state: LocationsState) {
    return state.center;
  },
  unmappedAccessPoints(state: LocationsState) {
    return state.unmappedAccessPoints;
  },
  roomsUnits(state: LocationsState) {
    return state.roomsUnits;
  },
  zoom(state: LocationsState) {
    return state.zoom;
  },
  accessPointsCoordinates(state: LocationsState) {
    return state.accessPointsCoordinates;
  },
  buildings(state: LocationsState) {
    return state.buildings;
  },
  floors(state: LocationsState) {
    return state.floors;
  },
  floor(state: LocationsState) {
    return state.floor;
  },
  building(state: LocationsState) {
    return state.building;
  },
  floorNumber(state: LocationsState) {
    return state.floorNumber;
  },

  showUpdateRoom(state: LocationsState) {
    return state.showUpdateRoom;
  },
};
