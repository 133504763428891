import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/dashboard/getters";
import { mutations } from "@/store/modules/dashboard/mutations";
import { actions } from "@/store/modules/dashboard/actions";
import { DashboardState } from "@/store/modules/dashboard/types";

export const DashboardData: DashboardState = {
  data: [],
};

export const repairs: Module<DashboardState, RootState> = {
  namespaced: true,
  state: DashboardData,
  getters,
  actions,
  mutations,
};

export default repairs;
