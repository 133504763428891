import { DecryptCommand, KMSClient } from "@aws-sdk/client-kms";
import getEnv from "@/utils/env";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { Buffer } from "buffer";

declare global {
  interface Window {
    configs: any;
  }
}

export default async function getEnvs(): Promise<any> {
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  const key =
    subdomain === "mmc"
      ? "squid-eye/production.json"
      : "squid-eye/development.json";

  // console.log({ key, subdomain });

  const encodedString = await getObject("squid-apps-secrets", key);

  if (!encodedString) return null;
  const envData = await decrypt(encodedString);
  // console.log({ envData });
  window.configs = {};
  for (const [key, value] of Object.entries(envData)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env[key] = value;
    window.configs[key] = value;
    // console.log({ key, value });
  }
  return envData;
}

const getObject = async (bucket: string, key: string) => {
  const client = new S3Client({
    region: getEnv("VUE_APP_SQUID_AWS_DEFAULT_REGION"),
    credentials: {
      accessKeyId: getEnv("VUE_APP_SQUID_KMS_AWS_ACCESS_KEY_ID"),
      secretAccessKey: getEnv("VUE_APP_SQUID_KMS_AWS_SECRET_ACCESS_KEY"),
    },
  });
  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });
  try {
    const response = await client.send(command);
    if (!response.Body) {
      return null;
    }
    const encodedCiphertext = await response.Body.transformToString();
    return Buffer.from(encodedCiphertext, "base64");
  } catch (err) {
    console.error(err);
    return null;
  }
};

const decrypt = async (encodedString: Buffer) => {
  try {
    const client = new KMSClient({
      region: getEnv("VUE_APP_SQUID_AWS_DEFAULT_REGION"),
      credentials: {
        accessKeyId: getEnv("VUE_APP_SQUID_KMS_AWS_ACCESS_KEY_ID"),
        secretAccessKey: getEnv("VUE_APP_SQUID_KMS_AWS_SECRET_ACCESS_KEY"),
      },
    });
    const command2 = new DecryptCommand({
      CiphertextBlob: encodedString,
      KeyId:
        "arn:aws:kms:us-east-1:049633621153:key/2d4f306e-3e4a-408b-8e9b-12c541db0e0a",
    });
    const response = await client.send(command2);
    if (!response["Plaintext"]) {
      return null;
    }
    const decoder = new TextDecoder("utf-8");
    const str = JSON.parse(decoder.decode(response["Plaintext"]));
    return JSON.parse(str);
  } catch (err) {
    console.error(err);
    return null;
  }
};
