import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "location__navigations__modal floor" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floors, (floor, key) => {
      return (_openBlock(), _createElementBlock("h6", {
        key: key,
        class: _normalizeClass({ active: floor.id === _ctx.currentFloor.id }),
        onClick: ($event: any) => (_ctx.updateFloor(floor))
      }, _toDisplayString(floor.floor_number), 11, _hoisted_2))
    }), 128))
  ]))
}