import { ActionTree } from "vuex";
import { AuthState } from "@/store/modules/auth/types";
import { RootState } from "@/store/types";
import AxiosClient from "@/services/api";
import getEnv from "@/utils/env";

export const actions: ActionTree<AuthState, RootState> = {
  logout({ commit }): Promise<void> {
    // await commit("LOGOUT");
    return AxiosClient.post("/auth/signout")
      .then((res) => {
        commit("LOGOUT");
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  signIn({ commit }, { code }): void | Promise<void> {
    let url = "/auth/signin?";

    if (getEnv("VUE_APP_SQUID_ENVIRONMENT") === "local") {
      url += "redirect=http://localhost:8085/callback";
    }

    return AxiosClient.post(
      url,
      { codeGrant: code },
      { params: { hideProgressBar: true } }
    )
      .then((res) => {
        commit("SET_JWT", res.data.jwt);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getUser({ commit }): void | Promise<void> {
    return AxiosClient.get("/auth/userinfo", {
      params: { hideProgressBar: true },
    })
      .then((res) => {
        commit("SET_AUTH_USER", res.data);
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
