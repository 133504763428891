<template>
  <div class="device__events__list__item">
    <div class="events__list__item__header">
      <h6 class="events__item__header__date">
        {{ formatDateFromMs(event.created_date) }}
      </h6>
      <div class="events__item__header__right">
        <span class="events__item__header__status">{{ status }}</span>
      </div>
    </div>
    <slot :is-open="isOpen"></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { helpers } from "@/composables/helpers";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "DeviceBaseTypeEvents",

  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { formatMoney } = helpers();
    const isOpen = ref(false);

    // eslint-disable-next-line vue/no-setup-props-destructure
    const { event, type } = props;

    const toggle = () => {
      return (isOpen.value = !isOpen.value);
    };

    const status = computed(() => {
      if (type === "location") {
        return event.type;
      }
      if (type === "financial") {
        return formatStatus(event.type);
      }
      return formatStatus(event.status);
    });

    const { formatStatus, formatDateFromMs, addStatusClass } = viewDevice();

    return {
      formatMoney,
      status,
      isOpen,
      toggle,
      formatStatus,
      formatDateFromMs,
      addStatusClass,
    };
  },
});
</script>

<style scoped></style>
