import { helpers } from "@/composables/helpers";

export function tickets() {
  const { formatDate } = helpers();

  const getRepairTicketClosedDate = (row, key) => {
    if (row.status !== "closed") {
      return "-";
    }
    return formatDate(row[key]);
  };

  return {
    getRepairTicketClosedDate,
  };
}
