export interface TicketsState {
  tickets: object[];
  ticket: object;
  events: object[];
}

export enum RepairTicketsStatusesEnums {
  OPEN = "open",
  ON_HOLD = "on_hold",
  PENDING_ASSIGNMENT = "pending_assignment",
  PENDING_REVIEW = "pending_review",
  READY_FOR_WORK = "ready_for_work",
  WORK_IN_PROGRESS = "work_in_progress",
  CLOSED = "closed",
}

export const RepairTicketsStatusesObject = {
  open: "Open",
  on_hold: "On hold",
  pending_assignment: "Pending assignment",
  pending_review: "Pending review",
  ready_for_work: "Ready for work",
  work_in_progress: "Work In progress",
  closed: "Closed",
};
