<template>
  <DoughnutChart
    :chartData="chartDataList"
    :is-filterable="true"
    :filter-exist="isReconStatus"
    :active-label="reconStatuses"
    @filter="updateData"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import DoughnutChart from "@/components/resusables/charts/DoughnutChart.vue";
import store from "@/store";

export default defineComponent({
  name: "DevicesReconciliationStatusesChart",
  components: {
    DoughnutChart,
  },
  setup() {
    const deviceStatuses = computed(() => {
      return store.getters["analytics/devicesReconciliationCounts"];
    });
    const filters = computed(() => {
      return store.getters["filters/filters"];
    });

    const reconStatuses = computed(() => {
      return filters.value.reconciliationStatuses;
    });

    const isReconStatus = computed(() => {
      return (
        filters.value.reconciliationStatuses &&
        filters.value.reconciliationStatuses.length > 0
      );
    });

    const updateChartList = () => {
      let datasets = [
        {
          label: "registered",
          value: deviceStatuses.value["registered"],
          color: "#016667",
          description: "(In use with proof of purchase)",
        },
        {
          label: "unbooked",
          value: deviceStatuses.value["unbooked"],
          color: "#B5CBCB",
          description:
            "(Not in ERP, but with proof of purchase & proof of existence)",
        },
        {
          label: "rogue",
          value: deviceStatuses.value["rogue"],
          color: "#DAE5E6",
          description: "(In use without proof of purchase)",
        },
        {
          label: "missing",
          value: deviceStatuses.value["missing"],
          color: "#DAB75D",
          description: "(Unavailable for use with proof of purchase)",
        },
        {
          label: "phantom",
          value: deviceStatuses.value["phantom"],
          color: "#E8D29B",
          description:
            "(In CMMS inventory, without proof of existence or purchase)",
        },
      ];

      // datasets = datasets.filter((dataset) => {
      //   const statuses = filters.value.reconciliationStatuses;
      //   if (statuses.length === 0) return true;
      //   return filters.value.reconciliationStatuses.includes(dataset.label);
      // });

      datasets = datasets.filter(
        (dataset) => !(dataset.label === "unbooked" && dataset.value === 0)
      );

      return {
        datasets: [
          {
            data: datasets.map((dataset) => dataset.value),
            backgroundColor: datasets.map((dataset) => dataset.color),
          },
        ],
        labels: datasets.map((dataset) => dataset.label),
        descriptions: datasets.map((dataset) => dataset.description),
      };
    };

    let chartDataList = ref(updateChartList());

    watch(deviceStatuses, () => {
      chartDataList.value = updateChartList();
    });

    const updateData = (status: string) => {
      if (filters.value.reconciliationStatuses.includes(status)) {
        filters.value.reconciliationStatuses = [];
      } else {
        filters.value.reconciliationStatuses = [status];
      }
      store.commit("filters/SET_SELECTED_FILTER", filters.value);
      store.dispatch("devices/getDevices");
    };

    return {
      chartDataList,
      isReconStatus,
      deviceStatuses,
      reconStatuses,
      updateData,
    };
  },
});
</script>
