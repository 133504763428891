<template>
  <CostCardItem title="Device Counts ">
    <CostCardListItem title="Devices Requested" price="131" />
    <CostCardListItem title="Avg Days Duration" price="6" />
  </CostCardItem>
</template>

<script>
import { defineComponent } from "vue";
import CostCardListItem from "@/components/resusables/cost/CostCardListItem.vue";
import CostCardItem from "@/components/resusables/cost/CostCardItem.vue";

export default defineComponent({
  name: "RentalsStatCard",
  components: { CostCardItem, CostCardListItem },
});
</script>
