<template>
  <div class="multi__line__chart">
    <div class="multi__line__chart__labels">
      <MeantimeChartLabels :datasets="chartDataList.datasets" />
    </div>
    <MultiLineChart :chart-data="chartDataList" :width="500" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";
import MultiLineChart from "@/components/resusables/charts/MultiLineChart.vue";
import MeantimeChartLabels from "@/components/charts/line/MeantimeChartLabels.vue";

export default defineComponent({
  name: "BiomedMeantimesLineChart",
  components: {
    MeantimeChartLabels,
    MultiLineChart,
  },
  setup() {
    const biomedMeantimesData = computed(() => {
      return store.getters["analytics/biomedMeantimesData"];
    });

    const updateChartList = () => {
      return {
        labels: biomedMeantimesData.value.responseMeantimes.labels,
        datasets: [
          {
            label: "Response",
            data: biomedMeantimesData.value.responseMeantimes.meantimes,
            borderColor: "#016667",
            backgroundColor: "#016667",
            yAxisID: "y",
          },
          {
            label: "Diagnosis",
            data: biomedMeantimesData.value.diagnosisMeantimes.meantimes,
            borderColor: "#A9CDCD",
            backgroundColor: "#A9CDCD",
            yAxisID: "y",
          },
          {
            label: "Repair",
            data: biomedMeantimesData.value.repairMeantimes.meantimes,
            borderColor: "#E9B32A",
            backgroundColor: "#E9B32A",
            yAxisID: "y",
          },
        ],
      };
    };

    let chartDataList = ref(updateChartList());

    watch(biomedMeantimesData, () => {
      chartDataList.value = updateChartList();
    });

    const sumArr = (arr) => {
      return arr.reduce((a, b) => a + b, 0);
    };

    return {
      chartDataList,
      sumArr,
      biomedMeantimesData,
    };
  },
});
</script>
