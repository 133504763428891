<template>
  <div class="modals modal">
    <div class="modal__header">
      <h3 class="modal__header__title">Contract Details</h3>
      <button @click="close" class="modals-trigger modal__header__close">
        <BaseIcon icon="modal-close" />
      </button>
    </div>
    <div class="modal__content">
      <div class="modal__content___left contract__left">
        <ContractDetails />
      </div>
      <div class="modal__content___right contract__right">
        <div>
          <h5 class="modal__content__sub__title">Device Serial Numbers</h5>

          <div class="">
            <div class="modal__tabs">
              <button
                @click="toggle(true)"
                :class="{ active: showUnVerified === true }"
              >
                Unverified
              </button>
              <button
                @click="toggle(false)"
                :class="{ active: showUnVerified === false }"
              >
                Verified
              </button>
            </div>
            <div
              v-if="showUnVerified && contract.leakage.length > 0"
              class="modal__table"
            >
              <div
                :key="key"
                v-for="(device, key) in contract.leakage"
                class="modal__table__single"
              >
                <h5 class="modal__table__row__title">{{ key + 1 }}.</h5>
                <div class="modal__table__row__body">
                  <h5 class="modal__table__row__value">{{ device }}</h5>
                </div>
              </div>
            </div>
            <div
              v-if="!showUnVerified && verified && verified.length > 0"
              class="modal__table"
            >
              <div
                :key="key"
                v-for="(device, key) in verified"
                class="modal__table__single"
              >
                <h5 class="modal__table__row__title">{{ key + 1 }}.</h5>
                <div class="modal__table__row__body">
                  <h5 class="modal__table__row__value">{{ device }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { contracts } from "@/composables/contracts/contracts";
import { helpers } from "@/composables/helpers";
import ContractDetails from "@/views/Contracts/ContractDetails.vue";

export default defineComponent({
  name: "ViewContract",
  components: { ContractDetails, BaseIcon },

  setup(props, context) {
    const route = useRoute();

    const { orderTypeName, getContractCost, getContractMaintenance } =
      contracts();
    const { formatDate } = helpers();
    const showUnVerified = ref(true);

    const close = () => {
      context.emit("close");
    };

    onMounted(async () => {
      const contractId = route.params.id;

      await store.dispatch("contracts/getContract", contractId);
    });

    const toggle = (value) => {
      showUnVerified.value = value;
    };

    const contract = computed(() => {
      return store.getters["contracts/contract"];
    });

    const verified = computed(() => {
      return contract.value.serial_numbers.filter(
        (x) => !contract.value.leakage.includes(x)
      );
    });

    return {
      close,
      contract,
      toggle,
      formatDate,
      orderTypeName,
      getContractCost,
      getContractMaintenance,
      showUnVerified,
      verified,
    };
  },
});
</script>

<style></style>
