<template>
  <div :class="{ active: show }" class="add-access-point find-devices__forms">
    <div class="form-head">
      <h3>Submit repair ticket</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="submit" class="form">
      <p>
        Device has a problem? Submit a repair ticket so it can be picked for
        repair.
      </p>
      <div class="form-group">
        <BaseLabel title="Description" />
        <textarea
          class="base__textarea"
          placeholder="Write device problem here..."
        ></textarea>
      </div>

      <div class="form-footer">
        <button type="submit" class="form-footer__btn-full">
          Submit repair ticket
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";
import { DeviceStatusEnums } from "@/store/modules/devices/types";

export default defineComponent({
  name: "SubmitRepairTicketModal",
  components: { BaseLabel, BaseIcon },
  setup(_, { emit }) {
    const disabled = ref(false);
    const status = DeviceStatusEnums.IN_REPAIR;

    onMounted(() => {
      store.commit("findDevices/SET_SHOW_SUBMIT_REPAIR_TICKET", false);
    });

    const show = computed(() => {
      return store.getters["findDevices/showSubmitRepairTicket"];
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const submit = async () => {
      disabled.value = true;
      await Promise.all([
        store.dispatch("devices/updateDeviceStatus", {
          deviceId: currentDevice.value.id,
          status,
        }),
      ]);
      disabled.value = false;

      emit("update-map", status);
    };

    const close = () => {
      store.dispatch("findDevices/setModal", {
        modal: "showSubmitRepairTicket",
        show: false,
      });
    };

    return {
      disabled,
      close,
      show,
      submit,
    };
  },
});
</script>
