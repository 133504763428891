<template>
  <div class="filters__category__group">
    <div
      v-for="(key, index) in Object.keys(scopeList)"
      :key="key"
      class="filters__category__group__item"
    >
      <div class="filters__category__group__item__head">
        <div class="filter__input__group">
          <BaseCheckbox
            :name="`head-${filter}`"
            @update:checked="checkGroup(filter, key, index, $event)"
            :checked="group[index]"
          />
          <BaseLabel :title="key" />
        </div>
        <div @click="selectGroup(index)">
          <BaseIcon :icon="`expand-${activeGroup[index] ? 'less' : 'more'}`" />
        </div>
      </div>
      <div
        v-if="activeGroup[index]"
        class="filters__category__group__item__body"
      >
        <div
          v-for="(scope, ind) in scopeList[key]"
          :key="ind"
          class="filters__category__group__item__body__item"
        >
          <BaseCheckbox
            :name="filter"
            @update:checked="checkValues(filter, key, scope, index, $event)"
            :checked="selecting[filter].some((fil) => fil === scope)"
          />
          <BaseLabel :title="split(scope)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import { helpers } from "@/composables/helpers";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";

export default defineComponent({
  name: "FilterCategoryGroup",
  components: {
    BaseCheckbox,
    BaseLabel,
    BaseIcon,
  },
  props: {
    scopeList: {
      type: [Array, Object],
      required: true,
    },
    filter: {
      type: String,
      required: true,
    },
    objectArray: {
      type: Boolean,
      default: true,
      required: false,
    },
    selecting: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const { split, areAllElementsExist } = helpers();
    const activeGroup = ref(
      Array.from({ length: Object.keys(props.scopeList).length }).fill(true)
    );

    const group = ref(
      Array.from({ length: Object.keys(props.scopeList).length }).fill(false)
    );

    const selectGroup = (index: number) => {
      activeGroup.value[index] = !activeGroup.value[index];
    };

    const setGroup = (field: string) => {
      let index = 0;
      const current = props.selecting[field] ? props.selecting[field] : [];
      for (let [_, values] of Object.entries(props.scopeList)) {
        if (areAllElementsExist(values, current)) {
          group.value[index] = true;
        }
        index++;
      }
    };

    watch(props.selecting, () => {
      setGroup(props.filter);
    });

    const checkGroup = (
      field: string,
      key: string,
      index: number,
      checked: boolean
    ) => {
      const values = props.scopeList[key];
      const current = props.selecting[field] ? props.selecting[field] : [];
      if (checked) {
        group.value[index] = true;
        // eslint-disable-next-line vue/no-mutating-props
        props.selecting[field] = [...new Set([...current, ...values])];
      } else {
        group.value[index] = false;
        const res = current.filter((val) => !values.includes(val));
        // eslint-disable-next-line vue/no-mutating-props
        props.selecting[field] = [...new Set(res)];
      }
    };

    const checkValues = (
      field: string,
      key: string,
      value: any,
      index: number,
      checked: boolean
    ) => {
      const values = props.scopeList[key];
      let current = props.selecting[field] ? props.selecting[field] : [];

      if (!checked && group.value[index]) {
        group.value[index] = false;
      }
      current.push(value);
      if (
        checked &&
        !group.value[index] &&
        areAllElementsExist(values, current)
      ) {
        group.value[index] = true;
      }

      context.emit("check", field, value, checked);
    };

    return { group, split, checkGroup, checkValues, activeGroup, selectGroup };
  },
});
</script>

<style scoped></style>
