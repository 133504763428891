<template>
  <div class="modals rentals-form modal">
    <div class="modal__header">
      <h3 class="modal__header__title">Request rental form</h3>
      <button @click="close" class="modals-trigger modal__header__close">
        <BaseIcon icon="modal-close" />
      </button>
    </div>
    <div class="modal__content">
      <div class="step__form">
        <div class="step__form__header">
          <div class="step__form__header__steps">
            <div class="step__form__header__steps__item">
              <div><span @click="changeStep(true)"></span></div>
              <h6>Rental Request</h6>
            </div>
            <div class="step__form__header__steps__item">
              <div><span @click="changeStep(false)"></span></div>
              <h6>Device(s) availability</h6>
            </div>
          </div>
        </div>
        <div class="step__form__content">
          <div v-show="first" class="step__form__content__item rental-request">
            <div class="form-wrapper">
              <div class="form-wrapper__header">
                <h4>Clinician Details</h4>
                <BaseIcon icon="arrow/down2" />
              </div>
              <div class="form-wrapper__content">
                <div class="col2">
                  <div class="form-group">
                    <BaseLabel title="Facility" />
                    <BaseSelect title="Select Facility" />
                  </div>
                  <div class="form-group">
                    <BaseLabel title="Department" />
                    <BaseSelect title="Select Department" />
                  </div>
                </div>
                <div class="form-group">
                  <BaseLabel title="Clinician" />
                  <BaseSelect title="Select Clinician" />
                </div>
              </div>
            </div>

            <div class="form-wrapper">
              <div class="form-wrapper__header">
                <h4>Device Details</h4>
                <BaseIcon icon="arrow/down2" />
              </div>
              <div class="form-wrapper__content">
                <div class="col2">
                  <div class="form-group">
                    <BaseLabel title="Category" />
                    <BaseSelect title="Select Category" />
                  </div>
                  <div class="form-group">
                    <BaseLabel title="Sub-Category" />
                    <BaseSelect title="Select Sub-Category" />
                  </div>
                </div>
                <div class="form-group">
                  <BaseLabel title="Number of Devices" />
                  <BaseInput type="number" />
                </div>
              </div>
            </div>

            <div class="form-wrapper">
              <div class="form-wrapper__header">
                <h4>Rental Details</h4>
                <BaseIcon icon="arrow/down2" />
              </div>
              <div class="form-wrapper__content">
                <div class="form-group">
                  <BaseLabel title="Expected Duration" />
                  <BaseInput type="number" />
                </div>
              </div>
            </div>
            <div class="step__form__submit">
              <button
                class="step__form__submit__btn"
                @click="changeStep(false)"
              >
                Continue
              </button>
            </div>
          </div>

          <div
            v-show="!first"
            class="step__form__content__item device-availability"
          >
            <ItemsList title="Rental request details">
              <ItemRow title="Device Description">
                <h5 class="modal__table__row__value">Ventilator</h5>
                <h5 class="modal__table__row__value">Precision flow plus</h5>
              </ItemRow>
              <ItemRow title="Number of devices" value="1 device" />
              <ItemRow title="Rental duration" value="4 days" />
            </ItemsList>

            <div class="form-group">
              <BaseLabel title="Is the requested device(s) in inventory" />
            </div>

            <button class="step__form__submit__btn">
              Submit Request and Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import ItemsList from "@/components/resusables/list/ItemsList.vue";
import ItemRow from "@/components/resusables/list/ItemRow.vue";
import router from "@/router";

export default defineComponent({
  name: "RentalsForm",
  components: {
    BaseIcon,
    BaseLabel,
    BaseSelect,
    BaseInput,
    ItemsList,
    ItemRow,
  },

  setup() {
    const first = ref(true);

    const changeStep = (bool) => {
      first.value = bool;
    };

    const close = () => {
      router.push({ name: "Rentals" });
    };

    return {
      first,
      changeStep,
      close,
    };
  },
});
</script>
