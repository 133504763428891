<template>
  <div class="table__container devices__container__table">
    <div class="table__container__header">
      <h4 v-if="showTitle" class="table__container__title">{{ name }}</h4>
      <slot name="header-right" />
      <div v-if="showColumnSetting" class="table__container__header__right">
        <ExportTable :name="name" />
        <button @click="toggleColList" class="table__container__header__action">
          <BaseIcon icon="table/settings" />
          <span>Columns</span>
        </button>

        <TableColumns
          v-if="colList"
          :columns="fields"
          @update="updateColumns"
        />
      </div>
    </div>

    <div class="table__wrapper">
      <table class="table devices__table relative">
        <thead class="table__head">
          <tr class="table__column table__head__row">
            <th
              :class="{
                active: activateColumn(field),
                lock__column: key === 0,
              }"
              :key="key"
              v-for="(field, key) in tableFields"
            >
              <span>{{ field.label }}</span>

              <span
                @click="sortColumn(key)"
                class="table__head__row__field__sort"
                v-if="field.sortable"
                :class="[
                  {
                    desc:
                      sorting.order_by === field.order_key &&
                      sorting.order_direction === 'DESC',
                  },
                  {
                    asc:
                      sorting.order_by === field.order_key &&
                      sorting.order_direction === 'ASC',
                  },
                ]"
              >
                <BaseIcon icon="table/sort/sort" />
              </span>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="data && data.data && data.data.length > 0"
          class="table__body"
        >
          <tr
            @click="open(row)"
            :key="key"
            v-for="(row, key) in data.data"
            :class="computeStatusClass(row['status'])"
            class="modals-trigger table__body__row table__column group capitalize"
          >
            <td
              :key="key2"
              :class="[
                addStatusClass(computeStatus(row, row[field.key]), field.key),
                computeStatusClass(row[field.key], field.key),
                { active: activateColumn(field) },
                { lock__column: key2 === 0 },
              ]"
              class="modals-trigger"
              v-for="(field, key2) in tableFields"
            >
              <span :class="modalsTriggerClass" v-if="field.type === 'status'">
                {{ split(computeStatus(row, row[field.key])) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'split'"
              >
                {{ split(row[field.key]) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'amount'"
              >
                {{ `$${formatMoney(row[field.key])}` ?? "-" }}
              </span>

              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'object'"
              >
                {{ getObjectData(row, field.key) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'user'"
              >
                {{ getUserFullName(row[field.key]) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'date'"
              >
                {{ row[field.key] ? formatDate(row[field.key]) : "-" }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'bool_answer'"
              >
                {{ row[field.key] ? "Yes" : "No" }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'file_name'"
              >
                <button
                  @click="downloadDeviceDoc(row)"
                  class="table__col__file__name"
                >
                  <BaseIcon icon="device/doc/pdf" /><span>{{
                    row[field.key]
                  }}</span>
                </button>
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'ticket_closed_date'"
              >
                {{ getRepairTicketClosedDate(row, field.key) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'array_len'"
              >
                {{ row[field.key] ? row[field.key].length : "-" }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'order_type'"
              >
                {{ getOrderTypeAmount(row, field.order_type) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'device_service'"
              >
                {{ getDeviceServiceContractCost(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'total_orders'"
              >
                {{ getTotalOrdersAmount(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'contract'"
              >
                {{ getOrderIds(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'unverified'"
              >
                {{ getContractUnverifiedCount(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'contract_type'"
              >
                {{ getContractType(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'contract_cost'"
              >
                {{ getContractCost(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'contract_maintenance'"
              >
                {{ getContractMaintenance(row) }}
              </span>
              <span
                :class="modalsTriggerClass"
                v-else-if="field.type === 'contract_savings'"
              >
                {{
                  savingsAmounts && savingsAmounts[key]
                    ? savingsAmounts[key]
                    : "-"
                }}
              </span>
              <span :class="modalsTriggerClass" v-else>{{
                row[field.key] ?? "-"
              }}</span>
            </td>
          </tr>
        </tbody>
        <slot v-else-if="hasSearchEmpty" />
        <tbody
          v-else-if="data && data.data && data.data.length === 0"
          class="table__body empty"
        >
          <tr>
            <td :colspan="tableFields.length"><span>No</span> {{ name }}</td>
          </tr>
        </tbody>
        <tbody v-else class="table__body skeleton">
          <tr :key="i" class="table__body__row table__column" v-for="i in 4">
            <td :key="i" v-for="i in tableFields.length"><span></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Pagination
    ref="paginationRef"
    v-if="showPagination"
    @paginate="update"
    :name="name"
    :pagination="pagination"
    :total-elements="data && data.data ? data.data.length : 0"
  />
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { helpers } from "@/composables/helpers";
import { devices } from "@/composables/table/devices";
import { contracts } from "@/composables/contracts/contracts";
import Pagination from "@/components/resusables/table/Pagination.vue";
import { tickets } from "@/composables/tickets/tickets";
import TableColumns from "@/components/resusables/table/TableColumns.vue";
import ExportTable from "@/components/resusables/table/ExportTable.vue";
import { savings } from "@/composables/contracts/savings";
import store from "@/store";

export default defineComponent({
  name: "TableComponent",
  emits: ["open", "update-pagination", "download-device-doc"],
  components: {
    ExportTable,
    TableColumns,
    Pagination,
    BaseIcon,
  },
  props: {
    data: {
      required: true,
      type: [Object, null],
      default: () => ({}),
    },
    fields: {
      required: false,
      type: Array,
      default: () => [],
    },
    name: {
      required: true,
      type: String,
    },
    computeStatus: {
      required: false,
      type: Function,
      default: () => "status",
    },
    showPagination: {
      required: false,
      type: Boolean,
      default: true,
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true,
    },
    showColumnSetting: {
      required: false,
      type: Boolean,
      default: true,
    },
    hasSearchEmpty: {
      required: false,
      type: Boolean,
      default: false,
    },
    computeStatusClass: {
      required: false,
      type: Function,
      default: () => null,
    },
  },

  data() {
    return {};
  },

  methods: {
    async sortColumn(index) {
      const field = this.tableFields[index];
      let sorting = this.sorting;

      if (sorting.order_by && field.order_key !== sorting.order_by) {
        sorting = {};
      }

      let direction = sorting.order_direction;

      if (direction === "ASC") {
        direction = "DESC";
      } else {
        direction = "ASC";
      }

      const sortBy = { order_by: field.order_key, order_direction: direction };

      await this.update({ sort: sortBy });

      this.$refs.paginationRef.resetPagination();
      this.sorting = sortBy;
    },
  },

  setup(props, { emit, expose }) {
    let tableFields = ref(props.fields);
    let colList = ref(false);
    let sorting = ref({});
    const modalsTriggerClass = "modals-trigger";

    let pagination = computed(() => {
      if (!props.data) return null;
      return {
        limit: props.data.limit,
        offset: props.data.offset,
        total_elements: props.data.total_elements,
      };
    });

    const { formatMoney, split, formatDate, addStatusClass, getUserFullName } =
      helpers();
    const { getOrderIds, getOrderTypeAmount, getTotalOrdersAmount } = devices();
    const { getRepairTicketClosedDate } = tickets();
    const {
      getContractMaintenance,
      getContractUnverifiedCount,
      getContractType,
      getContractCost,
      getDeviceServiceContractCost,
    } = contracts();

    const savingsAmounts = computed(() => {
      return store.getters["contracts/savingsAmounts"];
    });

    const open = (device) => {
      emit("open", device);
    };

    const getObjectData = (row, key) => {
      let result = row[key[0]];
      if (!result) return "-";
      for (let j = 1; j < key.length; j++) {
        result = result[key[j]];
        if (!result) return "-";
      }
      return result;
    };

    const update = ({ pagination = null, sort = null }) => {
      if (!sort) sort = sorting.value;
      emit("update-pagination", { pagination, sort });
    };

    const resetSorting = () => {
      sorting.value = {};
    };

    const activateColumn = (field) => {
      return (
        sorting.value.order_by && sorting.value.order_by === field.order_key
      );
    };
    const updateColumns = (obj) => {
      tableFields.value = props.fields.filter((_, index) => {
        return obj[index] && obj[index].selected;
      });
    };
    const toggleColList = () => {
      colList.value = !colList.value;
    };

    const downloadDeviceDoc = (row) => {
      emit("download-device-doc", row);
    };

    expose({ resetSorting });

    return {
      sorting,
      open,
      colList,
      split,
      activateColumn,
      addStatusClass,
      formatDate,
      getOrderIds,
      getOrderTypeAmount,
      getObjectData,
      downloadDeviceDoc,
      update,
      pagination,
      toggleColList,
      resetSorting,
      getTotalOrdersAmount,
      formatMoney,
      getContractMaintenance,
      getContractUnverifiedCount,
      getContractType,
      tableFields,
      updateColumns,
      getContractCost,
      getUserFullName,
      getDeviceServiceContractCost,
      getRepairTicketClosedDate,
      modalsTriggerClass,
      savingsAmounts,
    };
  },
});
</script>

<style scoped></style>
