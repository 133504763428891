import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    value: _ctx.modelValue,
    class: "base__input",
    onInput: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value)
    ))
  }, _ctx.$attrs), null, 16, _hoisted_1))
}