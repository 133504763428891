import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multi__line__chart" }
const _hoisted_2 = { class: "multi__line__chart__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeantimeChartLabels = _resolveComponent("MeantimeChartLabels")!
  const _component_MultiLineChart = _resolveComponent("MultiLineChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MeantimeChartLabels, {
        datasets: _ctx.chartDataList.datasets
      }, null, 8, ["datasets"])
    ]),
    _createVNode(_component_MultiLineChart, {
      "chart-data": _ctx.chartDataList,
      width: 500
    }, null, 8, ["chart-data"])
  ]))
}