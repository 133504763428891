<template>
  <div class="dashboard__notifications">
    <button
      @click="toggleNotification"
      class="modals-trigger dashboard__notifications__button"
    >
      <BaseIcon icon="header/notifications" />
    </button>

    <div
      class="modals dashboard__notifications__list"
      :class="{ active: showNotification }"
    >
      <div class="dashboard__notifications__list__header">
        <h2 class="dashboard__notifications__list__header__title">
          Notifications
        </h2>
        <a class="dashboard__notifications__list__header__link" href=""
          >Mark all as read</a
        >
      </div>
      <div class="dashboard__notifications__list__body">
        <p class="dashboard__notifications__empty">No Notifications</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon";
import { useStore } from "vuex";

export default defineComponent({
  name: "HeaderNotifications",
  components: { BaseIcon },
  setup() {
    const store = useStore();

    const showNotification = computed(() => {
      return store.state.dropdowns.header_notification;
    });

    const toggleNotification = () => {
      store.dispatch("dropdowns/toggleDropdown", "header_notification");
    };

    return { showNotification, toggleNotification };
  },
});
</script>

<style scoped></style>
