<template>
  <div
    :key="key"
    v-for="(item, key) in datasets"
    class="multi__line__chart__labels__item"
  >
    <span
      class="multi__line__chart__labels__item__color"
      :style="{ backgroundColor: item.borderColor }"
    ></span>
    <span class="multi__line__chart__labels__item__label">{{
      item.label
    }}</span>
    <span class="multi__line__chart__labels__item__value">{{
      sumArr(item.data)
    }}</span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LineChartLabels",
  props: {
    datasets: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const sumArr = (arr) => {
      return arr.reduce((a, b) => a + b, 0);
    };

    return {
      sumArr,
    };
  },
});
</script>

<style scoped></style>
