<template>
  <header class="dashboard__main__header">
    <!--    <h2 class="dashboard__main__header__title">Dashboard</h2>-->
    <div class="dashboard__main__header__right">
      <HeaderNotifications />
      <HeaderDropdown />
    </div>
  </header>
</template>

<script>
import { defineComponent } from "vue";
import HeaderNotifications from "@/components/layout/header/HeaderNotifications";
import HeaderDropdown from "@/components/layout/header/HeaderDropdown";

export default defineComponent({
  name: "DashboardHeader",
  components: { HeaderDropdown, HeaderNotifications },
});
</script>

<style scoped></style>
