import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rentals__chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DoughnutChart, {
      "total-label": "Device Requests",
      title: "Device Requests",
      chartData: _ctx.chartDataList
    }, null, 8, ["chartData"])
  ]))
}