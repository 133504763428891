<template>
  <div v-if="columnsValues" class="table__columns">
    <div class="table__columns__header">
      <h5>Manage Columns</h5>
    </div>
    <div class="table__columns__list">
      <div
        v-for="(column, key) in columns"
        :key="key"
        class="table__columns__list__item"
      >
        <div class="table__columns__list__item__select">
          <BaseCheckbox
            @update:checked="check(key, $event)"
            :checked="columnsValues[key].selected"
          />
          <BaseLabel :title="column.label" />
        </div>
        <button
          v-if="false"
          @click="lockColumn(key)"
          class="table__columns__list__item__lock"
        >
          <BaseIcon
            :icon="`table/${lockedIndex !== key ? 'lock' : 'unlock'}`"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import BaseCheckbox from "@/components/form/BaseCheckbox.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "TableColumns",
  components: { BaseIcon, BaseLabel, BaseCheckbox },
  props: {
    columns: {
      required: true,
      type: Array,
    },
  },

  setup(props, { emit }) {
    const columnsValues = ref(null);
    const lockedIndex = ref(-1);

    const createObj = (n) => {
      const myObject = {};
      for (let i = 0; i <= n; i++) {
        myObject[i] = { selected: true, locked: false };
      }
      return myObject;
    };

    onMounted(() => {
      columnsValues.value = createObj(props.columns.length);
    });

    watch(
      () => props.columns.length,
      () => {
        columnsValues.value = createObj(props.columns.length);
      }
    );

    watch(
      columnsValues,
      () => {
        emit("update", columnsValues.value);
      },
      { deep: true }
    );

    const check = (index, checked) => {
      columnsValues.value[index].selected = checked;
    };
    const lockColumn = (index) => {
      if (lockedIndex.value === index) {
        lockedIndex.value = -1;
        return;
      }
      lockedIndex.value = index;
    };

    return {
      columnsValues,
      lockedIndex,
      check,
      lockColumn,
    };
  },
});
</script>

<style scoped></style>
