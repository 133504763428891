import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modals modal" }
const _hoisted_2 = { class: "modal__header" }
const _hoisted_3 = { class: "modal__content" }
const _hoisted_4 = { class: "modal__content___left contract__left" }
const _hoisted_5 = { class: "modal__content___right contract__right" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "modal__tabs" }
const _hoisted_8 = {
  key: 0,
  class: "modal__table"
}
const _hoisted_9 = { class: "modal__table__row__title" }
const _hoisted_10 = { class: "modal__table__row__body" }
const _hoisted_11 = { class: "modal__table__row__value" }
const _hoisted_12 = {
  key: 1,
  class: "modal__table"
}
const _hoisted_13 = { class: "modal__table__row__title" }
const _hoisted_14 = { class: "modal__table__row__body" }
const _hoisted_15 = { class: "modal__table__row__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ContractDetails = _resolveComponent("ContractDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "modal__header__title" }, "Contract Details", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "modals-trigger modal__header__close"
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal-close" })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ContractDetails)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _cache[4] || (_cache[4] = _createElementVNode("h5", { class: "modal__content__sub__title" }, "Device Serial Numbers", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(true))),
                class: _normalizeClass({ active: _ctx.showUnVerified === true })
              }, " Unverified ", 2),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggle(false))),
                class: _normalizeClass({ active: _ctx.showUnVerified === false })
              }, " Verified ", 2)
            ]),
            (_ctx.showUnVerified && _ctx.contract.leakage.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contract.leakage, (device, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: "modal__table__single"
                    }, [
                      _createElementVNode("h5", _hoisted_9, _toDisplayString(key + 1) + ".", 1),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h5", _hoisted_11, _toDisplayString(device), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (!_ctx.showUnVerified && _ctx.verified && _ctx.verified.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verified, (device, key) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: key,
                      class: "modal__table__single"
                    }, [
                      _createElementVNode("h5", _hoisted_13, _toDisplayString(key + 1) + ".", 1),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("h5", _hoisted_15, _toDisplayString(device), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}