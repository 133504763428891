<template>
  <div v-if="location" class="device__location__levels">
    <template v-if="location.floor_name">
      <BaseIcon icon="location" />
      <h6>{{ location.floor_name }}</h6>
    </template>
    <template v-if="location.unit_name">
      <BaseIcon icon="helpers/full-stop" />
      <h6>{{ location.unit_name }}</h6>
    </template>
    <template v-if="location.room_name">
      <BaseIcon icon="helpers/full-stop" />
      <h6>{{ location.room_name }}</h6>
    </template>
    <template v-if="location.bed_number">
      <BaseIcon icon="helpers/full-stop" />
      <h6>{{ location.bed_number }}</h6>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "DeviceLocation",
  components: { BaseIcon },

  props: {
    location: {
      type: [Object, null],
      required: true,
      default: null,
    },
  },
});
</script>

<style scoped></style>
