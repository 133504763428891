<template>
  <input
    :value="modelValue"
    class="base__input"
    @input="
      $emit('update:modelValue', ($event.target as HTMLInputElement).value)
    "
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseInput",

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
      required: false,
    },
  },

  emits: ["update:modelValue"],
});
</script>

<style scoped>
.base__input {
  width: 100%;
  outline: none;
}
</style>
