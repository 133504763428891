<template>
  <ItemRow title="User id">
    <h5 class="modal__table__row__value">
      {{ event.user_id || "N/A" }}
    </h5>
  </ItemRow>
  <ItemRow title="Patient MRN">
    <h5 class="modal__table__row__value">
      {{ event.patient_hash || "N/A" }}
    </h5>
  </ItemRow>
</template>

<script>
import { defineComponent } from "vue";
import ItemRow from "../../../resusables/list/ItemRow";

export default defineComponent({
  name: "UtilizationEvent",
  components: { ItemRow },
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped></style>
