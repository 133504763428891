import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initiateJob && _ctx.initiateJob(...args))),
    class: "access-point__refresh"
  }, [
    _createVNode(_component_BaseIcon, {
      icon: `access-point/${_ctx.disabled ? 'loading' : 'refresh'}`
    }, null, 8, ["icon"])
  ], 8, _hoisted_1))
}