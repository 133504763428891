import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { PaginationState } from "@/store/modules/pagination/types";

export const getters: GetterTree<PaginationState, RootState> = {
  offset(state: PaginationState): number {
    return state.offset;
  },
  limit(state: PaginationState): number {
    return state.limit;
  },
  page(state: PaginationState): number {
    return state.page;
  },
};
