<template>
  <GoogleMap
    id="map"
    ref="mapRef"
    :api-key="GOOGLE_MAP_API_KEY"
    class="w-full h-full"
    :center="mapCenter"
    :zoom="zoom"
    :styles="styles"
    :zoomControl="true"
    :mapTypeControl="false"
    :streetViewControl="false"
    @center_changed="centerChanged"
    @click="clickEvents"
  >
    <Marker
      v-if="hasAccessPointCoordinates && !accessPoint.setLocation"
      @dragend="updateAccessPointCoordinate"
      @click="openLocationPrompt"
      :options="{
        position: accessPoint.coordinate,
        clickable: true,
        draggable: true,
        zIndex: 100000000000000,
        icon: 'https://squid-eye.s3.amazonaws.com/images/location_setter.svg',
      }"
    >
    </Marker>

    <CustomMarker
      v-for="(accessPoint, key) in accessPointsCoordinatesTransformed"
      :key="key"
      @click.stop="editAccessPoint(accessPoint)"
      :options="{
        position: accessPoint.coordinate,
        zIndex: 100000000,
      }"
    >
      <div class="access__point__map__display" :class="accessPoint.status">
        <span v-if="showAccessPointIcon(accessPoint.access_point)">
          <BaseIcon
            v-if="accessPoint.access_point.is_anchor"
            icon="access-point/tag/anchor"
          />
          <BaseIcon
            v-else-if="accessPoint.access_point.is_ble_compatible"
            icon="access-point/tag/ble"
          />
        </span>
        <h6>
          {{ accessPoint.label }}
        </h6>
      </div>
    </CustomMarker>

    <template
      v-for="(accessPoint, key) in accessPointsCoordinatesTransformed"
      :key="key"
    >
      <template v-if="showCoverage(accessPoint)">
        <Circle
          :options="{
            center: accessPoint.coordinate,
            radius,
            fillColor: '#239FA1',
            fillOpacity: 0.35,
            strokeWeight: 0,
          }"
        />
      </template>
    </template>
    <template :key="key" v-for="(feature, key) in features">
      <Polygon @click="initializeRoomUpdate(feature, key)" :options="feature" />
      <Marker
        @click="initializeRoomUpdate(feature, key)"
        v-if="feature.properties['ROOM-NAME'] && feature.center"
        :options="{
          position: feature.center,
          clickable: true,
          label: {
            text: feature.properties['ROOM-NAME'],
            className: 'room-marker-label',
          },
          icon: 'None',
        }"
      />
    </template>
  </GoogleMap>
  <UpdateRoomModal ref="updateRoomRef" @update="updateRoom" />
  <AddAccessPointModal @set_location="setAccessPointDefaultLocation" />
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import store from "../../store";
import {
  GoogleMap,
  Polygon,
  Marker,
  CustomMarker,
  Circle,
} from "vue3-google-map";
import UpdateRoomModal from "@/components/access-points/rooms/UpdateRoomModal.vue";
import { locations } from "@/composables/maps/locations";
import { access_points } from "@/composables/maps/access_points";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import AddAccessPointModal from "@/components/access-points/AddAccessPointModal.vue";
import getEnv from "@/utils/env";

export default defineComponent({
  name: "AccessPointsMap",
  components: {
    AddAccessPointModal,
    BaseIcon,
    UpdateRoomModal,
    GoogleMap,
    Polygon,
    Marker,
    CustomMarker,
    Circle,
  },
  setup() {
    const GOOGLE_MAP_API_KEY = getEnv("VUE_APP_SQUID_GOOGLE_MAP_API_KEY");

    const { features, getCoordinatesCenter } = locations();
    const {
      hasAccessPointCoordinates,
      accessPointsCoordinatesTransformed,
      showAccessPointIcon,
    } = access_points();

    const mapRef = ref(null);
    const room = ref();
    const updateRoomRef = ref();
    const activeRoom = ref(-1);
    const radius = 28 * 0.3048;
    const mapCenter = reactive({
      lat: 40.63950114685483,
      lng: -73.99869818841954,
    });

    const styles = [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
    ];

    onMounted(() => {
      store.commit("locations/SET_SHOW_UPDATE_ROOM", false);
      store.commit("accessPoints/SET_SHOW_COVERAGE", false);
    });

    const zoom = computed(() => {
      return store.getters["locations/zoom"];
    });

    const currentFloor = computed(() => {
      return store.getters["locations/floorNumber"];
    });

    const geojson = computed(() => {
      return store.getters["locations/geojson"];
    });

    const accessPoint = computed(() => {
      return store.getters["accessPoints/accessPoint"];
    });

    const coverages = computed(() => {
      return store.getters["accessPoints/coverages"];
    });

    const showCoverage = (accessPoint) => {
      const coveragesValues = coverages.value;
      let is_anchor = accessPoint.access_point.is_anchor;
      let is_ble = accessPoint.access_point.is_ble_compatible;
      let is_wifi_only = !accessPoint.access_point.is_ble_compatible;

      if (
        is_wifi_only &&
        coveragesValues.includes("wifi") &&
        coveragesValues.includes(accessPoint.status)
      ) {
        return true;
      }
      if (
        is_ble &&
        !is_anchor &&
        coveragesValues.includes("ble") &&
        coveragesValues.includes(accessPoint.status)
      ) {
        return true;
      }
      if (
        is_anchor &&
        coveragesValues.includes("anchor_ble") &&
        coveragesValues.includes(accessPoint.status)
      ) {
        return true;
      }
      return false;
    };

    watch(activeRoom, (updatedValue, prev) => {
      if (!geojson.value.features) return;
      if (prev >= 0) geojson.value.features[prev].strokeColor = "#737879";
      geojson.value.features[updatedValue].strokeColor = "#016667";
    });

    const center = computed(() => {
      // const middleIndex = Math.round(features.value.length / 2);
      // return features.value[middleIndex]["paths"][0];
      return store.getters["locations/center"];
    });

    const initializeRoomUpdate = (feature, index) => {
      activeRoom.value = index;
      room.value = {
        name: feature.properties["ROOM-NAME"],
        label: feature.properties["LABEL"],
        unit: feature.properties["UNIT"],
        index,
        jsonId: feature.jsonId,
      };
      store.commit("locations/SET_SHOW_UPDATE_ROOM", true);
      updateRoomRef.value.childMethod(room.value);
    };

    const updateRoom = () => {
      store.commit("locations/SET_SHOW_UPDATE_ROOM", false);
    };

    const centerChanged = () => {
      const gmap = mapRef.value.map;
      const center = gmap.getCenter();
      mapCenter.lat = center.lat();
      mapCenter.lng = center.lng();
    };
    const clickEvents = () => {
      store.commit("accessPoints/SET_SHOW_COVERAGE", false);
    };
    const setAccessPointDefaultLocation = (label) => {
      store.commit("accessPoints/SET_ACCESS_POINT", {
        label,
        coordinate: mapCenter,
      });
      store.commit("accessPoints/SET_SHOW_ADD", false);
    };

    const updateAccessPointCoordinate = (marker) => {
      const lat = marker.latLng.lat();
      const lng = marker.latLng.lng();
      const coordinate = { lat, lng };
      const top = marker.domEvent.clientY - 72 + 30;
      const left = marker.domEvent.clientX - 220 - 60;

      if (accessPoint.value && accessPoint.value.jsonId) {
        accessPoint.value.coordinate = coordinate;
        accessPoint.value.top = top;
        accessPoint.value.left = left;
        store.commit("accessPoints/SET_ACCESS_POINT", accessPoint.value);
      } else {
        const label = (accessPoint.value && accessPoint.value.label) || "";
        store.commit("accessPoints/SET_ACCESS_POINT", {
          label,
          top,
          left,
          coordinate,
        });
      }
    };

    const openLocationPrompt = () => {
      store.commit("accessPoints/SET_SHOW_LOCATION_PROMPT", true);
    };

    const editAccessPoint = (accessPoint) => {
      if (!accessPoint.jsonId) {
        console.log("Access point doesnt have jsonId");
        return;
      }

      store.commit("accessPoints/SET_ACCESS_POINT", {
        label: accessPoint.label,
        status: accessPoint.status,
        floorNumber: currentFloor.value,
        jsonId: accessPoint.jsonId,
        setLocation: true,
        top: "40%",
        left: "40%",
        coordinate: accessPoint.coordinate,
      });
      store.commit("accessPoints/SET_SHOW_ADD", true);
    };

    return {
      geojson,
      center,
      mapCenter,
      mapRef,
      features,
      zoom,
      room,
      showCoverage,
      radius,
      styles,
      GOOGLE_MAP_API_KEY,
      updateRoomRef,
      getCoordinatesCenter,
      initializeRoomUpdate,
      hasAccessPointCoordinates,
      updateRoom,
      accessPoint,
      editAccessPoint,
      centerChanged,
      accessPointsCoordinatesTransformed,
      updateAccessPointCoordinate,
      openLocationPrompt,
      setAccessPointDefaultLocation,
      showAccessPointIcon,
      clickEvents,
    };
  },
});
</script>
