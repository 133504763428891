import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal__table__row" }
const _hoisted_2 = { class: "modal__table__row__body" }
const _hoisted_3 = { class: "modal__table__row__value" }
const _hoisted_4 = { class: "modal__table__value__attachment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemRow = _resolveComponent("ItemRow")!
  const _component_ItemsList = _resolveComponent("ItemsList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ItemsList, { title: "Contract Status" }, {
      default: _withCtx(() => [
        _createVNode(_component_ItemRow, { title: "Status" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.addStatusClass(_ctx.computeStatus), "modal__contract__status"])
            }, _toDisplayString(_ctx.computeStatus), 3)
          ]),
          _: 1
        }),
        _createVNode(_component_ItemRow, {
          title: "PO #",
          value: _ctx.contract.document_id
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Invoice #",
          value: _ctx.contract.invoice?.invoice_number
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Contract Type",
          value: _ctx.orderTypeName[_ctx.contract.type]
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Start Date",
          value: _ctx.formatDate(_ctx.contract.start_date)
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_ItemsList, {
      title: "Description",
      class: "mt-5"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.contract.description ? _ctx.contract.description.toLowerCase() : "-"), 1)
          ])
        ]),
        _createVNode(_component_ItemRow, {
          title: "Vendor",
          value: _ctx.contract?.vendor?.company_name
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Category",
          value: _ctx.contract?.subcategory?.category?.name
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Subcategory",
          value: _ctx.contract?.subcategory?.name
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Cost",
          value: _ctx.getContractCost(_ctx.contract)
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Savings",
          value: _ctx.getContractMaintenance(_ctx.contract)
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Number of Devices",
          value: _ctx.contract.quantity
        }, null, 8, ["value"]),
        _createVNode(_component_ItemRow, {
          title: "Unverified Devices",
          value: _ctx.contract.unverified_count ? _ctx.contract.unverified_count : 0
        }, null, 8, ["value"]),
        (_ctx.contract.warranty_end_date)
          ? (_openBlock(), _createBlock(_component_ItemRow, {
              key: 0,
              title: "Warranty End Date",
              value: _ctx.formatDate(_ctx.contract.warranty_end_date)
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.contract.warranty_type)
          ? (_openBlock(), _createBlock(_component_ItemRow, {
              key: 1,
              title: "Warranty Type",
              value: _ctx.contract.warranty_type
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ItemRow, {
          title: "Refundable",
          value: _ctx.contract.refundable ? 'Yes' : 'No'
        }, null, 8, ["value"]),
        (_ctx.contract.refundable)
          ? (_openBlock(), _createBlock(_component_ItemRow, {
              key: 2,
              title: "Refundable Type",
              value: _ctx.contract.refundable_type
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.contract.archive_path)
          ? (_openBlock(), _createBlock(_component_ItemRow, {
              key: 3,
              title: "PDF Attachment"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadDoc && _ctx.downloadDoc(...args)))
                  }, "View"),
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, "or", -1)),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.downloadDoc && _ctx.downloadDoc(...args)))
                  }, "download")
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}