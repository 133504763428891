<template>
  <div class="multi__line__chart">
    <div class="multi__line__chart__labels">
      <LineChartLabels :datasets="chartDataList.datasets" />
    </div>
    <MultiLineChart :chart-data="chartDataList" :width="500" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";
import MultiLineChart from "@/components/resusables/charts/MultiLineChart.vue";
import LineChartLabels from "@/components/charts/line/LineChartLabels.vue";
import { RepairTicketsStatusesEnums } from "@/store/modules/tickets/types";

export default defineComponent({
  name: "BiomedTimelineLineChart",
  components: {
    LineChartLabels,
    MultiLineChart,
  },
  setup() {
    const biomedTimelineData = computed(() => {
      return store.getters["analytics/biomedTimelineData"];
    });

    const updateChartList = () => {
      return {
        labels: biomedTimelineData.value.labels,
        datasets: [
          {
            label: "Open",
            data: biomedTimelineData.value[RepairTicketsStatusesEnums.OPEN],
            borderColor: "#000000",
            backgroundColor: "#000000",
            yAxisID: "y",
          },

          {
            label: "On hold",
            data: biomedTimelineData.value[RepairTicketsStatusesEnums.ON_HOLD],
            borderColor: "#A9CDCD",
            backgroundColor: "#A9CDCD",
            yAxisID: "y",
          },
          {
            label: "Pending assignment",
            data: biomedTimelineData.value[
              RepairTicketsStatusesEnums.PENDING_ASSIGNMENT
            ],
            borderColor: "#E9B32A",
            backgroundColor: "#E9B32A",
            yAxisID: "y",
          },
          {
            label: "Pending review",
            data: biomedTimelineData.value[
              RepairTicketsStatusesEnums.PENDING_REVIEW
            ],
            borderColor: "#908a8e",
            backgroundColor: "#908a8e",
            yAxisID: "y",
          },
          {
            label: "Ready for work",
            data: biomedTimelineData.value[
              RepairTicketsStatusesEnums.READY_FOR_WORK
            ],
            borderColor: "#7bbfbf",
            backgroundColor: "#7bbfbf",
            yAxisID: "y",
          },
          {
            label: "Work In progress",
            data: biomedTimelineData.value[
              RepairTicketsStatusesEnums.WORK_IN_PROGRESS
            ],
            borderColor: "#4b4747",
            backgroundColor: "#4b4747",
            yAxisID: "y",
          },
          {
            label: "Closed",
            data: biomedTimelineData.value.closed,
            borderColor: "#016667",
            backgroundColor: "#016667",
            yAxisID: "y",
          },
        ],
      };
    };

    let chartDataList = ref(updateChartList());

    watch(biomedTimelineData, () => {
      chartDataList.value = updateChartList();
    });

    const sumArr = (arr) => {
      return arr.reduce((a, b) => a + b, 0);
    };

    return {
      chartDataList,
      sumArr,
      biomedTimelineData,
    };
  },
});
</script>
