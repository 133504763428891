import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:checked', event.target.checked)),
    class: "base__checkbox",
    checked: _ctx.checked
  }, null, 40, _hoisted_1))
}