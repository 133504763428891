<template>
  <BaseLayout>
    <Filters @apply_filters="changeData" :allowedFilters="allowedFilters" />

    <div class="rentals dashboard__card">
      <RentalsChart />
      <RentalsListCharts />
      <RentalsStatCard />
    </div>

    <TableComponent
      v-if="rentals.data"
      ref="tableRef"
      @updatePagination="updateTable"
      name="Device Requests"
      :fields="fields"
      :data="rentals"
      :show-pagination="false"
      :compute-status="computeStatus"
      :compute-status-class="computeStatusClass"
    >
      <!--      <template v-slot:header-right>-->
      <!--        <button @click="openForm" class="rental-request__form__btn">-->
      <!--          <BaseIcon icon="add/squared" />-->
      <!--          <span>Request Rental</span>-->
      <!--        </button>-->
      <!--      </template>-->
    </TableComponent>

    <div class="view__bg">
      <router-view />
    </div>
  </BaseLayout>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/table/Table.vue";
import Filters from "@/components/resusables/filters/Filters.vue";

import store from "@/store";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import RentalsChart from "@/components/rentals/RentalsChart.vue";
import RentalsListCharts from "@/components/rentals/RentalsListCharts.vue";
import RentalsStatCard from "@/components/rentals/RentalsStatCard.vue";
import { helpers } from "@/composables/helpers";
import router from "@/router";

export default defineComponent({
  name: "RentalsPage",
  components: {
    RentalsStatCard,
    RentalsListCharts,
    RentalsChart,
    TableComponent,
    Filters,
    BaseLayout,
  },

  methods: {
    router() {
      return router;
    },
    changeData(filter) {
      // console.log("<<<< >>>>>>>>");
    },
  },

  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);
    const graphs = ["utilization", "reconciliation"];
    const activeGraph = ref(graphs[0]);
    const { split } = helpers();

    const allowedFilters = [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
      FilterScopeEnums.RECONCILIATION_STATUSES,
    ];

    const fields = [
      {
        key: "request_id",
        label: "Request #",
        sortable: false,
      },
      {
        key: "approval_id",
        label: "Approval #",
        sortable: false,
      },
      {
        key: "category",
        label: "Category",
        sortable: false,
      },
      {
        key: "sub_category",
        label: "Sub-Category",
        sortable: false,
      },
      {
        key: "clinician",
        label: "Clinician",
        sortable: false,
      },
      {
        key: "department",
        label: "Department",
        sortable: false,
      },
      {
        key: "requested_date",
        label: "Requested Date",
        sortable: false,
      },
      {
        key: "return_date",
        label: "Return Date",
        sortable: false,
      },
      {
        key: "count",
        label: "Count",
        sortable: false,
      },
      {
        key: "status",
        type: "status",
        label: "Status",
        sortable: false,
      },
    ];

    const rentals = computed(() => {
      return {
        data: [
          {
            request_id: 426,
            approval_id: 184022,
            category: "Infusion Pump",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "open",
          },
          {
            request_id: 783,
            approval_id: 184022,
            category: "Infusion Pump",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "open",
          },
          {
            request_id: 345,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "request_accepted",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "request_accepted",
          },
          {
            request_id: 244,
            approval_id: 184022,
            category: "Infusion Pump",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "request_denied",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "request_accepted",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "almost_due",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "overdue",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "overdue",
          },

          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
          {
            request_id: 453,
            approval_id: 184022,
            category: "Pacemaker",
            sub_category: "AS50",
            clinician: "Adan, L",
            department: "Respiratory",
            requested_date: "07 Feb 2023",
            return_date: "07 Mar 2023",
            count: 2,
            status: "device_returned",
          },
        ],
      };
    });

    onMounted(() => {
      // getData();
    });

    const updateTable = (res) => {
      store.dispatch("devices/getDevices", res);
    };

    const computeStatus = (row) => {
      return split(row.status);
    };

    const openForm = () => {
      router.push({ name: "RentalsForm" });
    };

    const computeStatusClass = (value, field = "status") => {
      if (field !== "status") return "";

      if (["request_accepted", "device_returned"].includes(value)) {
        return "table__status__success";
      } else if (["open", "request_denied"].includes(value)) {
        return "table__status__grey";
      } else if (["almost_due"].includes(value)) {
        return "table__status__warning";
      } else if (["overdue"].includes(value)) {
        return "table__status__danger";
      }
    };

    return {
      isOpen,
      updateTable,
      rentals,
      fields,
      dataIndex,
      allowedFilters,
      graphs,
      computeStatus,
      openForm,
      activeGraph,
      computeStatusClass,
    };
  },
});
</script>
