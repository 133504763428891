import { Buffer } from "buffer";
import { DecryptCommand, EncryptCommand, KMSClient } from "@aws-sdk/client-kms";
import getEnv from "@/utils/env";

export function encryption() {
  const encrypt = async (data: object): Promise<string | null> => {
    try {
      const client = new KMSClient({
        region: getEnv("VUE_APP_SQUID_AWS_DEFAULT_REGION"),
        credentials: {
          accessKeyId: getEnv("VUE_APP_SQUID_PHI_KMS_AWS_ACCESS_KEY_ID"),
          secretAccessKey: getEnv("VUE_APP_SQUID_PHI_KMS_SECRET_ACCESS_KEY"),
        },
      });

      const jsonString = JSON.stringify(data);
      const encoder = new TextEncoder();
      const encodedData = encoder.encode(jsonString);

      const command = new EncryptCommand({
        KeyId: getEnv("VUE_APP_SQUID_PHI_KMS_ARN"),
        Plaintext: encodedData,
      });

      const response = await client.send(command);
      if (!response.CiphertextBlob) {
        return null;
      }

      return Buffer.from(response.CiphertextBlob).toString("base64");
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const decrypt = async (encodedString: string): Promise<object | null> => {
    try {
      const client = new KMSClient({
        region: getEnv("VUE_APP_SQUID_AWS_DEFAULT_REGION"),
        credentials: {
          accessKeyId: getEnv("VUE_APP_SQUID_PHI_KMS_AWS_ACCESS_KEY_ID"),
          secretAccessKey: getEnv("VUE_APP_SQUID_PHI_KMS_SECRET_ACCESS_KEY"),
        },
      });

      // Decode Base64-encoded string into a Buffer
      const ciphertextBlob = Buffer.from(encodedString, "base64");

      const command2 = new DecryptCommand({
        CiphertextBlob: ciphertextBlob,
        KeyId: getEnv("VUE_APP_SQUID_PHI_KMS_ARN"),
      });

      const response = await client.send(command2);
      if (!response["Plaintext"]) {
        return null;
      }

      const decoder = new TextDecoder("utf-8");
      return JSON.parse(decoder.decode(response["Plaintext"]));
    } catch (err) {
      console.error(err);
      return null;
    }
  };
  return {
    encrypt,
    decrypt,
  };
}
