<template>
  <input
    type="radio"
    @input="(event) => $emit('update:checked', event.target.checked)"
    class="base__checkbox"
    :checked="checked"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseRadio",

  props: {
    checked: {
      type: Boolean,
    },
  },
});
</script>

<style scoped></style>
