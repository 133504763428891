<template>
  <div :class="{ active: showAdd }" class="add-access-point">
    <div class="form-head">
      <h3>{{ action }} access point</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="upsertAccessPoint" class="form">
      <div class="form-group">
        <BaseLabel title="Label" />
        <input
          v-model="accessPointData.label"
          class="base__input"
          type="text"
          :disabled="!updateLabel"
          required
        />
      </div>
      <div class="form-group">
        <BaseLabel title="Floor" />
        <select
          v-model="accessPointData.floorNumber"
          class="base__select"
          @change="updateFloorNumber($event)"
          required
        >
          <option value="">Select Floor</option>
          <option :key="num" v-for="num in floorRanges" :value="num">
            Floor {{ num }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <BaseLabel title="Location" />
        <div
          v-if="hasAccessPointCoordinates"
          class="access-points__update-location"
        >
          <h6>{{ accessPointCoordinates }}</h6>
          <button type="button" @click="updateAccessPointLocation">
            UPDATE
          </button>
        </div>
        <button
          v-else
          @click="setAccessPointLocation"
          class="access-points__set-location"
        >
          <BaseIcon icon="access-point/location" />
          <span>SET LOCATION</span>
        </button>
      </div>

      <div class="access-point__form-footer">
        <button type="submit" class="access-point__add-submit">
          {{ action }} Access Point
        </button>
        <button
          :disabled="disabled"
          type="button"
          @click="close"
          class="access-point__add-cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";
import { locations } from "@/composables/maps/locations";
import { helpers } from "@/composables/helpers";
import { access_points } from "@/composables/maps/access_points";

export default defineComponent({
  name: "AddAccessPointModal",
  components: { BaseLabel, BaseIcon },
  setup(_, { emit }) {
    const { floorRanges } = locations();
    const { hasAccessPointCoordinates } = access_points();
    const { sliceString } = helpers();

    const disabled = ref(false);

    onMounted(() => {
      store.commit("accessPoints/SET_ACCESS_POINT", null);
      store.commit("accessPoints/SET_SHOW_ADD", false);
      store.commit("accessPoints/SET_SHOW_LOCATION_PROMPT", false);
    });

    const currentFloor = computed(() => {
      return store.getters["locations/floorNumber"];
    });

    const showAdd = computed(() => {
      return store.getters["accessPoints/showAdd"];
    });

    const accessPoint = computed(() => {
      return store.getters["accessPoints/accessPoint"];
    });

    const action = computed(() => {
      return accessPoint.value && accessPoint.value.jsonId ? "Update" : "Add";
    });

    const updateLabel = computed(() => {
      return (
        action.value === "Add" ||
        (action.value == "Update" &&
          accessPoint.value &&
          accessPoint.value.jsonId &&
          accessPoint.value.status &&
          accessPoint.value.status === "unavailable")
      );
    });

    const accessPointData = ref({
      label: "",
      floorNumber: currentFloor.value,
    });

    watch(currentFloor, (newVal) => {
      accessPointData.value.floorNumber = newVal;
    });

    watch(accessPoint, () => {
      if (accessPoint.value && accessPoint.value.label) {
        accessPointData.value.label = accessPoint.value.label;
      } else {
        accessPointData.value.label = "";
      }
    });

    const accessPointCoordinates = computed(() => {
      if (!hasAccessPointCoordinates.value) return "";

      return sliceString(
        `${accessPoint.value.coordinate.lat.toFixed(
          7
        )}, ${accessPoint.value.coordinate.lng.toFixed(7)}`,
        21
      );
    });

    const close = () => {
      accessPointData.value.label = "";
      store.commit("accessPoints/SET_SHOW_ADD", false);
      store.commit("accessPoints/SET_ACCESS_POINT", null);
    };

    const setAccessPointLocation = () => {
      emit("set_location", accessPointData.value.label);
    };

    const updateAccessPointLocation = () => {
      accessPoint.value.setLocation = false;
      accessPoint.value.label = accessPointData.value.label;
      accessPoint.value.floorNumber = accessPointData.value.floorNumber;
      store.commit("accessPoints/SET_ACCESS_POINT", accessPoint.value);
      store.commit("accessPoints/SET_SHOW_ADD", false);
    };
    const upsertAccessPoint = () => {
      if (!accessPoint.value) return;

      if (accessPoint.value && accessPoint.value.jsonId) {
        updateAccessPoint();
      } else {
        createAccessPoint();
      }
    };
    const createAccessPoint = () => {
      if (!accessPoint.value) return;

      accessPoint.value.label = accessPointData.value.label;
      accessPoint.value.floorNumber = accessPointData.value.floorNumber;

      store.commit("accessPoints/SET_ACCESS_POINT", accessPoint.value);
      disabled.value = true;
      store
        .dispatch("accessPoints/storeAccessPoint", accessPoint.value)
        .then(async () => {
          disabled.value = false;
          store.commit("accessPoints/SET_SHOW_ADD", false);

          await Promise.all([
            store.dispatch("locations/getAccessPoints"),
            store.dispatch("locations/getAccessPointsCoordinates"),
            store.dispatch("locations/unmappedAccessPoints"),
          ]).then(() => (accessPointData.value.label = ""));
        })
        .catch(() => {
          disabled.value = false;
        });
    };
    const updateAccessPoint = () => {
      if (!accessPoint.value) return;

      accessPoint.value.label = accessPointData.value.label;
      accessPoint.value.floorNumber = accessPointData.value.floorNumber;

      store.commit("accessPoints/SET_ACCESS_POINT", accessPoint.value);
      disabled.value = true;
      store
        .dispatch("accessPoints/updateAccessPoint", accessPoint.value)
        .then(async () => {
          disabled.value = false;
          store.commit("accessPoints/SET_SHOW_ADD", false);
          await Promise.all([
            store.dispatch("locations/getAccessPoints"),
            store.dispatch("locations/getAccessPointsCoordinates"),
            store.dispatch("locations/unmappedAccessPoints"),
          ]).then(() => (accessPointData.value.label = ""));
        })
        .catch(() => {
          disabled.value = false;
        });
    };

    const updateFloorNumber = (event) => {
      const floor = event.target.value;
      if (!floor) return;
      store.commit("locations/SET_FLOOR_NUMBER", parseInt(floor));
      if (hasAccessPointCoordinates.value) {
        const accessPointData = JSON.parse(JSON.stringify(accessPoint.value));
        accessPointData.coordinate = null;
        store.commit("accessPoints/SET_ACCESS_POINT", accessPointData);
      }
    };

    return {
      disabled,
      close,
      action,
      updateLabel,
      showAdd,
      upsertAccessPoint,
      updateFloorNumber,
      accessPointCoordinates,
      setAccessPointLocation,
      updateAccessPointLocation,
      hasAccessPointCoordinates,
      floorRanges,
      accessPoint,
      accessPointData,
    };
  },
});
</script>
