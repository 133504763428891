<script lang="ts">
import { defineComponent } from "vue";
import getEnv from "@/utils/env";

export default defineComponent({
  name: "LoginPage",
  mounted() {
    const COGNITO_REDIRECT_URI = getEnv("VUE_APP_SQUID_COGNITO_REDIRECT_URI");
    const COGNITO_CLIENT_ID = getEnv("VUE_APP_SQUID_COGNITO_CLIENT_ID");
    const COGNITO_APP_DOMAIN = getEnv("VUE_APP_SQUID_COGNITO_APP_DOMAIN");

    // console.info("<<<<<<<<<<< LOGIN >>>>>>>>>>");

    window.location.href = `https://${COGNITO_APP_DOMAIN}/oauth2/authorize?client_id=${COGNITO_CLIENT_ID}&redirect_uri=${COGNITO_REDIRECT_URI}&response_type=code&scope=openid`;
  },
});
</script>
