import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { FilterState, FilterTypes } from "@/store/modules/filters/types";
import moment from "moment";

const last30Days = moment().subtract(30, "days").format("YYYY-MM-DD");
const dateTo = moment().format("YYYY-MM-DD");

export const getters: GetterTree<FilterState, RootState> = {
  filters(state: FilterState): FilterTypes {
    return state.filters;
  },
  selectedFilter(state: FilterState): string {
    return state.selectedFilter;
  },
  dateFrom(state: FilterState): string {
    return state.dateFrom || last30Days;
  },
  dateTo(state: FilterState): string {
    return state.dateTo || dateTo;
  },
  searchString(state: FilterState): string {
    return state.searchString;
  },
};
