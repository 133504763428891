import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filters__values__item" }
const _hoisted_2 = { class: "capitalize" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.isObject ? _ctx.scope[_ctx.keyName] : _ctx.split(_ctx.scope)), 1),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          disabled: _ctx.disabled,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeFilter('type')))
        }, [
          _createVNode(_component_BaseIcon, { icon: "filter/close" })
        ], 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}