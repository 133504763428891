<template>
  <GoogleMap
    id="map"
    ref="mapRef"
    :api-key="GOOGLE_MAP_API_KEY"
    class="w-full h-full"
    :center="center"
    :zoom="zoom"
    :styles="mapStyles"
    :zoomControl="true"
    :mapTypeControl="false"
    :streetViewControl="false"
  >
    <Marker
      v-show="roomDevices"
      v-for="(value, roomName) in roomDevices"
      :key="roomName"
      @click="selectDevices('room', roomName, value.devices)"
      :options="{
        position: value.center,
        zIndex: 100000000,
        label: {
          text: value.count.toString(),
          className: `device-marker-label ${isSelected(
            'room',
            roomName,
            value.devices
          )}`,
        },
        icon: 'None',
      }"
    />
    <Marker
      v-show="unitDevices"
      v-for="(value, unitName) in unitDevices"
      :key="unitName"
      @click="selectDevices('unit', unitName, value.devices)"
      :options="{
        position: value.center,
        zIndex: 100000000,
        label: {
          text: value.count.toString(),
          className: `device-marker-label ${isSelected(
            'unit',
            unitName,
            value.devices
          )}`,
        },
        icon: 'None',
      }"
    />
    <Marker
      v-show="floorDevices"
      v-for="(value, key) in floorDevices"
      :key="key"
      @click="selectDevices('floor', key, value.devices)"
      :options="{
        position: value.center,
        zIndex: 100000000,
        label: {
          text: value.count.toString(),
          className: `device-marker-label ${isSelected(
            'floor',
            key,
            value.devices
          )}`,
        },
        icon: 'None',
      }"
    />

    <template :key="key" v-for="(feature, key) in features">
      <Polygon :options="feature" />
      <Marker
        v-if="feature.properties['ROOM-NAME'] && feature.center"
        :options="{
          position: feature.center,
          clickable: true,
          label: {
            text: feature.properties['ROOM-NAME'],
            className: 'room-marker-label',
          },
          icon: 'None',
        }"
      />
    </template>
  </GoogleMap>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import store from "../../store";
import { GoogleMap, Polygon, Marker } from "vue3-google-map";
import { locations } from "@/composables/maps/locations";
import { find_devices } from "@/composables/maps/find_devices";
import getEnv from "@/utils/env";
export default defineComponent({
  name: "FindDevicesRoomsMap",
  components: { GoogleMap, Marker, Polygon },
  setup() {
    const GOOGLE_MAP_API_KEY = getEnv("VUE_APP_SQUID_GOOGLE_MAP_API_KEY");
    const { mapStyles, features, getCoordinatesCenter } = locations();
    const { roomDevices, unitDevices, floorDevices } = find_devices();
    const mapCenter = reactive({
      lat: 40.63950114685483,
      lng: -73.99869818841954,
    });

    const zoom = computed(() => {
      return store.getters["locations/zoom"];
    });

    onMounted(async () => {
      store.commit("accessPoints/SET_SHOW_COVERAGE", false);
      // store.commit("locations/SET_CENTER", null);
    });

    const geojson = computed(() => {
      return store.getters["locations/geojson"];
    });

    const accessPoint = computed(() => {
      return store.getters["accessPoints/accessPoint"];
    });

    const selectedItem = computed(() => {
      return store.getters["findDevices/selectedItem"];
    });

    const selectedDevice = computed(() => {
      return store.getters["findDevices/selectedDevice"];
    });

    const locationCenter = computed(() => {
      return store.getters["locations/center"];
    });

    const center = computed(() => {
      const middleIndex = Math.round(features.value.length / 2);
      if (locationCenter.value) return locationCenter.value;
      return middleIndex ? features.value[middleIndex]["paths"][0] : mapCenter;
    });

    const selectDevices = (type, name, devices) => {
      store.commit("findDevices/SET_SELECTED_ITEM", {
        type,
        name,
        devices,
      });
      // store.commit("locations/SET_ZOOM", 21);
    };
    const isSelected = (type, name, devices) => {
      if (selectedDevice.value) {
        return devices.find((device) => device.id === selectedDevice.value.id)
          ? "selected"
          : "";
      }

      return selectedItem.value.type === type &&
        selectedItem.value.name === name
        ? "selected"
        : "";
      // store.commit("locations/SET_ZOOM", 21);
    };

    return {
      geojson,
      roomDevices,
      selectedItem,
      floorDevices,
      unitDevices,
      center,
      mapCenter,
      features,
      zoom,
      mapStyles,
      GOOGLE_MAP_API_KEY,
      getCoordinatesCenter,
      accessPoint,
      selectDevices,
      isSelected,
    };
  },
});
</script>
