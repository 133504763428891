import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "device__events__list__item" }
const _hoisted_2 = { class: "events__list__item__header" }
const _hoisted_3 = { class: "events__item__header__date" }
const _hoisted_4 = { class: "events__item__header__right" }
const _hoisted_5 = { class: "events__item__header__status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.formatDateFromMs(_ctx.event.created_date)), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.status), 1)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", { isOpen: _ctx.isOpen })
  ]))
}