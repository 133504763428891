import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filters__input__group__list__body__item" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseRadio = _resolveComponent("BaseRadio")!
  const _component_FilterCategoryGroup = _resolveComponent("FilterCategoryGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectFilter(_ctx.index))),
      class: "filters__input__group__list__body__item__title"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.name), 1),
      _createVNode(_component_BaseIcon, {
        icon: `expand-${_ctx.activeFilter === _ctx.index ? 'less' : 'more'}`
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["filters__input__group__list__body__item__list", { active: _ctx.activeFilter === _ctx.index && _ctx.show }])
    }, [
      (!_ctx.isGrouped)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scopeList, (scope, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: "filters__input__group__item"
              }, [
                (_ctx.objectArray)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.multiple)
                        ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
                            key: 0,
                            name: _ctx.filter,
                            "onUpdate:checked": ($event: any) => (_ctx.check(_ctx.filter, scope, $event)),
                            checked: _ctx.selecting[_ctx.filter].some((fil) => fil.id === scope.id)
                          }, null, 8, ["name", "onUpdate:checked", "checked"]))
                        : (_openBlock(), _createBlock(_component_BaseCheckbox, {
                            key: 1,
                            name: _ctx.filter,
                            "onUpdate:checked": ($event: any) => (_ctx.check(_ctx.filter, scope, $event)),
                            checked: _ctx.selecting[_ctx.filter]['id'] === scope.id
                          }, null, 8, ["name", "onUpdate:checked", "checked"])),
                      _createVNode(_component_BaseLabel, {
                        title: scope[_ctx.scopeObjName]
                      }, null, 8, ["title"])
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.multiple)
                        ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
                            key: 0,
                            name: _ctx.filter,
                            "onUpdate:checked": ($event: any) => (_ctx.check(_ctx.filter, scope, $event)),
                            checked: _ctx.selecting[_ctx.filter].some((fil) => fil === scope)
                          }, null, 8, ["name", "onUpdate:checked", "checked"]))
                        : (_openBlock(), _createBlock(_component_BaseRadio, {
                            key: 1,
                            name: _ctx.filter,
                            "onUpdate:checked": ($event: any) => (_ctx.check(_ctx.filter, scope, $event)),
                            checked: _ctx.selecting[_ctx.filter] === scope
                          }, null, 8, ["name", "onUpdate:checked", "checked"])),
                      _createVNode(_component_BaseLabel, {
                        title: 
                _ctx.deviceStatusesRep[scope]
                  ? _ctx.deviceStatusesRep[scope]
                  : _ctx.split(scope)
              
                      }, null, 8, ["title"])
                    ], 64))
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_FilterCategoryGroup, {
            key: 1,
            selecting: _ctx.selecting,
            scopeList: _ctx.scopeList,
            filter: _ctx.filter,
            multiple: _ctx.multiple,
            objectArray: _ctx.objectArray,
            onCheck: _ctx.check
          }, null, 8, ["selecting", "scopeList", "filter", "multiple", "objectArray", "onCheck"]))
    ], 2)
  ]))
}