import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { DropdownState } from "@/store/modules/dropdowns/types";

export const actions: ActionTree<DropdownState, RootState> = {
  toggleDropdown({ commit, state }, dropdown: string): void {
    if (!state[dropdown]) {
      commit("CLOSE_ALL_DROPDOWN");
    }
    commit("TOGGLE_DROPDOWN", dropdown);
  },
  closeDropdown({ commit }, dropdown: string): void {
    commit("CLOSE_DROPDOWN", dropdown);
  },
  closeAllDropdown({ commit }): void {
    commit("CLOSE_ALL_DROPDOWN");
  },
};
