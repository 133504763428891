<template>
  <div class="location__navigations">
    <div
      v-if="isAccessPointsRoute"
      @click="toggleCoverage"
      class="location__navigations__item"
    >
      <h4>Coverage</h4>
      <BaseIcon :icon="`expand-${showCoverage ? 'less' : 'more'}`" />
    </div>
    <div class="location__navigations-line"></div>
    <div
      @click="open('building')"
      class="location__navigations__item"
      :class="{ active: show === 'building' }"
      v-if="isFindDevicesRoute"
    >
      <BuildingsNavigation v-if="show === 'building'" @updateMap="update" />
      <h6>{{ building.building_name }}</h6>
      <BaseIcon :icon="`expand-${show === 'building' ? 'less' : 'more'}`" />
    </div>
    <div class="location__navigations-line"></div>
    <div
      @click="open('floor')"
      class="location__navigations__item"
      :class="{ active: show === 'floor' }"
    >
      <h6>{{ floor.floor_name }}</h6>
      <BaseIcon :icon="`expand-${show === 'floor' ? 'less' : 'more'}`" />
    </div>
    <FloorsNavigation v-if="show === 'floor'" @updateMap="update" />
    <CoverageNavigation :data="coverageData" v-if="showCoverage" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import store from "../../store";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BuildingsNavigation from "@/components/locations/BuildingsNavigation.vue";
import FloorsNavigation from "@/components/locations/FloorsNavigation.vue";
import { useRouter } from "vue-router";
import CoverageNavigation from "@/components/locations/CoverageNavigation.vue";

export default defineComponent({
  name: "LocationNavigations",
  components: {
    CoverageNavigation,
    BuildingsNavigation,
    FloorsNavigation,
    BaseIcon,
  },
  setup(_, { emit }) {
    const show = ref("");

    const coverageData = {
      mode: [
        { key: "wifi", name: "WiFi Only" },
        { key: "ble", name: "BLE Capable" },
        { key: "anchor_ble", name: "BLE Enabled" },
      ],
      status: [
        { key: "online", name: "Online" },
        { key: "offline", name: "Offline" },
        { key: "unavailable", name: "Unavailable" },
        { key: "removed", name: "Removed" },
      ],
    };

    const getKeys = (arr: { key: string }[]) => {
      return arr.map((item) => item.key);
    };

    onMounted(() => {
      let modesAndStatuses = getKeys(coverageData["mode"]).concat(
        getKeys(coverageData["status"])
      );
      store.commit("accessPoints/SET_COVERAGES", modesAndStatuses);
    });

    const router = useRouter();
    const routeName = router.currentRoute.value.name;

    const isAccessPointsRoute = computed(() => {
      return routeName === "AccessPoints";
    });

    const isFindDevicesRoute = computed(() => {
      return routeName === "FindDevices";
    });

    const floor = computed(() => {
      return store.getters["locations/floor"];
    });

    const building = computed(() => {
      return store.getters["locations/building"];
    });

    const showCoverage = computed(() => {
      return store.getters["accessPoints/showCoverage"];
    });

    const open = (type: string) => {
      if (show.value !== type) {
        show.value = type;
      } else {
        show.value = "";
      }
    };

    const update = () => {
      show.value = "";
      emit("update-map");
    };

    const handleCoverageChange = (event) => {
      store.commit("accessPoints/SET_SHOW_COVERAGE", event.target.checked);
    };
    const toggleCoverage = () => {
      if (showCoverage.value) {
        store.commit("accessPoints/SET_SHOW_COVERAGE", false);
      } else {
        store.commit("accessPoints/SET_SHOW_COVERAGE", true);
      }
    };
    return {
      floor,
      show,
      coverageData,
      building,
      open,
      update,
      isAccessPointsRoute,
      isFindDevicesRoute,
      showCoverage,
      handleCoverageChange,
      toggleCoverage,
    };
  },
});
</script>
