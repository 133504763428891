<template>
  <ItemRow title="Priority">
    <h6 :class="`event__biomed__priority__${event.priority}`">
      {{ event.priority }}
    </h6>
  </ItemRow>
</template>

<script>
import { defineComponent } from "vue";
import ItemRow from "../../../resusables/list/ItemRow";

export default defineComponent({
  name: "BiomedEvent",
  components: { ItemRow },
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped></style>
