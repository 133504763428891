<template>
  <button
    v-if="show"
    @click="downloadDevices"
    :disabled="disabled"
    class="table__container__header__action"
  >
    <BaseIcon icon="table/download" />
    <span>Export CSV</span>
  </button>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { helpers } from "@/composables/helpers";
import store from "@/store";

export default defineComponent({
  name: "ExportTable",
  components: { BaseIcon },
  props: {
    name: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const { split, formatDate } = helpers();

    const disabled = ref(false);

    const show = computed(() => {
      return props.name === "Devices";
    });

    const convertArrayToCSV = (array) => {
      const keys = Object.keys(array[0]);
      const csvRows = [
        keys.join(","),
        ...array.map((obj) =>
          keys
            .map((key) => {
              let value = obj[key];
              if (value === null || value === undefined) {
                value = "";
              } else if (typeof value === "string" && value.includes(",")) {
                value = `"${value.replace(/"/g, '""')}"`;
              }
              return value;
            })
            .join(",")
        ),
      ];
      return csvRows.join("\n");
    };
    const downloadCSV = async (data, filename) => {
      const csvContent = convertArrayToCSV(data);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const downloadDevices = async () => {
      const limit = 1000;
      disabled.value = true;

      const res = await store.dispatch("devices/getDevices", {
        pagination: {
          offset: 0,
          limit,
        },
        set: false,
        infinite: false,
      });

      let devices = res.data;
      const total = res.total_elements;
      const totalNumPage = Math.ceil(total / limit);

      for (let i = 1; i < totalNumPage; i++) {
        const res2 = await store.dispatch("devices/getDevices", {
          pagination: {
            offset: i * limit,
            limit,
          },
          set: false,
        });

        devices = [...devices, ...res2.data];
      }

      devices = devices.map((device) => {
        return {
          "Serial number": device.serial_number,
          Status: split(device.status),
          "Reconciliation status": split(device.reconciliation_status),
          Category: device?.subcategory?.category?.name,
          "Sub category": device?.subcategory?.name,
          Vendor: device?.vendor?.company_name,
          "Last seen": formatDate(device.last_seen),
        };
      });

      await downloadCSV(devices, "devices.csv");

      disabled.value = false;
    };

    return {
      disabled,
      show,
      downloadDevices,
    };
  },
});
</script>

<style scoped></style>
