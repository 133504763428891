import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { AnalyticsState } from "@/store/modules/analytics/types";

export const getters: GetterTree<AnalyticsState, RootState> = {
  financialData(state: AnalyticsState) {
    return state.financialData;
  },
  utilizationData(state: AnalyticsState) {
    return state.utilizationData;
  },
  biomedTimelineData(state: AnalyticsState) {
    return state.biomedTimelineData;
  },
  biomedMeantimesData(state: AnalyticsState) {
    return state.biomedMeantimesData;
  },
  devicesReconciliationCounts(state: AnalyticsState) {
    return state.devicesReconciliationCounts;
  },
  utilizationRates(state: AnalyticsState) {
    return state.utilizationRates;
  },
};
