import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { ToastState } from "@/store/modules/toast/types";

export const actions: ActionTree<ToastState, RootState> = {
  showToast({ commit }, { title, description }) {
    commit("SHOW_TOAST", { title, description });
    setTimeout(() => {
      commit("HIDE_TOAST");
    }, 10000);
  },
};
