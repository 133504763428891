<template>
  <div
    class="device__list__tabs__item"
    @click="showDeviceOnMap"
    :class="{
      active: currentDevice && currentDevice.id === device.id,
      selected: selectedDevice && selectedDevice.id === device.id,
    }"
  >
    <div class="device__list__item__numbers">
      <h6>
        <span>Serial</span>
        <span>{{ device.serial_number }}</span>
      </h6>
      <span v-if="device.plate_id">{{ device.plate_id }}</span>
    </div>
    <div class="device__list__tabs__item__body">
      <div class="device__list__tabs__item__titles">
        <h3>{{ device?.subcategory?.name }}</h3>
        <h4>{{ device?.subcategory?.category?.name }}</h4>
      </div>
      <div class="device__list__tabs__item__dropdown dropdown__container">
        <button
          @click.stop="toggleAction"
          class="device__list__tabs__item__hamburger"
        >
          <BaseIcon icon="hamburger" />
        </button>
        <div class="dropdown-list" :class="{ active: activeIndex === index }">
          <!--          <button-->
          <!--            v-if="device.status !== DeviceStatusEnums.IN_USE"-->
          <!--            @click.stop="open('showAssignToPatient')"-->
          <!--          >-->
          <!--            Assign to patient-->
          <!--          </button>-->
          <button
            v-if="device.status !== DeviceStatusEnums.IN_USE"
            @click.stop="open('showAssignToClinician')"
          >
            Mark as In-Use
          </button>
          <button
            v-if="device.status !== DeviceStatusEnums.DIRTY"
            @click.stop="open('showMarkAsDirty')"
          >
            Mark as dirty
          </button>
          <button
            v-if="device.status !== DeviceStatusEnums.AVAILABLE"
            @click.stop="open('showMarkAsAvailable')"
          >
            Mark as available
          </button>
          <button
            v-if="device.status !== DeviceStatusEnums.IN_REPAIR"
            @click.stop="open('showSubmitRepairTicket')"
          >
            Submit repair ticket
          </button>
        </div>
      </div>
    </div>

    <DeviceLocation :location="device.location" />
    <DecryptedClinicianData
      v-if="device.status === DeviceStatusEnums.IN_USE"
      :device="device"
    />
    <h6 class="device__list__tabs__item__date">Last seen {{ getLastSeen }}</h6>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import DeviceLocation from "@/components/devices/DeviceLocation.vue";
import store from "@/store";
import { DeviceStatusEnums } from "@/store/modules/devices/types";
import { helpers } from "@/composables/helpers";
import { find_devices } from "@/composables/maps/find_devices";
import DecryptedClinicianData from "@/components/find-devices/DecryptedClinicianData.vue";
export default defineComponent({
  name: "MapDevicesListItem",
  computed: {
    DeviceStatusEnums() {
      return DeviceStatusEnums;
    },
  },
  components: { DecryptedClinicianData, DeviceLocation, BaseIcon },
  props: {
    device: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { diffForHumans } = helpers();
    const { resetCenterOfMapAfterSelect } = find_devices();

    const activeIndex = computed(() => {
      return store.getters["dropdowns/deviceLocationListItem"];
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const mapCenter = computed(() => {
      return store.getters["locations/center"];
    });

    const selectedDevice = computed(() => {
      return store.getters["findDevices/selectedDevice"];
    });

    const currentFloor = computed(() => {
      return store.getters["locations/floor"];
    });

    const getLastSeen = computed(() => {
      if (!props.device.last_seen) return "over 6 months ago";
      return diffForHumans(props.device.last_seen);
    });

    const toggleAction = () => {
      store.commit(`findDevices/SET_CURRENT_DEVICE`, props.device);
      store.commit("dropdowns/TOGGLE_DEVICE_LOCATION_LIST_ITEM", props.index);
    };

    const showDeviceOnMap = () => {
      let lat = props.device.location?.latitude;
      let lng = props.device.location?.longitude;
      const floorNumber = props.device.location?.floor_number;

      store.commit("findDevices/SET_SELECTED_DEVICE", props.device);
      if (
        typeof floorNumber === "number" &&
        floorNumber !== currentFloor.value.floor_number
      ) {
        store.commit("locations/SET_FLOOR_WITH_NUMBER", floorNumber);
        emit("update-proximity", { device: props.device });
        return;
      }

      resetCenterOfMapAfterSelect(props.device);
    };
    const open = async (type) => {
      store.dispatch("findDevices/setModal", {
        modal: type,
        show: true,
      });
      store.commit("dropdowns/TOGGLE_DEVICE_LOCATION_LIST_ITEM", -1);
    };

    return {
      activeIndex,
      toggleAction,
      open,
      getLastSeen,
      selectedDevice,
      currentDevice,
      showDeviceOnMap,
      diffForHumans,
    };
  },
});
</script>

<style scoped></style>
