import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ContractsState } from "@/store/modules/contracts/types";

export const getters: GetterTree<ContractsState, RootState> = {
  contracts(state: ContractsState) {
    return state.contracts;
  },
  allContracts(state: ContractsState) {
    return state.allContracts;
  },
  contract(state: ContractsState) {
    return state.contract;
  },
  orderCosts(state: ContractsState) {
    return state.orderCosts;
  },

  rentalSavings(state: ContractsState) {
    return state.rentalSavings;
  },

  savingsAmounts(state: ContractsState) {
    return state.savingsAmounts;
  },
};
