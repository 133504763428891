<template>
  <div class="find__devices__in__use__data">
    <div class="find__devices__in__use__decrypted">
      <h6>Assigned to: {{ clinicianName }}</h6>
      <p>
        {{ assignNote }}
      </p>
    </div>
    <button
      :disabled="disabled"
      v-if="device.status === DeviceStatusEnums.IN_USE"
      @click.stop="getEncryptedData"
      class="find__devices__in__use__btn"
    >
      <BaseIcon :icon="`eye/${!show ? 'show' : 'hide'}`" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import { DeviceStatusEnums } from "@/store/modules/devices/types";
import store from "@/store";
import { encryption } from "@/composables/phi/encryption";

export default defineComponent({
  name: "DecryptedClinicianData",
  computed: {
    DeviceStatusEnums() {
      return DeviceStatusEnums;
    },
  },
  components: { BaseIcon },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { decrypt } = encryption();
    const disabled = ref(false);
    const show = ref(false);
    const data = ref({ name: "", note: "" });

    watch(
      () => props.device,
      (newDevice, oldDevice) => {
        if (newDevice.id !== oldDevice?.id) {
          data.value = { name: "", note: "" };
          show.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    const clinicianName = computed(() => {
      return show.value ? data.value.name : "••••••••••••••";
    });

    const assignNote = computed(() => {
      return show.value ? data.value.note : "Comment: ••••••••••••••";
    });

    const getEncryptedData = async () => {
      if (show.value) {
        show.value = false;
        return;
      }

      disabled.value = true;
      const res = await store.dispatch("devices/getDeviceEventsUtilization", {
        deviceId: props.device.id,
      });
      if (res.data.length > 0) {
        const lastEvent = res.data[res.data.length - 1];
        const hash = lastEvent.borrower_hash;
        const decryptedData: any = await decrypt(hash);
        if (decryptedData) {
          data.value = decryptedData;
        }
      }
      disabled.value = false;
      show.value = true;
    };

    return {
      disabled,
      show,
      clinicianName,
      assignNote,
      data,
      getEncryptedData,
    };
  },
});
</script>

<style scoped></style>
