<template>
  <div id="dashboard" @click="handleGlobalClick">
    <div class="dashboard">
      <DashboardSidebar />
      <div class="dashboard__main">
        <DashboardHeader />
        <main class="dashboard__main__content" :class="contentClass">
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import DashboardHeader from "@/components/layout/header/DashboardHeader";
import DashboardSidebar from "@/components/layout/DashboardSidebar";
import store from "@/store";

export default defineComponent({
  name: "BaseLayout",
  components: { DashboardSidebar, DashboardHeader },
  props: {
    contentClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(_, { emit }) {
    const drawer = ref(true);

    const handleGlobalClick = (event) => {
      if (
        event.target.classList.contains("modals") ||
        event.target.classList.contains("modals-trigger")
      ) {
        return;
      }

      const modalElements = document.querySelectorAll(".modals");
      const modalTriggerElements = document.querySelectorAll(".modals-trigger");
      for (let i = 0; i < modalElements.length; i++) {
        const modalElement = modalElements[i];
        const modalTriggerElement = modalTriggerElements[i];
        if (
          (modalElement && modalElement.contains(event.target)) ||
          (modalTriggerElement && modalTriggerElement.contains(event.target))
        ) {
          return;
        }
      }
      store.dispatch("dropdowns/closeAllDropdown");
      emit("close");
    };

    return {
      drawer,
      handleGlobalClick,
    };
  },
});
</script>

<style scoped></style>
