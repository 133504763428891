<template>
  <div class="rentals__list__charts">
    <ListChart title="Departments" :datasets="departmentsData" />
    <ListChart title="Request Status" :datasets="statusData" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ListChart from "@/components/resusables/charts/ListChart.vue";

export default defineComponent({
  name: "RentalsListCharts",
  components: { ListChart },

  setup() {
    const departmentsData = [
      {
        label: "Respiratory",
        value: 12,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "MICU",
        value: 34,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "OR",
        value: 36,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "PACU",
        value: 6,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "CCU",
        value: 2,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "OTHER",
        value: 18,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
    ];
    const statusData = [
      {
        label: "Open",
        value: 14,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "Request Accepted",
        value: 34,
        backgroundColor: "#F1F8F8",
        borderColor: "#95C8C9",
      },
      {
        label: "Request Denied",
        value: 36,
        backgroundColor: "#F0F0F0",
        borderColor: "#CCC",
      },
      {
        label: "Almost Due",
        value: 6,
        backgroundColor: "#FFF5EA",
        borderColor: "#E1B878",
      },
      {
        label: "Overdue",
        value: 8,
        backgroundColor: "#FFEDEC",
        borderColor: "#EE9C99",
      },
      {
        label: "Device Returned",
        value: 18,
        backgroundColor: "#F1F8F8",
        borderColor: "#95C8C9",
      },
    ];

    return {
      departmentsData,
      statusData,
    };
  },
});
</script>
