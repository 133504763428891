import moment from "moment";
import { helpers } from "@/composables/helpers";
import { computed, watch } from "vue";
import store from "@/store";

export function savings() {
  const { formatMoney } = helpers();

  const filters = computed(() => {
    return store.getters["filters/filters"];
  });

  const contracts = computed(() => {
    return store.getters["contracts/contracts"];
  });
  const allContracts = computed(() => {
    return store.getters["contracts/allContracts"];
  });

  const financialData = computed(() => {
    return store.getters["analytics/financialData"];
  });

  watch(
    filters,
    async (newFilters) => {
      await store.dispatch("contracts/getAllContracts");
      const rentalAmount = await rentals(newFilters);
      const contractsSavingsAmount = await getRowContractsSavings();
      store.commit("contracts/SET_RENTAL_SAVINGS", rentalAmount);
      store.commit("contracts/SET_SAVINGS_AMOUNTS", contractsSavingsAmount);
    },
    { deep: true }
  );

  const serviceSavings = computed(() => {
    let amount = 0;
    if (!allContracts.value.data) {
      return;
    }
    for (const contract of allContracts.value.data) {
      if (contract.type === "maintenance") {
        let diff = 0;
        if (contract.cycle === "month") {
          diff = moment(contract.end_date).diff(
            moment().format("YYYY-MM-DD"),
            "months"
          );
        } else if (contract.cycle === "day") {
          diff = moment(contract.end_date).diff(
            moment().format("YYYY-MM-DD"),
            "days"
          );
        } else if (contract.cycle === "year") {
          diff = moment(contract.end_date).diff(
            moment().format("YYYY-MM-DD"),
            "years"
          );
        }

        amount +=
          diff * parseFloat(contract.unit_amount) * contract.leakage.length;
      }
    }

    return `$${formatMoney(amount)}`;
  });

  const preventativeMaintenance = computed(() => {
    if (!allContracts.value.data) {
      return;
    }
    let amount = 0;
    for (const contract of allContracts.value.data) {
      if (contract.type === "preventative_maintenance") {
        amount +=
          parseFloat(financialData.value.average_preventative_maintenance) *
          contract.leakage.length;
      }
    }
    return `$${formatMoney(amount)}`;
  });

  const rentals = async (filter: any) => {
    let amount = 0;

    if (!allContracts.value.data) {
      return "";
    }

    for (const contract of allContracts.value.data) {
      if (contract.type === "rental") {
        const filter: any = {};
        filter.date_from = contract.start_date;
        filter.date_to = contract.end_date;

        filter.subCategory = contract.subcategory;

        // eslint-disable-next-line vue/no-async-in-computed-properties
        const data = await store.dispatch(
          "analytics/getUtilizationTimelineData",
          { filter }
        );
        let AR = contract.quantity - Math.max(...data.available);
        AR = AR > 0 ? AR : 0;
        const UR = contract.quantity - AR;
        amount += contract.period * parseFloat(contract.unit_amount) * UR;
        // amount += parseFloat(financialData.value.average_rental) * UR;
      }
    }

    return `$${formatMoney(amount)}`;
  };

  const getRowServiceContractSavings = async (contract: any) => {
    let amount = 0;
    if (contract.type === "maintenance") {
      let diff = 0;
      if (contract.cycle === "month") {
        diff = moment(contract.end_date).diff(
          moment().format("YYYY-MM-DD"),
          "months"
        );
      } else if (contract.cycle === "day") {
        diff = moment(contract.end_date).diff(
          moment().format("YYYY-MM-DD"),
          "days"
        );
      } else if (contract.cycle === "year") {
        diff = moment(contract.end_date).diff(
          moment().format("YYYY-MM-DD"),
          "years"
        );
      }
      amount +=
        diff * parseFloat(contract.unit_amount) * contract.leakage.length;
    }
    return amount;
  };

  const getRowRentalContractSavings = async (contract: any) => {
    let amount = 0;
    if (contract.type === "rental") {
      const filter: any = {};
      filter.date_from = contract.start_date;
      filter.date_to = contract.end_date;

      filter.subCategory = contract.subcategory;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const data = await store.dispatch(
        "analytics/getUtilizationTimelineData",
        { filter }
      );

      let AR = contract.quantity - Math.max(...data.available);
      AR = AR > 0 ? AR : 0;

      const UR = contract.quantity - AR;
      amount += contract.period * parseFloat(contract.unit_amount) * UR;
    }
    return amount;
  };
  const getRowContractsSavings = async () => {
    const amounts: any = [];
    if (!contracts.value.data) {
      return amounts;
    }
    for (const contract of contracts.value.data) {
      let amount: number | null = null;
      if (contract.type === "rental") {
        amount = await getRowRentalContractSavings(contract);
      } else if (contract.type === "maintenance") {
        amount = await getRowServiceContractSavings(contract);
      }
      if (amount === null) {
        amounts.push("-");
      } else {
        amounts.push(`$${formatMoney(amount)}`);
      }
    }
    return amounts;
  };

  return {
    serviceSavings,
    getRowContractsSavings,
    preventativeMaintenance,
  };
}
