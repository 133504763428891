import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasAccessPointCoordinates && _ctx.showLocationPrompt)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ active: _ctx.showLocationPrompt && _ctx.accessPoint }, "access-point__location__prompt"]),
        id: "access-point__location__prompt",
        style: _normalizeStyle({ top: `${_ctx.accessPoint.top}px`, left: `${_ctx.accessPoint.left}px` })
      }, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, "Not yet"),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setLocation && _ctx.setLocation(...args)))
        }, "Set Location")
      ], 6))
    : _createCommentVNode("", true)
}