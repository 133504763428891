<template>
  <BaseLayout contentClass="dashboard__main__content__map">
    <div class="access-points">
      <AccessPointsMap />
      <AccessPointsList />
      <!--      <AddAccessPointModal />-->
      <AccessPointLocationPromptModal />
      <LocationNavigations @updateMap="updateAccessPoints" />
    </div>
  </BaseLayout>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import store from "@/store";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import AccessPointsMap from "@/components/access-points/AccessPointsMap";
import AccessPointsList from "@/components/access-points/AccessPointsList";
import AccessPointLocationPromptModal from "@/components/access-points/AccessPointLocationPromptModal";
import LocationNavigations from "@/components/locations/LocationNavigations.vue";

export default defineComponent({
  name: "AccessPoints",

  components: {
    AccessPointLocationPromptModal,
    AccessPointsList,
    AccessPointsMap,
    BaseLayout,
    LocationNavigations,
  },

  setup() {
    onMounted(async () => {
      await updateAccessPoints();
    });

    const geojson = computed(() => {
      return store.getters["locations/geojson"];
    });

    const updateAccessPoints = async () => {
      store.commit("locations/SET_SHOW_UPDATE_ROOM", false);
      await Promise.all([
        store.dispatch("locations/getBuildings"),
        store.dispatch("locations/getFloors"),
        store.dispatch("locations/getAccessPointsCoordinates"),
        store.dispatch("locations/getRoomsCoordinates"),
        store.dispatch("locations/getAccessPoints"),
        store.dispatch("locations/unmappedAccessPoints"),
        store.dispatch("locations/getRoomUnits"),
      ]);
    };

    return {
      geojson,
      updateAccessPoints,
    };
  },
});
</script>
