import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/locations/getters";
import { mutations } from "@/store/modules/locations/mutations";
import { actions } from "@/store/modules/locations/actions";
import { LocationsState } from "@/store/modules/locations/types";

export const Data: LocationsState = {
  geojson: {},
  center: {
    lat: 40.63950114685483,
    lng: -73.99869818841954,
  },
  buildings: [],
  floors: [],
  building: null,
  floorNumber: 1,
  floor: null,
  unmappedAccessPoints: [],
  accessPoints: [],
  accessPointsCoordinates: [],
  roomsUnits: [],
  zoom: 20,
  showUpdateRoom: false,
};

export const locations: Module<LocationsState, RootState> = {
  namespaced: true,
  state: Data,
  getters,
  actions,
  mutations,
};

export default locations;
