<template>
  <div class="cost__card contracts__details">
    <CostCardItem title="Cost of Ownership">
      <CostCardListItem
        title="Total Cost"
        :price="`$${formatMoney(orderCosts.total_cost)}`"
      />
      <CostCardListItem title="Total Devices" :price="totalDevicesCount" />
    </CostCardItem>

    <CostCardItem title="Verified Cost">
      <CostCardListItem
        title="Total Cost"
        :price="`$${formatMoney(orderCosts.verified_cost)}`"
      />
      <CostCardListItem
        title="Verified Devices"
        :price="verifiedDevicesCount"
      />
      <CostCardListItem title="Missing Devices" :price="leakageDevicesCount" />
    </CostCardItem>

    <CostCardItem title="Savings Opportunity">
      <CostCardListItem title="Service" :price="serviceSavings" />
      <!--      <CostCardListItem title="Maintenance" :price="preventativeMaintenance" />-->
      <CostCardListItem title="Rentals" :price="rentalSavings" />
    </CostCardItem>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import { helpers } from "@/composables/helpers";
import CostCardListItem from "@/components/resusables/cost/CostCardListItem.vue";
import CostCardItem from "@/components/resusables/cost/CostCardItem.vue";
import { savings } from "@/composables/contracts/savings";

export default defineComponent({
  name: "StatCards",

  components: { CostCardListItem, CostCardItem },

  setup() {
    const { formatMoney } = helpers();
    const { serviceSavings, preventativeMaintenance } = savings();

    let rentalAmount = ref("");

    const financialData = computed(() => {
      return store.getters["analytics/financialData"];
    });

    const orderCosts = computed(() => {
      return store.getters["contracts/orderCosts"];
    });

    const rentalSavings = computed(() => {
      return store.getters["contracts/rentalSavings"];
    });

    const devicesReconciliationCounts = computed(() => {
      return store.getters["analytics/devicesReconciliationCounts"];
    });

    const getDeviceReconciliationCount = (type) => {
      const count = devicesReconciliationCounts.value[type];
      return count ? count : 0;
    };

    const deviceStatuses = computed(() => {
      return store.getters["devices/deviceStatusesCounts"];
    });

    const getDeviceCount = (type) => {
      const count = deviceStatuses.value[type];
      return count ? count : 0;
    };

    const leakageDevicesCount = computed(() => {
      return formatMoney(getDeviceReconciliationCount("missing"));
    });

    const registeredDevicesCount = computed(() => {
      return getDeviceReconciliationCount("registered");
    });

    const unPurchasedDevicesCount = computed(() => {
      return getDeviceReconciliationCount("unpurchased");
    });

    const totalDevicesCount = computed(() => {
      return formatMoney(
        getDeviceCount("available") +
          getDeviceCount("unavailable") +
          getDeviceCount("in_use") +
          getDeviceCount("dirty") +
          getDeviceCount("in_repair")
      );
    });

    const verifiedDevicesCount = computed(() => {
      return formatMoney(
        getDeviceReconciliationCount("confirmed") +
          getDeviceReconciliationCount("registered") +
          getDeviceReconciliationCount("undocumented") +
          getDeviceReconciliationCount("unbooked")
      );
    });

    return {
      financialData,
      formatMoney,
      rentalSavings,
      rentalAmount,
      preventativeMaintenance,
      verifiedDevicesCount,
      serviceSavings,
      totalDevicesCount,
      leakageDevicesCount,
      unPurchasedDevicesCount,
      registeredDevicesCount,
      orderCosts,
    };
  },
});
</script>
