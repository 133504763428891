<template>
  <div :class="{ active: show }" class="add-access-point">
    <div class="form-head">
      <h3>Assign to patient</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="submit" class="form">
      <div class="form-group">
        <BaseLabel title="Patient name" />
        <input
          v-model="patient.name"
          placeholder="Enter patient name"
          class="base__input"
          type="text"
          required
        />
      </div>
      <div class="form-group">
        <BaseLabel title="Date Of Birth" />
        <input
          v-model="patient.dob"
          class="base__input"
          placeholder="Enter patient date of birth"
          type="date"
          required
        />
      </div>
      <div class="form-group">
        <BaseLabel title="Patient ID" />
        <input
          v-model="patient.mrn"
          class="base__input"
          placeholder="Enter patient ID"
          type="text"
          required
        />
      </div>

      <div class="form-footer">
        <button type="submit" class="form-footer__btn-full">
          Assign to patient
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";

export default defineComponent({
  name: "AssignToPatientModal",
  components: { BaseLabel, BaseIcon },
  setup() {
    const disabled = ref(false);
    const patient = ref({
      name: "",
      dob: "",
      mrn: "",
      visitNumber: "",
    });

    onMounted(() => {
      store.commit("findDevices/SET_SHOW_ASSIGN_TO_PATIENT", false);
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const show = computed(() => {
      return store.getters["findDevices/showAssignToPatient"];
    });

    const submit = async () => {
      // const encryptedPatient = await encrypt(patient.value);
      // console.log({ encryptedPatient });
      // store
      //   .dispatch("devices/assignDeviceToClinician", {
      //     deviceId: currentDevice.value.id,
      //     borrower_hash: encryptedPatient,
      //   })
      //   .then(() => {
      //     store.dispatch("toast/showToast", {
      //       title: `SERIAL ${currentDevice.value.serial_number} has been assigned to clinician.`,
      //       description: `Device: ${currentDevice.value.description} (${currentDevice.value.vendor?.brand_name})`,
      //     });
      //   });
      // close();
    };

    const close = () => {
      store.dispatch("findDevices/setModal", {
        modal: "showAssignToPatient",
        show: false,
      });
    };

    return {
      disabled,
      close,
      show,
      patient,
      submit,
    };
  },
});
</script>
