import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { AccessPointState } from "@/store/modules/access-points/types";

export const getters: GetterTree<AccessPointState, RootState> = {
  accessPoint(state: AccessPointState) {
    return state.accessPoint;
  },
  showLocationPrompt(state: AccessPointState) {
    return state.showLocationPrompt;
  },
  showAdd(state: AccessPointState) {
    return state.showAdd;
  },
  showCoverage(state: AccessPointState) {
    return state.showCoverage;
  },
  coverages(state: AccessPointState) {
    return state.coverages;
  },
  lastTimeAccessPointsRefreshed(state: AccessPointState) {
    return state.lastTimeAccessPointsRefreshed;
  },
};
