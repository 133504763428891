<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "OAuthCallback",

  async mounted() {
    const route = useRoute();

    const router = useRouter();

    const query = route.query;
    console.log({ query });

    if (!query.code) {
      // router.push({ name: "Logout" });
      return;
    }

    await store.dispatch("loading/startLoading", true);

    await store.dispatch("auth/signIn", {
      code: query.code,
    });
    await store.dispatch("auth/getUser");

    await store.dispatch("loading/doneLoading", true);

    router.push({ name: "Dashboard" });
  },
});
</script>
