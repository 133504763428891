<template>
  <router-view />
  <ToastMessage />
</template>

<script>
import { defineComponent, onMounted } from "vue";
import "@/assets/tailwind.css";
import ToastMessage from "@/components/helpers/ToastMessage.vue";
import getEnvs from "@/utils/env/decrypt";
import store from "@/store";

export default defineComponent({
  name: "App",
  components: { ToastMessage },
  setup() {
    onMounted(() => {
      store.commit("toast/HIDE_TOAST");
    });
    (async () => {
      await getEnvs();
    })();
  },
});
</script>
