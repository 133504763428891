<template>
  <div class="device__events__list__item">
    <div class="events__list__item__header">
      <h6 class="events__item__header__date">
        {{ formatDateFromMs(event.status_date) }}
      </h6>
      <div class="events__item__header__right">
        <span class="events__item__header__status">{{ status }}</span>
      </div>
    </div>
    <h3 class="events__list__item__desc">
      {{ description[event.status] ?? "-" }}
    </h3>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { helpers } from "@/composables/helpers";
import { viewDevice } from "@/composables/devices/view-device";

export default defineComponent({
  name: "TicketEvent",

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { formatMoney, split } = helpers();

    // eslint-disable-next-line vue/no-setup-props-destructure
    const { event } = props;

    const status = computed(() => {
      return split(event.status);
    });

    const description = {
      open: "Repair ticket has been created",
      on_hold: "Ticket has been put on hold",
      pending_assignment: "Ticket is yet to be assigned",
      pending_review: "Ticket is yet to be reviewed",
      ready_for_work: "Ticket is now ready for work",
      work_in_progress: "Ticket work is in progress",
      closed: "Repair work has been done, and ticket closed",
    };

    const { formatStatus, formatDateFromMs, addStatusClass } = viewDevice();

    return {
      formatMoney,
      status,
      formatStatus,
      formatDateFromMs,
      addStatusClass,
      description,
    };
  },
});
</script>

<style scoped></style>
