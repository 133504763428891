import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/access-points/getters";
import { mutations } from "@/store/modules/access-points/mutations";
import { actions } from "@/store/modules/access-points/actions";
import { AccessPointState } from "@/store/modules/access-points/types";

export const Data: AccessPointState = {
  accessPoint: null,
  showLocationPrompt: false,
  showAdd: false,
  showCoverage: false,
  lastTimeAccessPointsRefreshed: null,
  coverages: [],
};

export const accessPoints: Module<AccessPointState, RootState> = {
  namespaced: true,
  state: Data,
  getters,
  actions,
  mutations,
};

export default accessPoints;
