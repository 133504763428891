import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createBlock(_component_DoughnutChart, {
    total: _ctx.financialData.average,
    title: "Average Cost of Ownership",
    totalLabel: "Average Cost",
    isCurrency: true,
    chartData: _ctx.chartDataList
  }, null, 8, ["total", "chartData"]))
}