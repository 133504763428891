import { MutationTree } from "vuex";
import { LocationsState } from "@/store/modules/locations/types";

export const mutations: MutationTree<LocationsState> = {
  SET_MAP_GEO_JSON(state: LocationsState, data: object[]) {
    state.geojson = data;
    return state.geojson;
  },
  SET_ACCESS_POINTS(state: LocationsState, data: object[]) {
    state.accessPoints = data;
    return state.accessPoints;
  },
  SET_CENTER(state: LocationsState, data: object) {
    state.center = data;
    return state.center;
  },
  SET_UNMAPPED_ACCESS_POINTS(state: LocationsState, data: object[]) {
    state.unmappedAccessPoints = data;
    return state.unmappedAccessPoints;
  },
  SET_ROOMS_UNITS(state: LocationsState, data: object[]) {
    state.roomsUnits = data;
    return state.roomsUnits;
  },
  SET_ACCESS_POINTS_COORDINATES(state: LocationsState, data: object[]) {
    state.accessPointsCoordinates = data;
    return state.accessPointsCoordinates;
  },
  SET_BUILDINGS(state: LocationsState, data: object[]) {
    state.buildings = data;
    return state.buildings;
  },
  SET_FLOORS(state: LocationsState, data: object[]) {
    state.floors = data;
    return state.floors;
  },
  SET_BUILDING(state: LocationsState, data: object) {
    state.building = data;
    return state.building;
  },
  SET_FLOOR(state: LocationsState, data: object) {
    state.floor = data;
    return state.floor;
  },
  SET_FLOOR_NUMBER(state: LocationsState, data: number) {
    state.floorNumber = data;
    return state.floorNumber;
  },
  SET_FLOOR_WITH_NUMBER(state: LocationsState, data: number) {
    const floor = state.floors.find((f) => f.floor_number === data);
    if (!floor) return state.floorNumber;
    state.floor = floor;
    state.floorNumber = data;
    return state.floorNumber;
  },
  SET_ZOOM(state: LocationsState, data: number) {
    state.zoom = data;
    return state.zoom;
  },

  SET_SHOW_UPDATE_ROOM(state: LocationsState, data: boolean) {
    state.showUpdateRoom = data;
    return state.showUpdateRoom;
  },
};
