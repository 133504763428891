import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import dropdowns from "@/store/modules/dropdowns";
import contracts from "@/store/modules/contracts";
import { tickets } from "@/store/modules/tickets";
import devices from "@/store/modules/devices";
import dashboard from "@/store/modules/dashboard";
import filters from "@/store/modules/filters";
import analytics from "@/store/modules/analytics";
import pagination from "@/store/modules/pagination";
import loading from "@/store/modules/loading";
import locations from "@/store/modules/locations";
import accessPoints from "@/store/modules/access-points";
import findDevices from "@/store/modules/find-devices";
import toast from "@/store/modules/toast";

export default createStore({
  state: {
    data: "new",
  },
  actions: {},
  mutations: {},
  modules: {
    auth,
    devices,
    tickets,
    contracts,
    dropdowns,
    dashboard,
    filters,
    analytics,
    pagination,
    loading,
    locations,
    accessPoints,
    findDevices,
    toast,
  },
  plugins: [
    // createLogger(),
    createPersistedState({
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, state) => localStorage.setItem(key, state),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
  ],
});
